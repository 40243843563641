.Add_new_pump_block {
    padding-top: 32px;

    .table-header {
        &.table_heading_head {
            h1 {
                margin-bottom: 0px;
            }
        }
    }

    .new_pump_details {
        min-height: 756px;
    }

    select {
        color: $shadeGrey;
    }

    .selected_pump_shows {
        border: 1px solid #E2E3E4;

        &.pump_checked {
            border: 1px solid $red;
        }

        .selected_pump_img_name {
            gap: 12px;

            // .vt_pump_img {
            //     @media (min-width: 992px) {
            //        min-width: 45px;
            //        min-height: 81px;
            //     }
            //     @media (min-width: 768px) and (max-width: 991px) {
            //         margin:  0 auto;
            //     }
            //     @media (max-width: 992px) {
            //         min-width: 32px;
            //         max-width: max-content;
            //         display: block;
            //     }
            // }

        }

        // .pump_field_img {
        //     @media (min-width: 768px) and (max-width: 991px) {
        //         min-width: 73px;
        //     }
        // }

        // img {
        //     // max-width: 54px;
        //     max-width: 56px;
        //     max-height: 64px;

        //     @media (max-width:767px) {
        //         max-width: 32px;
        //         max-height: 50px;
        //     }
        // }

        input[type=radio] {
            width: 24px;
            height: 24px;
        }
    }

    .add_new_pump_btns {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;

        button {
            width: 177px;

            @media (max-width:767px) {
                width: 156px;
            }
        }
    }
}