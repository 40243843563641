$red: #ED1D24;
$high_red: #BC141A;
$dark_red: #BE1E2D;
$gray: #808285;
$dark_blue: #15223B;
$orange: #F57E20;
$yellow: #FFC82C;
$bg_color: #f0c8b9;
$black: #000;
$white: #fff;
$shadeGrey: #5D5D70;
$greyShadeAF:#afb1b4;
$green : #9CC559;
$light_green : #B9E471;
$dark_grey :#868594;
$dark_grey_shade:#C9CACC;