footer{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 24px 0px 26px 0px;
    border-top: 1px solid #e9eaee;
    position: absolute;
    bottom: 0px;
    margin-bottom: -66px;    
    @media (max-width:767px){
        padding: 20px 0px 20px 0px;
        margin-bottom: -56px; 
    }
    h6{
        @include font_12_400;
        font-weight: 400;
        line-height: normal;
        color: #4E565F;
        text-align: center;
        margin-bottom: 0px;
    }
}