.home_page {
  padding: 24px 0px 28px 0px;
  background-image: url("../../images/home_background.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100%;
  @media (max-width: 767px) {
    padding: 24px 0px 40px 0px;
  }
  .home_left_col {
    padding-right: 10px !important;
    padding-top: 120px;
    .home_hero_img {
      width: 100%;
    }
    @media (max-width: 991px) {
      padding-top: 0px;
      margin-bottom: 24px;
      padding-right: 0px !important;
      width: 100%;
    }
  }
  .home_right_col {
    @media (max-width: 991px) {
      width: 100%;
    }
    .home_right_content {
      padding: 32px;
      background-color: #f6f7f7;
      margin-left: 10px;
      @media (max-width: 991px) {
        margin-left: 0px;
        padding: 24px 0px 20px 0px;
      }
      .home_heading_main {
        @include font_30_700;
        line-height: 30px;
        font-weight: 700;
        margin-bottom: 12px;
        color: $dark_blue;
        margin-bottom: 12px;
        @media (max-width: 991px) {
            padding: 0px 70px 0px 16px;
        }
        @media (max-width: 767px) {
          font-size: 24px;
          margin-bottom: 20px;
        }
      }
      .hero_para_top {
        @media (max-width: 991px) {
            padding: 0px 16px;
        }
        p {
          max-width: 719px;
          margin-bottom: 32px;
          @media (max-width: 767px) {
            margin-bottom: 24px;
            &:first-child{
                padding-right: 10px
            }
            &:last-child{
                margin-bottom: 32px
            }
          }
        }
      }
      p {
        @include font_18_400;
        line-height: 28px;
        letter-spacing: -0.18px;
        color: $shadeGrey;
        font-weight: 400;
        margin-bottom: 0px;
        @media (max-width: 767px) {
          font-size: 14px;
          letter-spacing: -0.14px;
        }
      }
      .middle_hero_content {
        .darkred_btn{
          width: 192px;
          @media (max-width: 767px) {
            width: 174px;
          }
        }
        @media (max-width: 991px) {
            padding: 0px 16px;            
        }
        h2 {
          @include font_24_700;
          color: $dark_blue;
          line-height: normal;
          font-weight: 700;
          margin-bottom: 9px;
          @media (max-width: 767px) {
            font-size: 16px;
            margin-bottom: 20px;
          }
        }
        p {
          max-width: 630px;
        }
        ol {
          padding-left: 20px;
          margin-bottom: 20px;
          @media (max-width: 767px) {
            margin-bottom: 32px;
          }
          li {
            &::marker {
              @include font_18_400;
              line-height: 28px;
              letter-spacing: -0.18px;
              color: $shadeGrey;
              font-weight: 400;
              margin-bottom: 0px;
              @media (max-width: 767px) {
                font-size: 14px;
                letter-spacing: -0.14px;
              }
            }
          }
        }
      }
    }
  }
  .accordion_home {
    margin-top: 32px;
    .accordion {
      background-color: transparent;
      border: none;
      --bs-border-color: transparent;
      .accordion-item {
        background-color: transparent;
        .accordion-button {
          @include font_24_700;
          font-weight: 700;
          line-height: normal;
          color: $dark_blue;
          padding: 20px 11px;
          box-shadow: none;
          border-radius: 0px;
          border-top: 0px;
          border-right: 0px;
          border-left: 0px;
          border-bottom: 1px solid $gray;
          background-color: transparent;
          &:focus-visible {
            box-shadow: none;
          }
          @media (max-width: 767px) {
            font-size: 12px;
            padding: 16px 11px;
          }
          &::after {
              background-image: url("../../images/minus-square.svg");
              transform: rotate(0);
            @media (max-width: 767px) {
                background-size: 16px 16px;
                width: 16px;
                height: 16px;
            }
          }
          &.collapsed {
            &::after {
              background-image: url("../../images/add-square.svg");
              transform: rotate(0);
            }
          }
          &:not(.collapsed){
              border-bottom: 0px;
              padding-bottom: 0px;
          }
        }
        .accordion-body {
          border: none;
          border-bottom: 1px solid $gray;
          padding: 16px 11px;
          @include font_18_400;
          line-height: 28px;
          letter-spacing: -0.18px;
          color: $shadeGrey;
          font-weight: 400;
          margin-bottom: 0px;
          @media (max-width: 767px) {
            font-size: 12px;
            letter-spacing: -0.14px;
          }
        }
      }
    }
  }
}
