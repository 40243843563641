.stock_pump_master {
    .pumps_tabs {
        .p-tabview-nav {
            overflow: auto;

            .p-unselectable-text {
                &.p-highlight {
                    overflow: unset;
                }
            }
        }

        .overview_left {
            @media (max-width: 1023px) {
                width: 100%;
            }

            .details_block {
                padding: 20px 20px 44px 20px;

                @media (max-width: 767px) {
                    padding: 24px 16px 24px 16px;
                }
            }

            .overview_pump_img {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;

                .overview_img {
                    width: 100%;
                    max-width: 220px;
                    height: auto;
                    margin: 0 auto;
                    display: block;

                    &.vt_mgr_img {
                        max-width: 190px;
                        @media (min-width:1201px) {
                            position: absolute;
                            top: -25px;
                        }
                    }
                }
            }

            .row_center {
                align-items: center;
                height: 100%;
            }

            .laptop_w_50 {
                .ipad_col_50 {
                    @media (max-width: 1280px) {
                        width: 50%;
                    }
                }
            }

            .form-row {
                margin-top: 68px;

                @media (max-width: 1200px) {
                    margin-top: 24px;
                }

                @media (max-width: 991px) {
                    margin-top: 16px;
                }
                @media (min-width: 1201px) {
                    &.location_row{
                        margin-top: 0px;
                    }
                }
            }
        }

        .overview_right {
            @media (max-width: 1023px) {
                width: 100%;
            }

            .custom_width {
                @media (min-width: 1201px) {
                    width: 27.7%;
                }
            }

            .custom_min_w {
                white-space: nowrap;
            }

            .form-row {
                margin-top: 24px;

                @media (max-width: 991px) {
                    margin-top: 24px;
                }
            }
        }

        .row {
            @media (max-width: 1200px) {
                align-items: flex-start !important;
            }
        }

        .form-row {
            label {
                @media (max-width: 767px) {
                    font-size: 14px;
                }
            }
        }

        .details_block {
            margin-bottom: 16px;

            @media (max-width: 767px) {
                margin-bottom: 20px;
                padding: 24px 16px 24px 16px;
            }
        }
    }

    .show_enquiries_detail {
        display: none;
    }
}