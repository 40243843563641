.stock_pump_master{
    margin-top: 32px;
    padding-bottom: 32px;
    @media (max-width: 767px) {
        margin-top: 24px;
        padding-bottom: 24px;
    }
    .stock_pump_master_table{
        margin-top: -9px;
    }
}