.enquiries_details {
    padding-bottom: 23px;
    .enquiries_main_row {
        @media (max-width: 991px) {
            width: 100%;
            flex-direction: column-reverse;
            margin: 0 auto;
        }
        .enquiries_left_box {
            padding-right: 10px;
            .details_block{
                overflow: hidden;
            }
            @media (max-width: 1400px) {
                width:70%;
            }
            @media (max-width: 1200px) {
                width: 60%;
                .col_1200_w_100{
                    width: 100%;
                }
            }
            @media (max-width: 991px) {
                width: 100%;
                margin: 0 auto;
                margin-top: 20px;
                padding: 0px;
            }
        }
        .enquiries_right_box {
            padding-right: 10px;
            @media (max-width: 1400px) {
                width: 30%;
            }
            @media (max-width: 1200px) {
                width: 40%;
            }
            @media (max-width: 991px) {
                width: 100%;
                margin: 0 auto;
                padding: 0px;
            }
        }
    }
}
.activity_block {
    border: 1px solid #e2e3e4;
    padding: 20px 20px 24px 20px;
}
.status_change {
    .p-dropdown {
        border: 1px solid #e2e3e4;
        background-color: $white;
        border-radius: 0px;
        height: 50px;
        display: flex;
        align-items: center;
        &:focus {
            box-shadow: none;
            border: 1px solid #e2e3e4;
            outline: none;
        }
        &:focus-visible {
            box-shadow: none;
            border: 1px solid #e2e3e4;
            outline: none;
        }
        &:not(.p-disabled).p-focus {
            box-shadow: none;
            border: 1px solid #e2e3e4;
            outline: none;
        }
    }
    .p-dropdown-label {
        @include font_16_400;
        line-height: 16px;
        color: $dark_blue;
        padding-left: 16px;
        padding-right: 5px;
        border-right: 0px;
    }
    .p-dropdown-trigger {
        background-image: url("../../images/chevron-down_dark.svg");
        background-repeat: no-repeat;
        background-position: left;
        background-size: 20px 20px;
        width: 30px;
        height: 50px;
        margin-left: auto;
        svg {
            display: none;
        }
    }
}

.p-dropdown-panel {
    .p-dropdown-items-wrapper {
        .p-dropdown-items {
            padding-left: 0px;
            margin-bottom: 0px;
            background-color: $white;
            .p-dropdown-item {
                list-style: none;
                @include font_16_400;
                line-height: 16px;
                color: $dark_blue;
                padding: 16px;
                border-top: 1px solid #e2e3e4;
                border-right: 1px solid #e2e3e4;
                border-left: 1px solid #e2e3e4;
                border-bottom: 0px solid #e2e3e4;
                &:last-child {
                    border-bottom: 1px solid #e2e3e4;
                }
                &:hover {
                    background-color: #efc8b8;
                }
                &.p-highlight {
                    background-color: #efc8b8;
                }
            }
        }
    }
}

.steps_flow {
    margin-top: 32px;
    overflow: hidden;
    @media (max-width:767px) {
        margin-top: 24px;
    }
    .progress_bar {
        list-style: none;
        padding-left: 0px;
        margin-bottom: 0px;
        display: flex;
        flex-direction: column;
        li {
            list-style: none;
            display: flex;
            width: 100%;
            justify-content: flex-start;
            align-items: flex-start;
            // column-gap: 12px;
            position: relative;
            text-align: center;
            cursor: pointer;
            // min-height: 102px;
            min-height: 124px;
            @media (max-width: 991px) {
                min-height: 100px;
            }
            &::before {
                content: "";
                min-width: 20px;
                min-height: 20px;
                width: 20px;
                height: 20px;
                border: 2px solid #c9cacc;
                border-radius: 100%;
                display: block;
                text-align: center;
                background-color: #fff;
                // z-index: 9;
                z-index: 8;
                position: relative;
            }
            &::after {
                content: "";
                position: absolute;
                width: 3px;
                height: 100%;
                top: -100%;
                left: 8.4px;
                z-index: -1;
                border-left: 3px dashed #c9cacc;
            }
            &:first-child {
                .step_data {
                    .step_info {
                        margin-top: 0px;
                    }
                }
                &::after {
                    content: none;
                }
            }
            &.active {
                color: #f57e20;
                &::before {
                    border-color: #f57e20;
                    border-width: 5px;
                }
                &::after {
                    border-left: 3px solid #f57e20;
                    z-index: 1;
                }
            }
            .step_data {
                // margin-bottom: 40px;
                margin-bottom: 20px;
                margin-left: 20px;
                @media (max-width:767px) {
                    margin-bottom: 24px;
                    margin-right: 10px;
                }
                .step_info {
                    margin-bottom: 12px;
                    @include font_24_400;
                    font-weight: 400;
                    line-height: normal;
                    color: $black;
                    text-align: start;
                    margin-top: -4px;
                    @media (max-width:767px) {
                       font-size: 18px;
                       margin-bottom: 8px;
                    }
                }
                .step_date {
                    text-align: start;
                    margin-bottom: 0px;
                    @include font_16_400;
                    font-weight: 400;
                    line-height: 16px;
                    color: $shadeGrey;
                    @media (max-width:767px) {
                        font-size: 12px;
                     }
                    .editable-container{
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        a{
                            text-decoration: none;
                            color :#5D5D70;
                        }
                        .form-group{
                            .form-control {
                                width: 140px !important
                            }
                        }
                        .editable-btn{
                            display: flex;
                            align-items: center;
                            gap: 5px;
                        }
                      }
                }
            }
        }
    }
}
