.selected_pump_details {
    padding-top: 32px;
    @media (max-width: 767px) {
        padding-top: 24px;
    }
    .selected_top_details {
        .row{
            .col-md-4{
                @media (min-width:768px) and (max-width: 991px) {
                    width: 50% !important;
                }
            }
        }
        h1 {
            @include font_30_700;
            font-weight: 700;
            line-height: 30px;
            color: $dark_blue;
            margin-bottom: 24px;
            @media (max-width: 767px) {
                font-size: 24px;
                margin-bottom: 16px;
            }
        }
        
    }
    .selected_below_details_main {
        @media (max-width: 767px) {
            padding-bottom: 20px;
        }
    }
    .selected_below_details {
        padding: 20px 20px;
        border: 1px solid #efeff0;
        background: #f6f7f7;
        margin-bottom: 24px;
        @media (max-width: 767px) {
            padding: 24px 16px 0px 16px;
        }
        .row{
            .col-md-4{
                @media (min-width:768px) and (max-width: 991px) {
                    width: 50% !important;
                }
            }
        }
        .criteria_block {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 24px;
            .selected_heading {
                @include font_18_700;
                color: $dark_blue;
                line-height: 18px;
                font-weight: 700;
                margin-bottom: 0px;
                @media (max-width: 767px) {
                    font-size: 16px;
                }
            }
        }
    }
    .form-row {
        margin-bottom: 0px;
        input {
            background-color: $white;
        }
        select {
            background-color: $white;
        }
    }
    .extra_pumpset {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 24px;
        @media (max-width: 767px) {
            justify-content: center;
            margin-bottom: 20px;
        }
        .add_extra {
            text-decoration: none;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            @include font_16_700;
            color: $red;
            line-height: 16px;
            font-weight: 700;
        }
    }
}
