.print_data_block {
    padding: 18px 6px 36px 36px;
    background-color: #e2e3e4;
    @media (max-width: 767px) {
        padding: 16px 6px 16px 16px;
    }
    .print_data {
        .pdf_icons_box_main {
            margin-top: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            .print_data_img {
                position: relative;
                display: block;
                max-width: 100%;
                .dataImage_block {
                    max-height: 808px;
                    overflow: auto;
                    padding-right: 26px;
                    @media (max-width: 767px) {
                        padding-right: 10px;
                        max-height: 768px;
                    }
                    .data_img {
                        width: auto;
                        max-width: 100%;
                        height: auto;
                    }
                }
                // &::before {
                //     content: "";
                //     background: linear-gradient(
                //         to top,
                //         rgba(0, 0, 0, 0.42) 0%,
                //         rgba(255, 255, 255, 0) 100%
                //     );
                //     position: absolute;
                //     bottom: 0px;
                //     left: 0px;
                //     width: calc(100% - 26px);
                //     height: 37%;
                //     @media (max-width: 767px) {
                //         width: calc(100% - 10px);
                //     }
                // }
            }
            .controller_icons {
                position: absolute;
                bottom: 24px;
                left: 50%;
                transform: translate(-58%, 0%);
                @media (max-width: 767px) {
                    bottom: 10px;
                }
                ul {
                    padding-left: 0px;
                    margin-bottom: 0px;
                    list-style: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 8px;
                    li {
                        a {
                            &.pdf_icons {
                                height: 32px;
                                width: 32px;
                                @media (max-width: 767px) {
                                    height: 20px;
                                    width: 20px;
                                    svg {
                                        width: 14px;
                                        height: 14px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
