.fire_pump_booking {
    @media (max-width: 767px) {
        padding-top: 24px;
    }
    .table_heading_content {
        margin-bottom: 12px;
        @media (max-width: 767px) {
            margin-bottom: 24px;
        }
    }
    .new_pump_details {
        padding-top: 12px;
        background-color: #f6f7f7;
        min-height: auto;
        @media (min-width: 992px) {
            min-height: 381.14px;
            position: relative;
        }
        @media (min-width: 1101px) {
            min-height: 291px;
            position: static;
        }
        @media (max-width: 767px) {
            background-color: transparent;
            // margin-bottom: 122px;
            padding-top: 0px;
            padding-bottom: 48px;
        }
        // .selected_pump_shows {
        //     img {
        //         height: 52px;
        //         margin-right: 4px;
        //         @media (max-width: 767px) {
        //             height: 42px;
        //         }
        //     }
        // }
        .checkbox_find {
            .form_radio_wrapper {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 40px;
                padding-top: 10px;
                // min-height: 48px;
                @media (min-width: 992px) and (max-width: 1299px) {
                    gap: 10px;
                }
                @media (max-width: 991px) {
                    min-height: 48px;
                }
            }
            .form_radio_block {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 12px;
                input[type="radio"] {
                    width: 24px;
                    height: 24px;
                }
                .radio_label {
                    margin-bottom: 0px;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 21.92px;
                    text-align: left;
                    color: $dark_blue;
                    @media (max-width: 1399px) {
                        font-size: 14px;
                    }
                    @media (max-width: 991px) {
                        font-size: 16px;
                        line-height: 19.49px;
                    }
                }
            }
        }
        .mb__24 {
            .form-row {
                margin-bottom: 24px;

                @media (max-width: 767px) {
                    margin-bottom: 16px;
                    // &:last-child {
                    //     margin-bottom: 24px;
                    // }
                    // &:nth-child(2) {
                    //     margin-bottom: 24px;
                    // }
                }

                .pump_name_show {
                    font-size: 18px;
                    line-height: 21.92px;
                    @media (min-width: 1100px) and (max-width: 1250px) {
                        font-size: 13px;
                    }
                }
            }
            .col-md-3 {
                @media (min-width: 992px) and (max-width: 1100px) {
                    width: 50%;
                }
                @media (max-width: 991px) {
                    &:last-child {
                        .iPad_label_d-none {
                            display: none;
                        }
                    }
                }
                @media (max-width: 767px) {
                    &:nth-last-child(2) {
                        margin-bottom: 8px;
                    }
                }
            }
        }

        .form-row {
            label{
                line-height: 17.05px;
                @media (max-width: 767px) {
                    line-height: 14.62px;
                }
            }
            input {
                color: $dark_blue;
            }
        }
    }
    .sec_col_heading {
        h5 {
            font-family: "Proxima Nova Bold";
            font-size: 18px;
            font-weight: 700;
            line-height: 21.92px;
            text-align: left;
            margin-bottom: 24px;
            @media (max-width: 767px) {
                font-size: 16px;
                line-height: 18px;
            }
        }
    }
    .white_bg_field {
        @media (min-width: 1101px) {
            position: relative;
        }
        input,
        select {
            background-color: $white;
            color: $dark_blue;
        }
        .min_max_block {
            width: 100%;
        }
        .col-md-3 {
            @media (min-width: 992px) and (max-width: 1100px) {
                width: 50%;
            }
            @media (max-width: 1100px) {
                &:last-child {
                    .sec_col_heading {
                        display: none;
                    }
                }
                // &:nth-last-child(2) {
                //     .sec_col_heading {
                //         display: none;
                //     }
                // }
            }
            @media (min-width: 1101px) {
                &.mobo_sticky {
                    bottom: unset !important;
                    right: 0px !important;
                }
            }

            @media (min-width: 992px) {
                &.mobo_sticky {
                    position: absolute;
                    right: 30px;
                    bottom: 0px;                  
                }
            }
            @media (min-width: 992px) and (max-width: 1100px) {
                &.mobo_sticky {
                    width: calc(100% - 52.7%);
                }
            }
            @media (max-width: 767px) {
                &.mobo_sticky {
                    position: fixed;
                    bottom: 0px;
                    z-index: 99;
                    margin-top: 41px;
                    background-color: #fff;
                }
            }
        }
    }
    .min_max_block_main {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        @media (max-width: 1100px) {
            gap: 12px;
        }
    }
    .bottom_action_btns {
        text-decoration: none;
        &.newSearch_btn {
            padding: 28px 0px 20px 0px;
            @media (min-width: 768px) and (max-width: 991px) {
                max-width: 400px;
                margin: 0 auto;
            }
            @media (max-width: 767px) {
                padding: 12px 0px;
                margin-bottom: 0px;
                position: relative;
                &::before {
                    content: "";
                    position: absolute;
                    width: calc(100vw - 15px);
                    height: 1px;
                    background-color: #e2e3e4;
                    top: 0px;
                    left: -15px;
                    z-index: 99999;
                }
            }
        }
        .darkred_btn {
            width: 100%;
        }
    }
    .available_table_block {
        padding-top: 20px;
        @media (max-width: 767px) {
            padding-top: 24px;
        }
        .available_table {
            .p-datatable-wrapper {
                table {
                    thead {
                        tr {
                            th {
                                width: 12%;
                                min-width: 130px;
                                &:nth-child(3) {
                                    width: 10%;
                                    min-width: 100px;
                                }
                                &:nth-child(2) {
                                    width: 10%;
                                    min-width: 90px;
                                }
                                &:nth-child(5) {
                                    width: 10%;
                                    min-width: 100px;
                                }
                                &:nth-child(7) {
                                    width: 10%;
                                    min-width: 100px;
                                }
                                &:nth-child(8) {
                                    padding-right: 28px;
                                }
                                &:nth-child(9) {
                                    width: 10%;
                                    min-width: 100px;
                                    .p-column-title {
                                        text-align: center;
                                        display: block;
                                    }
                                }
                                &:last-child {
                                    .p-column-header-content {
                                        justify-content: flex-start;
                                    }
                                }
                            }
                        }
                    }
                    tbody {
                        tr {
                            td {
                                width: 12%;
                                min-width: 130px;
                                padding: 19px 0px;
                                line-height: 14px;
                                &:nth-child(3) {
                                    width: 10%;
                                    min-width: 100px;
                                }
                                &:nth-child(2) {
                                    width: 10%;
                                    min-width: 90px;
                                }
                                &:nth-child(5) {
                                    width: 10%;
                                    min-width: 100px;
                                }
                                &:nth-child(7) {
                                    width: 10%;
                                    min-width: 100px;
                                }
                                &:nth-child(8) {
                                    padding-right: 40px;
                                    text-align: right;
                                    width: 10%;
                                    min-width: 100px;
                                }
                                &:nth-child(9) {
                                    text-align: center;
                                    padding-left: 19px;
                                    width: 10%;
                                    min-width: 100px;
                                }
                                &:nth-child(10) {
                                    padding-right: 50px;
                                }
                                .checkbox_container {
                                    height: 20px;
                                    gap: 0px;
                                    .checkbox_block {
                                        label {
                                            margin-right: 0px;
                                        }
                                    }
                                }
                                .available_carts {
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-start;
                                    gap: 8px;
                                    input{
                                        border: 1px solid #E2E3E4;
                                        width: auto;
                                        min-width: auto;
                                        max-width: 41px;
                                        padding-left: 6px;
                                        height: 28px;
                                        color: #000000;
                                        font-size: 14px;
                                        &:focus-visible{
                                            outline: none;
                                        }
                                    }
                                    .form-row {
                                        margin-bottom: 0px;
                                        height: 28px;
                                        .form-label {
                                            display: none;
                                        }
                                        select {
                                            padding: 0px 6px;
                                            font-size: 14px;
                                            font-weight: 400;
                                            color: $dark_blue;
                                            min-width: 67px;
                                            height: 28px;
                                            line-height: 24px;
                                            letter-spacing: 0.01em;
                                            text-align: left;
                                            text-transform: unset !important;
                                            background-image: url("../../images/darker_down.svg");
                                            background-size: 12px !important;
                                            background-position: right 0.27rem center;
                                            option {
                                                text-transform: unset !important;
                                            }
                                        }
                                    }
                                }
                                .action_td {
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-start;
                                    gap: 40px;
                                    .action_eye {
                                        display: flex;
                                        align-items: center;
                                        justify-content: flex-start;
                                        gap: 8px;
                                        text-decoration: none;
                                        .eye_digit {
                                            font-size: 14px;
                                            line-height: 14px;
                                            color: $dark_blue;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .sticky_compare_block {
        border: 1px solid #e2e3e4;
        padding: 24px 0px;
        position: sticky;
        bottom: 0px;
        width: 100%;
        background-color: #fff;
        z-index: 99;
        margin-top: 60px;
        @media (max-width: 767px) {
            padding: 16px 0px;
            box-shadow: 0px -4px 12px 0px #0000000D;
            margin-top: 36px;
        }
        .sticky_compare {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            @media (max-width: 991px) {
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                gap: 15px;
            }
            ul {
                padding: 0px;
                margin-bottom: 0px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 16px;
                flex-wrap: wrap;
                @media (max-width: 991px) {
                    justify-content: center;;
                }
                li {
                    list-style: none;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border: 1px solid #b9b8c199;
                    padding: 5px 12px;
                    width: 100%;
                    max-width: 343px;
                    min-width: 343px;
                    height: 48px;
                    .check_pump_main{
                        margin-right: 12px;
                        @media (max-width: 991px) {
                            min-width: 45px;
                        }
                    }
                    img {
                        max-width: 36px;
                        // margin-right: 12px;
                        // @media (max-width: 767px) {
                        //     max-width: 25px;
                        // }
                        &.es_check_img{
                            max-width: 30px;
                        }
                        &.vt_check_img{
                            max-width: 23px;
                            @media (max-width: 991px) {
                                margin-left: 5px;
                                display: block;
                            }
                            @media (max-width: 767px) {
                                max-width: 28px;
                            }
                        }
                    }
                    .product_compare {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                    }
                    .product_name {
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 16px;
                        text-align: left;
                        color: #868594;
                        margin-right: 8px;
                    }
                    .product_id {
                        font-family: "Proxima Nova Bold";
                        font-size: 18px;
                        font-weight: 700;
                        line-height: 18px;
                        text-align: left;
                    }
                    .red_cross {
                        width: 18px;
                        height: 18px;
                        display: flex;
                        align-items: center;
                    }
                }
            }
            .bottom_action_btns {
                padding: 0px;
                max-width: 177px;
                margin-left: 20px;
                @media (max-width: 991px) {
                    margin-bottom: 0px;
                    margin-left: 0px;
                }
            }
            button.darkred_btn[disabled]{
                margin: 0px !important;
                pointer-events: none;
                box-shadow: none !important;
                border-radius: 0px !important;
            }
        }
    }
    &.desktop_block {
        @media (max-width: 767px) {
            .available_table_block {
                display: none;
            }
            .sticky_compare_block {
                display: none;
            }
        }
    }
    &.mobile_view_available {
        .mobile_back_btn {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 8px;
            .table_heading_content {
                margin-bottom: 0px;
            }
        }
        .sticky_compare {
            @media (max-width: 767px) {
                gap: 24px;
                ul {
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    gap: 24px;
                    width: 100%;
                    li {
                        padding: 0px 0px;
                        border: none;
                        max-width: 100%;
                        min-width: auto;
                        .product_name {
                            font-size: 14px;
                        }
                        .product_id {
                            font-size: 16px;
                        }
                    }
                }
                .bottom_action_btns {
                    margin-left: 0px;
                    max-width: 100%;
                }
            }
        }
    }
}
