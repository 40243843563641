.my_order_page {
    padding-bottom: 20px;
    .order_heading_back {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 40px;
        @media (max-width: 767px) {
            margin-bottom: 24px;
            align-items: flex-start;
        }
        .table_heading_content {
            margin-bottom: 0px;
            margin-left: 16px;
            @media (max-width: 767px) {
                margin-left: 6px;
            }
        }
        .table_heading_head{
            display: block;
            width: 100%;
        }
        // .conditional_block_show{
        //     display: none;
        // }
    }
    .my_order_list {
        margin-bottom: 0px;
        padding-left: 0px;
        list-style: none;
        li{
            margin-bottom: 16px;
            a{
                text-decoration: none;
            }
        }
        .order_head {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 16px;
            border: 1px solid #e2e3e4;
            background-color: #f6f7f7;
            h4 {
                margin-bottom: 0px;
                font-size: 18px;
                font-weight: 600;
                line-height: 18px;
                text-align: left;
                font-family: "Proxima Nova Bold";
                color: $dark_blue;
            }
            span {
                font-size: 16px;
                font-weight: 400;
                line-height: 18px;
                color: $dark_blue;
                text-align: left;
            }
        }
        .order_body {
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 16px 16px 26px 16px;
            border: 1px solid #e2e3e4;
            border-top: 0px;
            gap: 16px;
            position: relative;
            height: 137px;
            @media (max-width: 767px) {
                flex-direction: column;
                align-items: flex-start;
                padding: 16px 16px 36px 16px;
                height: 230px;
                .product_compare{
                    height: 97px;
                }
            }
            .order_pump_main{
                // @media (max-width: 767px) {
                    min-width: 61px;
                // }
            }
            .vt_order_img{
                max-width: 50px;
            }
            .my_order_status{
                width: 100%;
                max-width: 300px;
                margin-left: auto;
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 12px;
                @media (max-width: 767px) {
                    margin-left: unset;
                    min-width: 100%;
                }
                .quantity{
                    span{
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 17.05px;
                        text-align: left;
                        color: #5D5D70;
                        margin-bottom: 8px;
                    }
                    p{
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 16px;
                        text-align: left;
                        color: #000000;
                        margin-bottom: 0px;
                    }
                }
               
            }
            .pro_order{
                .product_name{
                    font-size: 16px;
                }
                .product_id{
                    font-size: 16px;
                }
            }
            .order_footer{
                font-size: 13px;
                font-weight: 400;
                line-height: 16px;
                text-align: left;
                margin-bottom: 0px;
                position: absolute;
                right: 18px;
                bottom: 13px;
                a{
                    color: #000000;
                    text-decoration: underline;
                }
            }
        }
    }
}
