header {
    background-color: $dark_red;
    position: relative;
    .nav {
        padding: 0px;
        justify-content: space-between;
        height: 58px;
        @media (max-width: 767px) {
            height: 48px;
        }
        .navbar_links_listing {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 12px;
            padding-left: 0px;
            margin: 0px;
            list-style: none;
            height: 100%;
            @media (max-width: 991px) {
                display: none;
            }
            @media (min-width: 992px) and (max-width: 1200px) {
                gap: 8px;
            }
            .navbar_links_items {
                height: 100%;
                .navbar_links {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    // gap: 10px;
                    text-decoration: none;
                    @include font_14_400;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.28px;
                    color: $white;
                    padding: 0px 16px;
                    height: 100%;
                    @media (min-width: 992px) and (max-width: 1300px) {
                        padding: 0px 8px;
                    }
                    @media (min-width: 992px) and (max-width: 1100px) {
                        font-size: 12px;
                    }
                    &:hover {
                        background-color: $high_red;
                    }
                    &.active_link {
                        background-color: #a21725;
                    }
                    svg{
                        margin-right: 10px;
                        @media (min-width: 992px) and (max-width: 1300px) {
                            margin-right: 6px;
                        }
                    }
                }
            }
        }
        .new_drop{
            display: flex;
            align-items: center;
            gap: 32px;
            @media (max-width: 991px) {
                gap: 20px;
            }
        }
        .btn-container {
            position: relative;
            @media (max-width: 991px) {
                display: none;
            }
            .dropdown_login_btn {
                display: flex;
                align-items: center;
                justify-content: center;
                // gap: 10px;
                background: $dark_blue;
                width: 100%;
                text-align: start;
                max-width: 140px;;
                padding: 8px 16px;
                font-weight: 700 !important;
                z-index: 999;
                margin-right: 0px;
                &::before {
                    content: url("../../images/Polygon_one.svg");
                    position: absolute;
                    top: -16px;
                    right: 9px;
                    @media (max-width: 991px) {
                        right: 4px;
                    }
                }
                svg {
                    width: 16px;
                    margin-right: 10px;
                }
                // @media (max-width: 991px) {
                //     right: auto;
                //     top: 40px;
                //     left: 25px;
                // }
                @media (min-width: 992px) and (max-width: 1100px) {
                    gap: 6px;
                }
                // &.show-dropdown {
                //     position: absolute;
                //     background: $dark_blue;
                //     width: 100%;
                //     text-align: start;
                //     right: -4px;
                //     max-width: 150px;
                //     padding: 8px 16px;
                //     top: 30px;
                //     font-weight: 700 !important;
                //     z-index: 999;
                //     &::before {
                //         content: url("../../images/Polygon_one.svg");
                //         position: absolute;
                //         top: -16px;
                //         right: 9px;
                //     }
                //     svg {
                //         width: 16px;
                //     }
                //     @media (max-width: 991px) {
                //         right: auto;
                //         top: 40px;
                //         left: 25px;
                //     }
                // }
            }
            .main_login{
                display: flex;
                align-items: center;
                justify-content: center;
                // gap: 10px;
                svg{
                    margin-right: 10px;
                }
                @media (min-width: 992px) and (max-width: 1100px) {
                    svg{
                        width: 20px;
                        height: 20px;
                    }
                }
            }
            .login_btn {
                background-color: transparent;
                border: none;
                color: $white;
                @include font_14_400;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.28px;
                @media (min-width: 992px) and (max-width: 1100px) {
                    font-size: 12px;
                }
            }
        }
    }
    .hamburger_box {
        display: none;
        @media (max-width: 991px) {
            display: flex;
            align-items: center;
            .cart_icon{
                padding: 0px;
                border: none;
                background-color: transparent;
                margin-right: 20px;
            }
        }
        &.open {
            .mobile_menu {
                opacity: 1;
                transition: 0.5s;
                width: 300px;
                visibility: visible;
                overflow-x: auto;
            }
            .closed_btn {
                background: transparent;
                border: none;
                padding: 0px;
                width: auto;
                position: absolute;
                top: 20px;
                right: 312px;
                z-index: 999;
                visibility: visible;
            }
        }
    }
    .mobile_menu {
        @media (max-width: 991px) {
            position: absolute;
            top: 0px;
            right: 0px;
            z-index: 999;
            transition: 0.5s;
            width: 0px;
            visibility: hidden;
            overflow-x: hidden;
        }
        .trans_mob {
            background-color: $dark_red;
            height: 100vh;
            padding: 24px 18px;
            margin-left: auto;
            .navbar_links_listing {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                height: auto;
                gap: 16px;
                margin-bottom: 16px;
                .navbar_links_items {
                    height: auto;
                    width: 100%;
                    .navbar_links {
                        padding: 8px 16px;
                        justify-content: flex-start;
                    }
                }
            }
            .btn-container {
                display: flex;
                padding: 8px 16px;
                // .dropdown_login_btn {
                //     padding: 8px 16px;
                // }
            }
        }
    }
    .hamburger {
        display: none;
        @media (max-width: 991px) {
            background-color: transparent;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .user_profile {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px;
        margin: 0px;
        &:focus-visible {
            border: 1px solid transparent;
            background-color: transparent;
            box-shadow: none;
        }
        &:active{
            border: 1px solid transparent;
            background-color: transparent;
            box-shadow: none;
            border-color: transparent !important;
        }
        @media (max-width: 991px) {
            padding: 8px 16px;
        }
        .user_profile_img {
            max-width: 27px;
            height: auto;
            border-radius: 100%;
            width: 100%;
        }
        .user_pro_name {
            white-space: nowrap;
            color: $white;
            margin-right: 6px;
            @include font_14_400;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.28px;
            @media (min-width: 992px) and (max-width: 1100px) {
                font-size: 12px;
            }
            @media (max-width: 991px) {
                margin-right: 10px;
            }
        }
        .arrow_drop {
            .arrow_drop_img {
                margin-top: -4px;
            }
        }
        .user_profile_text{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 6px;
            @media (max-width: 991px) {
                margin-right: 10px;
            }
            .user_name_show{
                background-color: rgb(177, 225, 177);
                border: none;
                border-radius: 100%;
                @include font_14_700;
                line-height: normal;
                padding: 16px 7px;
                width: auto;
                height: auto;
                min-width: 49px;
                color: $dark_blue;
            }
        }
    }
    // .cart_icon{
    //     position: relative;
    //     .booking_count{ 
    //         position: absolute;
    //         width: 18px;
    //         height: 18px;
    //         display: flex;
    //         align-items: center;
    //         justify-content: center;
    //         background-color: #F57E20;
    //         font-size: 12px;
    //         font-weight: 400;
    //         line-height: 13.52px;
    //         text-align: left;
    //         top: -1px;
    //         right: -9px;
    //         border-radius: 100px;
    //     }
    // }
}
.menu_open_overlay {
    overflow: hidden;
    position: relative;
    &::before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background: #00000080;
        z-index: 999;
        transition:  0.5s linear;
    }
}
.closed_btn {
    background: transparent;
    border: none;
    padding: 0px;
    width: auto;
    position: absolute;
    top: 20px;
    right: 0px;
    transition: 0.5s;
    visibility: hidden;
}
.new_drop{
    .btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active, button{
        background-color: transparent;
        border: none;
        padding: 0px;
        margin: 0px;
        &:hover{
            background-color: transparent;
            border: 0px;
        }
    }
    .dropdown-toggle::after{
        display: none;
    }
    .dropdown-menu{
        padding: 0px;
        background-color: transparent;
        border-radius: 0px;
        min-width: 139px;
        right: 0px !important;
        border: none;
        .dropdown-item{
            padding: 0px;
        }
        @media (max-width: 991px) {
            min-width: 141px;
        }
    }
}
.new_drop .dropdown-menu .dropdown-item{
    width: 139px;
    margin-left: auto;
}