.compare_product_page {
    .compare_product_block {
        position: relative;
    }
    .breadcrumb_verses_products {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .details_head_block {
            @media (max-width: 767px) {
                width: 100%;
            }
        }
        .details_heading_block {
            .heading_details {
                @media (max-width: 1330px) {
                    font-size: 24px;
                    margin-left: 6px;
                }
                @media (max-width: 1300px) {
                    font-size: 20px;
                }
                @media (max-width: 991px) {
                    font-size: 24px;
                }
            }
        }
        @media (max-width: 767px) {
            .crumb_link {
                line-height: 17.05px;
                font-size: 13px;
            }
        }
        .verse_compare_product {
            margin: 32px 0px;
            margin-left: 10px;
            @media (max-width: 1024px) {
                margin-left: 0px;
            }
            @media (max-width: 991px) {
                margin: 0px 0px;
                margin-top: -6px;
                margin-bottom: 16px;
                width: 100%;
            }
            ul {
                padding-left: 0px;
                margin-bottom: 0px;
                list-style: none;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 16px;
                width: 100%;
                flex-wrap: wrap;
                @media (max-width: 1024px) {
                    gap: 12px;
                }
                li {
                    position: relative;
                    min-width: 165px;
                    .product_compare {
                        // display: flex;
                        // align-items: center;
                        // justify-content: space-between;
                        // gap: 16px;
                        border: 1px solid #b9b8c1;
                        // padding: 19px 23px;
                        padding: 10px 11px;
                        @media (max-width: 1200px) {
                            padding: 11px 9px;
                        }
                        @media (max-width: 767px) {
                            align-items: flex-start;
                            gap: 8px;
                            padding: 9px 7px;
                            border: 1px solid #b9b8c14d;
                        }
                        .img_qty {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            gap: 16px;
                        }
                        img {
                            width: 100%;
                            // max-width: 46px;
                            // max-height: 58px;
                            // @media (max-width: 767px) {
                            //     width: 28px;
                            // }
                            &.es_compare_img{
                                max-width: 42px;
                                // @media (max-width: 767px) {
                                //     width: 28px;
                                // }
                            }
                            &.hp_compare_img{
                                max-width: 50px;
                                // @media (max-width: 767px) {
                                //     width: 28px;
                                // }
                            }
                            &.vt_compare_img{
                                max-width: 30px;
                                max-height: 52px;
                                // @media (max-width: 767px) {
                                //     width: 28px;
                                // }
                            }
                        }
                        .product_item {
                            display: flex;
                            align-items: center;
                            gap: 8px;
                            @media (max-width: 767px) {
                                flex-direction: column;
                                align-items: flex-start;
                            }
                            .color_text {
                                display: flex;
                                align-items: center;
                            }
                            .product_color {
                                display: inline-block;
                                width: 18px;
                                height: 18px;
                                margin-right: 8px;
                                @media (max-width: 1200px) {
                                    margin-right: 4px;
                                }
                                @media (max-width: 767px) {
                                    width: 14px;
                                    height: 14px;
                                }
                            }
                            .product_name {
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 16px;
                                color: #868594;
                                @media (max-width: 1200px) {
                                    font-size: 12px;
                                }
                            }
                            .product_id {
                                font-family: "Proxima Nova Bold";
                                font-size: 20px;
                                font-weight: 700;
                                line-height: 18px;
                                color: $dark_blue;
                                @media (max-width: 1300px) {
                                    font-size: 16px;
                                }
                                @media (max-width: 1100px) {
                                    font-size: 12px;
                                }
                                @media (max-width: 767px) {
                                    display: block;
                                    width: 100%;
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                    .qty_carts {
                        .available_carts {
                            display: flex;
                            padding-top: 12px;
                            align-items: center;
                            @media (max-width: 767px) {
                                padding-top: 8px;
                            }
                            input{
                                border: 1px solid #E2E3E4;
                                width: auto;
                                min-width: auto;
                                max-width: 41px;
                                padding-left: 6px;
                                height: 28px;
                                color: #000000;
                                font-size: 14px;
                                &:focus-visible{
                                    outline: none;
                                }
                            }
                            .form-row {
                                margin-bottom: 0px;
                                height: 28px;
                                display: flex;
                                select {
                                    padding: 2px 6px;
                                    height: 28px;
                                    margin-right: 8px;
                                    min-width: 65px;
                                    font-size: 14px;
                                    font-weight: 400;
                                    color: $dark_blue;
                                    line-height: 24px;
                                    letter-spacing: 0.01em;
                                    text-align: left;
                                    text-transform: unset !important;
                                    background-image: url("../../images/darker_down.svg");
                                    background-size: 10px !important;
                                    background-position: right 0.27rem center;
                                    color: #000000;
                                    option {
                                        text-transform: unset !important;
                                    }
                                    @media (max-width: 767px) {
                                        height: 24px;
                                        padding: 0px 6px;
                                    }
                                }
                            }
                            .cart_icon{
                                margin-top: -1px;
                            }
                            @media (max-width: 767px) {
                                a {
                                    width: 24px;
                                    height: 24px;
                                    svg {
                                        width: 24px;
                                        height: 24px;
                                        margin-top: -7.4px;
                                    }
                                }
                            }
                        }
                    }
                    .close_compare {
                        position: absolute;
                        right: -10px;
                        top: -12px;
                        @media (max-width: 767px) {
                            width: 20px;
                            height: 20px;
                            display: flex;
                            align-items: center;
                            top: -7px;
                            right: -7px;
                        }
                    }
                   
                        &::before {
                            content: url("../../images/vs.svg");
                            position: absolute;
                            right: -26px;
                            top: calc(50% - 17px);
                            z-index: 99;
                            @media (max-width: 1024px) {
                                right: -23px;
                            }
                            @media (max-width: 767px) {
                                display: none;
                            }
                        }
                        &:last-child {
                            &::before{
                                display: none;
                            }
                        }
                }
            }
        }
        @media (max-width: 991px) {
            flex-direction: column;
            align-items: flex-start;
        }
    }
}
.table_compare {
    padding: 8px 0px 100px 0px;
    @media (max-width: 767px) {
        padding: 8px 0px 34px 0px;
    }
    .compare_title {
        font-size: 18px;
        font-weight: 700;
        line-height: 21.92px;
        font-family: "Proxima Nova Bold";
        margin-bottom: 16px;
    }
    .col-md-6.px_10 {
        @media (max-width: 991px) {
            width: 100%;
            margin-bottom: 24px;
            &:last-child {
                margin-bottom: 0px;
            }
        }
    }
    .custom_dataTable {
        .p-datatable-wrapper {
            table {
                thead {
                    tr {
                        border: 1px solid #e2e3e4;
                        th {
                            padding: 12px 0px;
                            background-color: #e2e3e4;
                            width: auto;
                            min-width: auto;
                            .p-column-header-content {
                                justify-content: center;
                            }
                            .p-column-title {
                                .compare_table_heading {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    gap: 12px;
                                    @media (max-width: 1300px) {            
                                        display: block;
                                    }
                                    .color_code {
                                        width: 18px;
                                        height: 18px;
                                        display: inline-block;
                                        @media (max-width: 1300px) {
                                            margin-right: 8px;
                                            margin-bottom: -4px;
                                        }
                                    }
                                    .Product_code {
                                        font-family: "Proxima Nova Bold";
                                        font-size: 16px;
                                        font-weight: 700;
                                        line-height: 20px;
                                    @media (max-width: 767px) {   
                                        font-size: 14px;
                                    }
                                    @media (max-width: 1300px) {                                       
                                        &:last-child{
                                            display: block;
                                            width: 100%;
                                            margin-top: 5px;
                                            text-align: center;
                                        }
                                    }
                                    }
                                }
                            }
                        }
                    }
                }
                tbody {
                    tr {
                        &.p-rowgroup-header {
                            background-color: #f6f7f7;
                            td {
                                border: none !important;
                            }
                        }

                        td {
                            padding: 11px 20px;
                            width: auto;
                            min-width: auto;
                            @media (max-width: 767px) {
                                padding: 7px 14px;
                            }
                            &:first-child {
                                border-right: 1px solid #e2e3e4;
                            }
                            &:nth-child(2) {
                                border-right: 1px solid #e2e3e4;
                            }
                            .subHeading_compare {
                                justify-content: center;
                                span {
                                    font-family: "Proxima Nova Bold";
                                    font-size: 16px;
                                    font-weight: 600 !important;
                                    line-height: 16px;
                                    text-align: center;
                                    @media (max-width: 767px) {
                                        font-size: 14px;
                                        line-height: 14px;
                                    }
                                }
                            }
                            .compare_data {
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 16px;
                                text-align: center;
                                padding: 5px 0px;
                                color: #000000;
                                @media (max-width: 767px) {
                                    padding: 2px 0px;
                                    font-size: 14px;
                                    line-height: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
