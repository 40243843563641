.custom_dataTable {
    .p-datatable {
        .p-datatable-header {
            background: transparent;
            border: none;
            font-weight: normal;
            color: $dark_blue;
        }
    }
    .p-datatable-wrapper {
        table {
            thead {
                border: 1px solid #efeff0;
                background: #e9eaea;
                // pointer-events: all !important;
                tr {
                    th {
                        width: 20%;
                        min-width: 180px;
                        @include font_12_700;
                        font-weight: 700;
                        line-height: 12px;
                        color: #393d53;
                        padding: 32px 0px;
                        text-transform: uppercase;
                        pointer-events: none;
                        &:first-child {
                            padding-left: 20px;
                        }
                        &:last-child {
                            padding-right: 20px;
                            .p-column-header-content {
                                justify-content: flex-end;
                            }
                        }
                        span {
                            svg {
                                display: none;
                            }
                        }
                        &:hover {
                            cursor: auto;
                            // background: #e9eaea;
                        }
                        &.location_cell_th {
                            padding-left: 30px !important;
                        }
                    }
                }
            }
            tbody {
                tr {
                    border-right: 1px solid #e2e3e4;
                    border-bottom: 1px solid #e2e3e4;
                    border-left: 1px solid #e2e3e4;
                    td {
                        @include font_14_400;
                        line-height: 12px;
                        color: $dark_blue;
                        padding: 16px 0px;
                        width: 22%;
                        min-width: 180px;
                        padding-right: 20px;
                        .newUser {
                            @include font_14_700;
                            padding-left: 20px;
                        }
                        a{
                            color: $dark_blue;
                        }
                    }
                }
                .p-datatable-emptymessage {
                    td {
                        text-align: center;
                        padding: 24px 20px;
                        font-weight: 700;
                        text-transform: capitalize;
                    }
                }
            }
        }
    }

    .p-splitbutton-defaultbutton {
        display: none;
    }
    .p-splitbutton-menubutton {
        background-color: transparent;
        border: none;
        background-image: url("../../images/More.svg");
        background-repeat: no-repeat;
        background-size: 24px 24px;
        background-position: right;
        height: 24px;
        &:focus-visible,
        &:focus {
            box-shadow: none;
            outline: none;
            border: none;
        }
        svg {
            opacity: 0;
        }
    }
}
.p-menu {
    .p-menu-list {
        margin-bottom: 0px;
        padding-left: 0px;
        &::before {
            content: url("../../images/Polygon_one.svg");
            position: absolute;
            top: -15px;
            right: auto;
            left: 20px;
            @media (max-width: 1785px) {
                right: 5px;
                left: auto;
            }
        }
        .p-menuitem {
            border-bottom: 1px solid #5d5d70;
            .p-menuitem-link {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                // gap: 9px;
                padding: 16px;
                background: $dark_blue;
                text-decoration: none;
                text-transform: capitalize;
                border-bottom: 0px;
                min-width: 200px;
                .p-menuitem-text {
                    @include font_14_700;
                    line-height: normal;
                    font-weight: 700;
                    color: $white;
                    margin-left: 9px;
                }
                &:focus-visible {
                    outline: none;
                }
                &:focus {
                    box-shadow: none;
                }
            }
        }
    }
}
.table-header {
    background-color: transparent;
    border: none;
    h1 {
        @include font_30_700;
        line-height: 30px;
        font-weight: 700;
        color: $dark_blue;
        margin-bottom: 0px;
        @media (max-width: 767px) {
            font-size: 24px;
        }
    }
}
.customer_table {
    @media (max-width: 767px) {
        display: none;
    }
}
.user_table {
    .custom_dataTable {
        .p-datatable-wrapper {
            table {
                thead {
                    tr {
                        th {
                            width: 41%;
                        }
                    }
                }
                tbody {
                    tr {
                        td {
                            width: 41%;
                        }
                    }
                }
            }
        }
    }
}
.pump_table_width {
    .p-datatable-wrapper {
        table {
            thead {
                tr {
                    pointer-events: all;
                    cursor: auto;
                    &:first-child{
                        th{
                            &:first-child{
                                position: relative;
                                span {
                                    svg {
                                        position: absolute;
                                        right: 160px;
                                        @media (max-width:1200px) {
                                            right: 153px;
                                        }
                                    }
                                }
                            }
                            &:nth-child(3){
                                position: relative;
                                span {
                                    svg {
                                        position: absolute;
                                        right: 40px;
                                        top: 33px;
                                    }
                                }
                            }
                        }
                    }
                    &:nth-child(2){
                        th{
                                position: relative;
                                span {
                                    svg {
                                        position: absolute;
                                        right: 32px;
                                        top: 10px;
                                    }
                                }
                        }
                    }
                    th {
                        position: relative;
                        cursor: auto;
                        &.p-sortable-column{
                            cursor: pointer;
                            &:hover{
                                cursor: pointer;
                            }
                        }
                        pointer-events: all;
                        &:hover{
                            background-color:#f8f9fa;
                            cursor: auto;
                        }
                        span {
                            svg {
                                display: inline-block;
                                size: 16px;
                                height: 16px;
                                width: 16px;
                                color: $shadeGrey;
                                position: absolute;
                                        right: 60px;
                                        top: 32px;
                            }
                        }
                    }
                }
            }
        }
    }
}
.custom_dataTable .p-datatable-wrapper table thead tr th:hover{
    background-color:#f8f9fa;
}
.pump_table_width .p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover .p-sortable-column-icon{
    color: $dark_blue;
}
.pump_table_width .p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover{
    background-color:#f8f9fa;
}
