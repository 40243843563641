.order_detail_page {
    @media (max-width: 767px) {
        padding-top: 24px;
    }
    .details_head_block {
        margin: 0px;
    }
    .cancel_order {
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 767px) {
            display: none;
        }
        .order_status_btn {
            margin-right: 12px;
            height: 48px;
            min-width: 124px;
        }
    }
    .order_details_head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 32px;
        @media (max-width: 767px) {
            margin-bottom: 24px;
        }
        .darkred_btn {
            min-width: 177px;
        }
    }
    .order_detail_main {
        margin-bottom: 16px;
        .order_heading {
            font-family: "Proxima Nova Bold";
            font-size: 18px;
            font-weight: 700;
            line-height: 21.92px;
            text-align: left;
            margin-bottom: 24px;
            @media (max-width: 767px) {
                margin-bottom: 16px;
            }
        }
        .product_delivered {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            gap: 24px;
            @media (max-width: 767px) {
                flex-direction: column;
                align-items: flex-start;
            }
            .order_body {
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: #e2e3e44d;
                width: 100%;
                max-width: 100%;
                padding: 16px;
                @media (max-width: 991px) {
                    gap: 8px;
                    flex-direction: column;
                    align-items: flex-start;
                }
                .product_compare {
                    &.order {
                        gap: 26px;
                        @media (max-width: 991px) {
                            gap: 16px;
                        }
                        img {
                            width: auto;
                            height: auto;
                            @media (max-width: 991px) {
                                width: 48px;
                            }
                        }
                        .pro_order {
                            margin-bottom: 8px;
                        }
                    }
                }
                .my_order_status {
                    @media (max-width: 991px) {
                        width: 100%;
                    }
                }
                .quantity {
                    @media (max-width: 991px) {
                        width: 100%;
                        max-width: calc(100% - 66px);
                        margin-left: auto;
                        display: block;
                    }
                    span {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 17.05px;
                        text-align: left;
                        color: #5d5d70;
                        margin-bottom: 8px;
                    }
                    @media (max-width: 991px) {
                        p {
                            display: inline-block;
                            vertical-align: baseline;
                            margin-left: 8px;
                        }
                    }
                }
                p {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 16px;
                    text-align: left;
                    color: #000000;
                    margin-bottom: 0px;
                }
            }
            .delivered_to {
                width: 100%;
                max-width: 337px;
                padding: 16px 14px 16px 16px;
                border: 1px solid #e2e3e4;
                @media (max-width: 767px) {
                    max-width: 100%;
                    border: none;
                    padding: 0px;
                }
                h4 {
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 21.92px;
                    text-align: left;
                    font-family: "Proxima Nova Bold";
                    margin-bottom: 16px;
                }
                h5 {
                    strong {
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 16px;
                        text-align: left;
                        color: $dark_blue;
                    }
                }
                p {
                    font-size: 14px;
                    font-weight: 400;
                    text-align: left;
                    color: #868594;
                    margin-bottom: 8px;
                    line-height: 16px;
                    span {
                        color: $dark_blue;
                    }
                    &.address{
                        line-height: 18px;
                    }
                }
            }
        }
    }
    .order_status_progress {
        .sub_details_head {
            font-size: 18px;
            font-weight: 700;
            line-height: 21.92px;
            text-align: left;
            font-family: "Proxima Nova Bold";
        }
        .track_flow {
            padding-left: 35px;
            max-width: 995px;
            @media (max-width: 767px) {
                margin-top: 16px;
                padding-left: 0px;
            }
            .progress_bar {
                li {
                    &.active {
                        &::before {
                            border-color: #be1e2d !important;
                            border-width: 12px !important;
                        }
                        &::after {
                            border-left: 2px solid #be1e2d;
                            @media (max-width: 767px) {
                                width: 1px;
                                height: 91%;
                                left: 11px;
                                z-index: 9;
                            }
                        }
                        .fix_border {
                            &::before {
                                border-bottom: 2px solid #be1e2d;
                                width: 100%;
                                position: absolute;
                                left: 0%;
                                @media (max-width: 767px) {
                                    border-left: 2px solid #be1e2d;
                                    border-bottom: none;
                                    height: 68%;
                                    left: -2px;
                                }
                            }
                        }
                    }
                    span {
                        &.fix_border {
                            border-bottom: 2px solid #d9d9d9;
                            top: 10px;
                            @media (max-width: 767px) {
                                border-left: 2px solid #d9d9d9;
                                border-bottom: 0px;
                                left: 11px;
                            }
                        }
                    }
                    &::after {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 1px;
                        top: 10.2px;
                        left: 0%;
                        z-index: -1;
                        border-bottom: 2px dashed #d9d9d9;
                        @media (max-width: 767px) {
                            border-left: 2px dashed #d9d9d9;
                            border-bottom: 0px;
                        }
                    }
                    &::before {
                        content: "";
                        min-width: 24px;
                        min-height: 24px;
                        width: 24px;
                        height: 24px;
                        border: 1px solid $dark_blue;
                        border-radius: 100%;
                        display: block;
                        text-align: center;
                        background-color: #fff;
                        z-index: 8;
                        position: relative;
                    }
                    .step_info {
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 16px;
                        text-align: center;
                        font-family: "Proxima Nova Bold";
                        color: $dark_blue;
                        margin-bottom: 4px;
                        @media (max-width: 767px) {
                            text-align: start;
                        }
                    }
                    .step_data {
                        margin-bottom: 40px;
                        margin-left: -30px;
                        margin-top: 0px;
                        @media (max-width: 767px) {
                            margin-bottom: 61px;
                            margin-top: 2px;
                            margin-left: 12px;
                        }
                        .step_date {
                            text-align: center;
                            color: #15223b;
                            line-height: 14px;
                            @media (max-width: 767px) {
                                text-align: left;
                            }
                        }
                    }
                    &:nth-child(3) {
                        .step_data {
                            margin-left: -41px;
                            @media (max-width: 767px) {
                                margin-left: 12px;
                                text-align: left;
                            }
                        }
                    }
                    &:last-child {
                        .fix_border {
                            display: none;
                        }
                        &::after {
                            display: none;
                        }
                    }
                    &.upcoming {
                        &::before {
                            content: url("../../images/checkbg.png");
                            border: none;
                        }
                        &::after {
                            display: block;
                            left: -25%;
                            z-index: 1;
                            border-bottom: 2px solid #d9d9d9;
                            width: 60px;
                            @media (max-width: 767px) {
                                left: 11px;
                                z-index: 99;
                                border-left: 2px solid #d9d9d9;
                                border-bottom: none;
                                width: 1px;
                                top: -25px;
                                height: 25px;
                            }
                        }
                    }
                }
            }
        }
    }
}
