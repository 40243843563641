@font-face {
  font-family: "PT Sans bold";
  src: url("../../fonts/ptSans/PTSans-Bold.eot");
  src: url("../../fonts/ptSans/PTSans-Bold.eot?#iefix") format("embedded-opentype"), url("../../fonts/ptSans/PTSans-Bold.woff2") format("woff2"), url("../../fonts/ptSans/PTSans-Bold.woff") format("woff"), url("../../fonts/ptSans/PTSans-Bold.ttf") format("truetype"), url("../../fonts/ptSans/PTSans-Bold.svg#PTSans-Bold") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "PT Sans reg";
  src: url("../../fonts/ptSans/PTSans-Regular.eot");
  src: url("../../fonts/ptSans/PTSans-Regular.eot?#iefix") format("embedded-opentype"), url("../../fonts/ptSans/PTSans-Regular.woff2") format("woff2"), url("../../fonts/ptSans/PTSans-Regular.woff") format("woff"), url("../../fonts/ptSans/PTSans-Regular.ttf") format("truetype"), url("../../fonts/ptSans/PTSans-Regular.svg#PTSans-Regular") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova Black";
  src: url("../../fonts/ProximaNova-Black.eot");
  src: url("../../fonts/ProximaNova-Black.eot?#iefix") format("embedded-opentype"), url("../../fonts/ProximaNova-Black.woff2") format("woff2"), url("../../fonts/ProximaNova-Black.woff") format("woff"), url("../../fonts/ProximaNova-Black.ttf") format("truetype"), url("../../fonts/ProximaNova-Black.svg#ProximaNova-Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova Bold";
  src: url("../../fonts/ProximaNova-Bold.eot");
  src: url("../../fonts/ProximaNova-Bold.eot?#iefix") format("embedded-opentype"), url("../../fonts/ProximaNova-Bold.woff2") format("woff2"), url("../../fonts/ProximaNova-Bold.woff") format("woff"), url("../../fonts/ProximaNova-Bold.ttf") format("truetype"), url("../../fonts/ProximaNova-Bold.svg#ProximaNova-Bold") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova Rg";
  src: url("../../fonts/ProximaNova-Regular.eot");
  src: url("../../fonts/ProximaNova-Regular.eot?#iefix") format("embedded-opentype"), url("../../fonts/ProximaNova-Regular.woff2") format("woff2"), url("../../fonts/ProximaNova-Regular.woff") format("woff"), url("../../fonts/ProximaNova-Regular.ttf") format("truetype"), url("../../fonts/ProximaNova-Regular.svg#ProximaNova-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova Extrabold";
  src: url("../../fonts/ProximaNova-Extrabld.eot");
  src: url("../../fonts/ProximaNova-Extrabld.eot?#iefix") format("embedded-opentype"), url("../../fonts/ProximaNova-Extrabld.woff2") format("woff2"), url("../../fonts/ProximaNova-Extrabld.woff") format("woff"), url("../../fonts/ProximaNova-Extrabld.ttf") format("truetype"), url("../../fonts/ProximaNova-Extrabld.svg#ProximaNova-Extrabld") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova Th";
  src: url("../../fonts/ProximaNovaT-Thin.eot");
  src: url("../../fonts/ProximaNovaT-Thin.eot?#iefix") format("embedded-opentype"), url("../../fonts/ProximaNovaT-Thin.woff2") format("woff2"), url("../../fonts/ProximaNovaT-Thin.woff") format("woff"), url("../../fonts/ProximaNovaT-Thin.ttf") format("truetype"), url("../../fonts/ProximaNovaT-Thin.svg#ProximaNovaT-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
body {
  font-family: "Proxima Nova Rg";
}

* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 16px;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 12px;
}

.main_title_lh30 {
  font-family: "Proxima Nova Bold";
  font-size: 30px;
  font-style: normal;
  line-height: 30px;
}

.main_title_lh_normal {
  font-family: "Proxima Nova Bold";
  font-size: 30px;
  font-style: normal;
  line-height: normal;
}

.outline_btn {
  font-family: "Proxima Nova Bold";
  font-size: 18px;
  font-style: normal;
  line-height: 16px;
  color: #ED1D24;
  width: 200px;
  height: 48px;
  border-radius: 0;
}
.outline_btn svg {
  margin-left: 8px;
}
@media (max-width: 767px) {
  .outline_btn svg {
    width: 20px;
    height: 20px;
    margin-top: 2px;
  }
}
@media (max-width: 767px) {
  .outline_btn {
    height: 40px;
    font-size: 16px;
    width: 160px;
  }
}

.darkred_btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #ED1D24;
  width: 200px;
  height: 48px;
  font-family: "Proxima Nova Bold";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  color: white;
  line-height: 16px;
  border: 1px solid #ED1D24;
  border-radius: 0;
  text-transform: capitalize;
}
.darkred_btn:hover {
  background-color: white;
  border: 1px solid #ED1D24;
  color: #ED1D24;
}
.darkred_btn:hover svg path {
  stroke: #ED1D24;
}
.darkred_btn svg {
  margin-left: 8px;
}
@media (max-width: 767px) {
  .darkred_btn svg {
    width: 20px;
    height: 20px;
    margin-top: 2px;
  }
}
@media (max-width: 767px) {
  .darkred_btn {
    height: 40px;
    font-size: 16px;
    width: 160px;
  }
}

.transparent_btn {
  border: 1px solid #ED1D24;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #fff;
  padding: 16px;
  height: 48px;
  font-family: "Proxima Nova Bold";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  color: #ED1D24;
  line-height: 16px;
  color: #ED1D24;
  border-radius: 0;
}
.transparent_btn:hover {
  background-color: #ED1D24;
  border: 1px solid #ED1D24;
  color: #fff;
}
.transparent_btn:hover svg path {
  stroke: #fff;
}
.transparent_btn svg {
  margin-left: 10px;
}
.transparent_btn svg path {
  stroke: #ED1D24;
}
@media (max-width: 767px) {
  .transparent_btn svg {
    width: 20px;
    height: 20px;
    margin-top: 2px;
  }
}
@media (max-width: 767px) {
  .transparent_btn {
    height: 40px;
    font-size: 16px;
    width: auto;
    padding: 8px;
  }
}

.left_button svg {
  margin-left: 0px;
  margin-right: 10px;
}
@media (max-width: 767px) {
  .left_button svg {
    margin-right: 8px;
  }
}

.spread_btns {
  -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
          justify-content: space-between !important;
}

.container {
  max-width: 1520px;
  margin: 0 auto;
  padding: 0px 40px;
}
@media (max-width: 767px) {
  .container {
    padding: 0px 16px;
  }
}

.form-row {
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .form-row {
    margin-bottom: 24px;
  }
}
.form-row label {
  font-family: "Proxima Nova Rg";
  font-size: 14px;
  font-style: normal;
  color: #5D5D70;
  text-transform: capitalize;
  margin-bottom: 8px;
  display: inline-block;
  line-height: normal;
}
@media (max-width: 767px) {
  .form-row label {
    font-size: 12px;
  }
}
.form-row input {
  border: 1px solid #e2e3e4;
  background-color: transparent;
  padding: 16px;
  height: 48px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  color: #15223B;
  border-radius: 0px;
}
.form-row input:focus-visible {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
  border: 1px solid #e2e3e4;
}
@media (max-width: 767px) {
  .form-row input {
    height: 44px;
    font-size: 12px;
  }
}
.form-row select {
  border: 1px solid #e2e3e4;
  background-color: transparent;
  padding: 14px 16px;
  height: 48px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  color: #15223B;
  border-radius: 0px;
  display: block;
  width: 100%;
  background-image: url("../../images/chevron-down_dark.svg");
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 20px 20px;
}
@media (max-width: 767px) {
  .form-row select {
    height: 44px;
    font-size: 12px;
    padding: 14px 16px;
  }
}

.combine_fields {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  width: 100%;
  max-width: 100%;
}
.combine_fields .form-row:first-child {
  width: 100%;
}
.combine_fields .form-row input {
  background-color: #fff;
  width: 100%;
  border-right: 0px;
}
.combine_fields .form-row select {
  background-color: #fff;
  width: 112px;
}
@media (max-width: 767px) {
  .combine_fields .form-row select {
    width: 91px;
  }
}

.combine_field_checkbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
@media (max-width: 767px) {
  .combine_field_checkbox {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    margin-bottom: 12px;
  }
}
.combine_field_checkbox .checkbox_inner_field {
  width: 100%;
  max-width: 240px;
  margin-right: 20px;
}
@media (min-width: 992px) and (max-width: 1349px) {
  .combine_field_checkbox .checkbox_inner_field {
    max-width: 50%;
    margin-right: 10px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .combine_field_checkbox .checkbox_inner_field {
    max-width: 54%;
    margin-right: 10px;
  }
}
.combine_field_checkbox .checkbox_inner_field .mobile_certification_select {
  display: none;
}
@media (max-width: 767px) {
  .combine_field_checkbox .checkbox_inner_field {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    width: 100%;
    max-width: 100%;
    margin-bottom: 12px;
  }
  .combine_field_checkbox .checkbox_inner_field .form-row {
    width: 100%;
  }
  .combine_field_checkbox .checkbox_inner_field .form-row input {
    width: 100%;
  }
  .combine_field_checkbox .checkbox_inner_field .mobile_certification_select {
    display: block;
  }
  .combine_field_checkbox .checkbox_inner_field .mobile_certification_select .form-row select {
    width: 91px;
  }
}

.dashboard_page_main {
  position: relative;
}

.bottom_action_btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  position: static;
  width: 100%;
  left: 0px;
  bottom: 0px;
  padding: 20px 0px;
}
.bottom_action_btns .darkred_btn {
  margin-left: auto;
}
@media (max-width: 991px) {
  .bottom_action_btns .darkred_btn {
    margin-left: 0px;
  }
}
@media (max-width: 991px) {
  .bottom_action_btns {
    margin-bottom: 43px;
  }
}
@media (max-width: 767px) {
  .bottom_action_btns {
    padding: 12px 0px;
  }
}

@media (max-width: 767px) {
  .mb_md_20 {
    margin-bottom: 20px;
  }
}

.px_10 {
  padding-left: 10px;
  padding-right: 10px;
}

.mb_20 {
  margin-bottom: 20px;
}

.mb_24 {
  margin-bottom: 24px;
}

.mb_md_12 {
  margin-bottom: 12px;
}
@media (max-width: 991px) {
  .mb_md_12 {
    margin-bottom: 0px;
  }
}

.full_hr {
  border-top: 1px solid #e2e3e4;
  width: 100%;
  position: absolute;
  left: 0px;
  margin-top: -90px;
}
@media (max-width: 767px) {
  .full_hr {
    margin-top: -70px;
  }
}

@media (max-width: 991px) {
  .full_hr_iPad_bottom {
    width: 100%;
    position: absolute;
    left: 0px;
    margin-top: 90px;
    border-bottom: 1px solid #e2e3e4;
  }
}
@media (max-width: 991px) and (max-width: 767px) {
  .full_hr_iPad_bottom {
    margin-top: 70px;
  }
}

.w_max_325 {
  max-width: 325px;
}
@media (max-width: 991px) {
  .w_max_325 {
    max-width: 100% !important;
  }
}

@media (max-width: 991px) {
  .w_991_full {
    width: 100% !important;
  }
}

.dashboard {
  display: block;
  position: relative;
  min-height: 100vh;
}

div::-webkit-scrollbar-track {
  border-radius: 4px;
}

div::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

div::-webkit-scrollbar-thumb {
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 6px #808285;
  background-color: #808285;
}

.p-datatable-wrapper {
  padding-bottom: 4px;
}

.p-datatable-wrapper::-webkit-scrollbar-track {
  border-radius: 4px;
  margin-top: 10px;
}

.p-datatable-wrapper::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.p-datatable-wrapper::-webkit-scrollbar-thumb {
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 6px #808285;
  background-color: #808285;
}

.table_heading_content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 32px;
}
@media (max-width: 767px) {
  .table_heading_content {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    margin-bottom: 24px;
  }
}
.table_heading_content h1 {
  margin-bottom: 0px;
}

.breadcrumb {
  margin-bottom: 12px;
}
.breadcrumb ul {
  padding-left: 0px;
  margin-bottom: 0px;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.breadcrumb ul li .crumb_link {
  font-family: "Proxima Nova Rg";
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  color: #5D5D70;
  text-decoration: none;
  font-weight: 400;
  text-transform: capitalize;
}
.breadcrumb ul li .crumb_link::after {
  content: "/";
  margin-left: 8px;
  margin-right: 8px;
}
.breadcrumb ul li:last-child .crumb_link {
  font-family: "Proxima Nova Bold";
  font-size: 14px;
  font-style: normal;
  color: #15223B;
  font-weight: 700;
}
.breadcrumb ul li:last-child .crumb_link::after {
  display: none;
}

.details_head_block {
  margin: 32px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 767px) {
  .details_head_block {
    margin: 24px 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}

.details_heading_block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.details_heading_block .heading_details {
  font-family: "Proxima Nova Bold";
  font-size: 30px;
  font-style: normal;
  line-height: 30px;
  font-weight: 700;
  margin-bottom: 0px;
  margin-left: 16px;
}
@media (max-width: 767px) {
  .details_heading_block .heading_details {
    font-size: 24px;
    line-height: 30px;
    margin-left: 8px;
  }
}
@media (max-width: 767px) {
  .details_heading_block .back_svg {
    width: 24px;
    height: 24px;
  }
}
@media (max-width: 767px) {
  .details_heading_block .back_svg img {
    width: 24px;
    height: 24px;
    margin-top: -6px;
  }
}

.details_block {
  padding: 36px 36px 36px 36px;
  border: 1px solid #e2e3e4;
  background: #fff;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .details_block {
    padding: 24px 16px 24px 16px;
  }
}
@media (min-width: 768px) {
  .details_block:first-child {
    padding: 36px 25px 36px 36px;
  }
}
.details_block .inner_details {
  border-bottom: 1px solid #e2e3e4;
  padding-bottom: 24px;
}
.details_block .inner_details:last-child {
  border-bottom: 0px;
  padding-bottom: 0px;
}
.details_block .form-row {
  margin-bottom: 0px;
  margin-top: 32px;
}
@media (max-width: 767px) {
  .details_block .form-row {
    margin-top: 24px;
  }
}
.details_block .form-row label {
  margin-bottom: 12px;
  color: #868594;
}
.details_block .details_data {
  font-family: "Proxima Nova Rg";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #000;
  margin-bottom: 0px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}
@media (max-width: 1366px) {
  .details_block .details_data {
    width: 98%;
  }
}
@media (max-width: 1200px) {
  .details_block .details_data {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .details_block .details_data {
    font-size: 18px;
  }
}

.align_btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 12px;
}

.order_open {
  font-family: "Proxima Nova Bold";
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  height: 30px;
  vertical-align: middle;
  background-color: #fee1c8;
  padding: 8px 13px;
  border-radius: 40px;
  color: #f57e20;
}
@media (max-width: 767px) {
  .order_open {
    font-size: 10px;
    padding: 9px 13px;
  }
}

.order_closed {
  font-family: "Proxima Nova Bold";
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  height: 30px;
  vertical-align: middle;
  background-color: #CCFEC8;
  padding: 8px 13px;
  border-radius: 40px;
  color: #45AB43;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .order_closed {
    font-size: 10px;
    padding: 9px 13px;
  }
}

.right_align_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.right_align_box .form-row {
  margin-left: auto;
}

@media (max-width: 1200px) {
  .iPad_col_100 {
    width: 100%;
  }
  .iPad_col_100 .form-row {
    margin-left: 0px;
  }
}

@media (max-width: 1200px) {
  .ipad_col_50_100 {
    width: 50%;
  }
  .ipad_col_50_100 .form-row {
    margin-left: 0px;
  }
}
@media (max-width: 991px) {
  .ipad_col_50_100 {
    width: 100%;
  }
}

@media (max-width: 1200px) {
  .ipad_col_50 {
    width: 50%;
  }
  .ipad_col_50 .form-row {
    margin-left: 0px;
  }
}

@media (max-width: 991px) {
  .w-max-991_col_50 {
    width: 50%;
  }
}

.sub_details_head {
  font-family: "Proxima Nova Bold";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  color: #15223B;
  margin-bottom: -8px;
}
@media (max-width: 767px) {
  .sub_details_head {
    font-size: 16px;
    margin-bottom: 0px;
  }
}

.result_found_wrapper {
  position: absolute;
  width: 100%;
  max-width: 320px;
  z-index: 99;
  top: 12px;
  left: 50%;
  -webkit-transform: translate(-50%, 0px);
          transform: translate(-50%, 0px);
}
.result_found_wrapper.ToastMsgAdd .result_found {
  background-color: #F57E20 !important;
  border: 1px solid #F57E20 !important;
}
.result_found_wrapper.ToastMsgAdd .result_found .result_found_text {
  color: #000 !important;
}
.result_found_wrapper.ToastMsgAdd .result_found button svg path {
  stroke: #000 !important;
}
@media (max-width: 767px) {
  .result_found_wrapper {
    top: 15px;
  }
}
.result_found_wrapper .result_found {
  border: 1px solid #9CC559;
  padding: 12px 10px 12px 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #B9E471;
  border-radius: 4px;
}
.result_found_wrapper .result_found .result_found_text {
  margin: 0;
  font-size: 14px;
  line-height: 17.05px;
  color: #15223B;
  font-weight: 700;
  padding-right: 5px;
}
.result_found_wrapper .result_found button {
  border: none;
  background-color: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.result_found_wrapper .result_found.hide {
  display: none;
}
.result_found_wrapper.error-toaster .result_found {
  background-color: #BE1E2D;
  border: 1px solid #e80f17;
}
.result_found_wrapper.error-toaster .result_found .result_found_text {
  color: #fff;
}
.result_found_wrapper.error-toaster .result_found button svg path {
  stroke: #fff;
}

.search_result_head_section {
  padding-top: 80px;
}
@media (max-width: 767px) {
  .search_result_head_section {
    padding-top: 24px;
  }
}

section .table_head .table_head_wrapper {
  padding: 35px 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  overflow: hidden;
  position: relative;
  background-image: url("../../images/table_nav_img-without-logo.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  height: 128px;
}
@media (max-width: 991px) {
  section .table_head .table_head_wrapper {
    background-size: cover;
  }
}
@media (max-width: 767px) {
  section .table_head .table_head_wrapper {
    padding: 16px;
    height: 88px;
  }
}
section .table_head .table_head_wrapper .table_header_bg {
  position: absolute;
  right: 52px;
  top: 50%;
  -webkit-transform: translate(30px, -50%);
          transform: translate(30px, -50%);
}
@media (max-width: 1100px) {
  section .table_head .table_head_wrapper .table_header_bg {
    right: 20px;
  }
}
section .table_head .table_head_wrapper .table_header_bg img {
  width: 175px;
  height: auto;
}
@media (max-width: 1100px) {
  section .table_head .table_head_wrapper .table_header_bg img {
    width: 135px;
  }
}
@media (max-width: 767px) {
  section .table_head .table_head_wrapper .table_header_bg img {
    width: 80px;
  }
}
section .table_head .table_head_wrapper .table_head_left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
section .table_head .table_head_wrapper .table_head_left .arrow_left_img {
  margin-right: 18px;
}
@media (max-width: 767px) {
  section .table_head .table_head_wrapper .table_head_left .arrow_left_img {
    height: 24px;
    width: 24px;
    margin-right: 8px;
  }
}
section .table_head .table_head_wrapper .table_head_left .table_head_text .table_head_heading {
  font-size: 30px;
  line-height: 100%;
  font-weight: 700;
  color: #15223B;
  margin-bottom: 12px;
}
@media (max-width: 767px) {
  section .table_head .table_head_wrapper .table_head_left .table_head_text .table_head_heading {
    font-size: 24px;
    line-height: 30px;
  }
}
section .table_head .table_head_wrapper .table_head_left .table_head_text .table_head_title {
  font-size: 16px;
  line-height: 100%;
  color: #868594;
  margin: 0;
}
@media (max-width: 767px) {
  section .table_head .table_head_wrapper .table_head_left .table_head_text .table_head_title {
    line-height: 14px;
  }
}
section .table_head .table_head_wrapper .table_head_right {
  position: absolute;
  right: 24px;
  bottom: 16px;
  max-width: 130px;
  display: none;
}
@media (max-width: 767px) {
  section .table_head .table_head_wrapper .table_head_right {
    max-width: 66px;
    height: auto;
    right: 0px;
  }
}
section .table_head .table_head_wrapper .table_head_right img {
  max-width: 100%;
  max-height: 170px;
}

@media (max-width: 767px) {
  .table_criteria {
    overflow: auto;
  }
}
.table_criteria .table_criteria_wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #BE1E2D;
  padding: 15px 15px 16px 20px;
  min-width: 1160px;
}
@media (max-width: 767px) {
  .table_criteria .table_criteria_wrapper {
    padding: 0px;
    overflow: unset;
  }
}
.table_criteria .table_criteria_wrapper .table_criteria_block {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  width: 100%;
  min-width: 70px;
  margin-right: auto;
  position: relative;
}
@media (max-width: 767px) {
  .table_criteria .table_criteria_wrapper .table_criteria_block {
    padding: 14px 0px 14px 16px;
  }
  .table_criteria .table_criteria_wrapper .table_criteria_block::after {
    display: none;
  }
}
.table_criteria .table_criteria_wrapper .table_criteria_block:last-child {
  border: none;
}
.table_criteria .table_criteria_wrapper .table_criteria_block:last-child::after {
  display: none;
}
.table_criteria .table_criteria_wrapper .table_criteria_block::after {
  content: "";
  position: absolute;
  right: -32px;
  top: 0;
  height: 100%;
  width: 1px;
  background-color: #fff;
}
@media (max-width: 1280px) {
  .table_criteria .table_criteria_wrapper .table_criteria_block::after {
    right: -16px;
  }
}
@media (max-width: 767px) {
  .table_criteria .table_criteria_wrapper .table_criteria_block::after {
    height: 69%;
    top: 9px;
  }
}
.table_criteria .table_criteria_wrapper .table_criteria_block .table_criteria_text {
  font-size: 16px;
  line-height: 19.49px;
  font-weight: 700;
  color: #fff;
  margin: 0;
}
.table_criteria .table_criteria_wrapper .table_criteria_block .table_criteria_uppertext {
  font-size: 14px;
  line-height: 17.05px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 4px;
}
.table_criteria .table_criteria_wrapper .table_criteria_block .table_criteria_lowertext {
  font-size: 16px;
  line-height: 100%;
  color: #fff;
  font-weight: 400;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .sm-px-5:nth-child(even) {
    padding-left: 3px;
    padding-right: 2px;
    width: 45.5%;
  }
  .sm-px-5:nth-child(odd) {
    padding-right: 0px;
    width: 54.5%;
  }
}

input[type=radio] {
  height: 20px;
  width: 20px;
  accent-color: #ED1D24;
}

.table_checkbox .p-radiobutton .p-radiobutton-box {
  border: 2px solid #ED1D24;
  background-color: #fff;
}
.table_checkbox .p-radiobutton .p-radiobutton-box.p-focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.table_checkbox .p-radiobutton .p-radiobutton-icon {
  background-color: #ED1D24;
}
.table_checkbox .p-radiobutton.p-highlight:not(.p-disabled):hover {
  background-color: #fff;
}

.send_enquiry_head .send_enquiry_row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 20px;
}
.send_enquiry_head .send_enquiry_row .enquiries_img_box {
  padding: 11px 8px;
  border: 1px solid #e2e3e4;
}
.send_enquiry_head .send_enquiry_row .enquiries_img_box img {
  width: 83px;
  height: auto;
}
.send_enquiry_head .send_enquiry_row .enq_data_main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 40px;
}
@media (max-width: 767px) {
  .send_enquiry_head .send_enquiry_row .enq_data_main {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 0px;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}
.send_enquiry_head .send_enquiry_row .enq_data_block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 12px;
}
.send_enquiry_head .send_enquiry_row .enq_label {
  margin-top: 16px;
  font-family: "Proxima Nova Rg";
  font-size: 14px;
  font-style: normal;
  color: #868594;
  line-height: normal;
}
@media (max-width: 767px) {
  .send_enquiry_head .send_enquiry_row .enq_label {
    margin-top: 8px;
  }
}
.send_enquiry_head .send_enquiry_row .enq_data {
  margin-top: 16px;
  font-family: "Proxima Nova Rg";
  font-size: 16px;
  font-style: normal;
  color: #000;
  line-height: 16px;
}
@media (max-width: 767px) {
  .send_enquiry_head .send_enquiry_row .enq_data {
    margin-top: 8px;
  }
}

.popup_pump_heading_block {
  padding: 32px 32px 0px 32px;
}
@media (max-width: 767px) {
  .popup_pump_heading_block {
    padding: 24px 16px 0px 16px;
  }
}

.send_enquiry_heading {
  font-family: "Proxima Nova Bold";
  font-size: 18px;
  font-style: normal;
  line-height: 18px;
  font-weight: 700;
  color: #15223B;
  margin-bottom: 0px;
}

.addNewBtnShow {
  display: block !important;
}

.modificationShow {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.modification_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 24px;
}
@media (max-width: 767px) {
  .modification_box {
    margin-top: 20px;
  }
}

.visible_toggle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 12px;
}
.visible_toggle span {
  font-family: "Proxima Nova Rg";
  font-size: 18px;
  font-style: normal;
  color: #15223B;
  font-weight: 400;
  line-height: normal;
}

.new_pump_toggle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
@media (max-width: 767px) {
  .new_pump_toggle {
    gap: 0px;
  }
}

.toggle_checkbox {
  position: relative;
}
.toggle_checkbox input[type=checkbox] {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.toggle_checkbox input[type=checkbox]:checked + label:before {
  background: #0bda6c;
}
.toggle_checkbox input[type=checkbox]:checked + label:after {
  left: 27px;
}
.toggle_checkbox label {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.toggle_checkbox label::before {
  content: "";
  width: 49px;
  height: 24px;
  background: #e2e3e4;
  position: relative;
  display: inline-block;
  border-radius: 46px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}
.toggle_checkbox label::after {
  content: "";
  position: absolute;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  left: 2.94px;
  top: 2.4px;
  z-index: 2;
  background: #fff;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}

.edit_deleted_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 39px;
}
@media (max-width: 767px) {
  .edit_deleted_box {
    gap: 20px;
  }
}
.edit_deleted_box a {
  font-family: "Proxima Nova Rg";
  font-size: 18px;
  font-style: normal;
  line-height: normal;
  color: #ED1D24;
  text-decoration: none;
}

.edit_box,
.delete_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 8px;
}

@media (max-width: 991px) {
  .checkbox_main {
    margin-top: -6px;
  }
}

.checkbox_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 40px;
  height: 48px;
}
@media (max-width: 767px) {
  .checkbox_container {
    height: 24px;
    gap: 10px;
  }
}
.checkbox_container .checkbox_block {
  margin-bottom: 0px;
}
.checkbox_container .checkbox_block label {
  margin-bottom: 0px;
}
.checkbox_container .checkbox_block input[type=checkbox]:checked + label:after {
  background-size: 20px 20px;
}
@media (max-width: 767px) {
  .checkbox_container .checkbox_block input[type=checkbox]:checked + label:after {
    background-size: 20px 20px;
  }
}
.checkbox_container .checkbox_block label {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 20px;
  height: 20px;
}
@media (max-width: 1399px) {
  .checkbox_container .checkbox_block label {
    width: 20px;
    height: 20px;
  }
}
@media (max-width: 767px) {
  .checkbox_container .checkbox_block label {
    width: 20px;
    height: 20px;
  }
}
.checkbox_container .checkbox_block label::after {
  width: 20px;
  height: 20px;
  background-size: 20px 20px;
}
@media (max-width: 1399px) {
  .checkbox_container .checkbox_block label::after {
    background-size: 20px 20px;
    width: 20px;
    height: 20px;
  }
}
@media (max-width: 767px) {
  .checkbox_container .checkbox_block label::after {
    background-size: 20px 20px;
    width: 20px;
    height: 20px;
  }
}
@media (min-width: 768px) and (max-width: 1399px) {
  .checkbox_container .checkbox_block span {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .checkbox_container .checkbox_block span {
    font-size: 12px;
  }
}

.checkbox_block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  margin-bottom: 13px;
  position: relative;
}
@media (max-width: 767px) {
  .checkbox_block {
    margin-bottom: 0px;
  }
}
.checkbox_block input[type=checkbox] {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.checkbox_block input[type=checkbox]:checked + label:after {
  background-image: url("../../images/Chechbox.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px 24px;
}
@media (max-width: 767px) {
  .checkbox_block input[type=checkbox]:checked + label:after {
    background-size: 16px 16px;
  }
}
.checkbox_block label {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
@media (max-width: 1399px) {
  .checkbox_block label {
    width: 20px;
    height: 20px;
  }
}
@media (max-width: 767px) {
  .checkbox_block label {
    width: 16px;
    height: 16px;
  }
}
.checkbox_block label::after {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  right: 0px;
  z-index: 2;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-image: url("../../images/CheckedNo.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px 24px;
}
@media (max-width: 1399px) {
  .checkbox_block label::after {
    background-size: 20px 20px;
    width: 20px;
    height: 20px;
  }
}
@media (max-width: 767px) {
  .checkbox_block label::after {
    background-size: 16px 16px;
    width: 16px;
    height: 16px;
  }
}
.checkbox_block span {
  font-family: "Proxima Nova Rg";
  font-size: 16px;
  font-style: normal;
  line-height: 16px;
  color: #15223B;
  margin-bottom: 0px;
}
@media (min-width: 768px) and (max-width: 1399px) {
  .checkbox_block span {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .checkbox_block span {
    font-size: 12px;
  }
}

.search_field_block {
  position: relative;
  margin-right: 16px;
}
@media (max-width: 767px) {
  .search_field_block {
    margin-right: 8px;
  }
}
.search_field_block .form-row {
  margin-bottom: 0px;
}
.search_field_block .search_svg {
  position: absolute;
  left: 16px;
  top: 10px;
  height: auto;
  width: 16px;
}
@media (max-width: 767px) {
  .search_field_block .search_svg {
    top: 7px;
  }
}
.search_field_block .search_field {
  padding-left: 42px;
  font-family: "Proxima Nova Bold";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  color: #868594;
  width: 332px;
  max-width: 322px;
}
@media (max-width: 767px) {
  .search_field_block .search_field {
    width: 100%;
    max-width: 100%;
  }
}
.search_field_block .search_field::-webkit-input-placeholder {
  font-family: "Proxima Nova Bold";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  color: #868594;
}
.search_field_block .search_field::-moz-placeholder {
  font-family: "Proxima Nova Bold";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  color: #868594;
}
.search_field_block .search_field:-ms-input-placeholder {
  font-family: "Proxima Nova Bold";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  color: #868594;
}
.search_field_block .search_field::-ms-input-placeholder {
  font-family: "Proxima Nova Bold";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  color: #868594;
}
.search_field_block .search_field::placeholder {
  font-family: "Proxima Nova Bold";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  color: #868594;
}

.uploaded_pdf {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-width: 333px;
  padding: 16px;
  background-color: #f3f4f4;
}
.uploaded_pdf .pdf_left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.uploaded_pdf .pdf_left .pdf_details p {
  font-family: "Proxima Nova Rg";
  font-size: 14px;
  font-style: normal;
  color: #5D5D70;
  line-height: normal;
  margin-bottom: 2px;
}
.uploaded_pdf .pdf_left .pdf_details a {
  font-family: "Proxima Nova Rg";
  font-size: 18px;
  font-style: normal;
  color: #15223B;
  line-height: normal;
  margin-bottom: 0px;
  text-decoration: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;
  overflow: hidden;
  width: 100%;
  display: block;
  pointer-events: none;
}
.uploaded_pdf .pdf_right_icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 8px;
}

.filterOpen .overlay {
  width: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.4588235294);
  right: 0px;
  height: 200vh;
  top: -90px;
  z-index: 9;
  pointer-events: none;
}

.overflow_hidden {
  overflow: hidden;
}

.pumps_tabs .p-tabview .p-tabview-nav {
  background: transparent;
  border: none;
  border-width: 0;
  padding-left: 0px;
  margin-bottom: 32px;
  padding: 0px;
  list-style-type: none;
  list-style: none;
}
@media (max-width: 767px) {
  .pumps_tabs .p-tabview .p-tabview-nav {
    margin-bottom: 24px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
}
.pumps_tabs .p-tabview .p-tabview-nav .p-unselectable-text .p-tabview-nav-link {
  text-decoration: none;
  border: none;
  border-radius: 0px;
}
.pumps_tabs .p-tabview .p-tabview-nav .p-unselectable-text .p-tabview-nav-link span {
  font-family: "Proxima Nova Rg";
  font-size: 18px;
  font-style: normal;
  color: #000;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.18px;
  padding: 5px 24px;
  border: 1px solid #5d5d70;
  border-radius: 0px;
  border-right: 0px;
}
@media (max-width: 767px) {
  .pumps_tabs .p-tabview .p-tabview-nav .p-unselectable-text .p-tabview-nav-link span {
    font-size: 16px;
    letter-spacing: 0.16px;
  }
}
.pumps_tabs .p-tabview .p-tabview-nav .p-unselectable-text.p-highlight {
  background-color: #f57e20;
  display: inline-block;
  overflow: hidden;
}
.pumps_tabs .p-tabview .p-tabview-nav .p-unselectable-text.p-highlight .p-tabview-nav-link span {
  border: 1px solid #f57e20;
}
.pumps_tabs .p-tabview .p-tabview-nav .p-unselectable-text:nth-last-child(2) span {
  border-right: 1px solid #5d5d70;
}

.custom_unit {
  font-family: "Proxima Nova Rg";
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  color: #5D5D70;
}
@media (max-width: 767px) {
  .custom_unit {
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  .md-none {
    display: none;
  }
}

.pdf_icons_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 12px;
  position: absolute;
  right: 50px;
  top: 24px;
}
@media (max-width: 767px) {
  .pdf_icons_box {
    right: 20px;
    top: 10px;
    gap: 8px;
  }
}

.pdf_icons {
  background-color: #fff;
  border-radius: 100%;
  border: 1px solid #e2e3e4;
  -webkit-filter: drop-shadow(3px 5px 3px rgba(0, 0, 0, 0.07));
          filter: drop-shadow(3px 5px 3px rgba(0, 0, 0, 0.07));
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 45px;
  width: 45px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
@media (max-width: 767px) {
  .pdf_icons {
    height: 30px;
    width: 30px;
  }
  .pdf_icons svg {
    width: 16px;
    height: 16px;
  }
}
.pdf_icons:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.stockAvailability_two_col.custom_dataTable .p-datatable-wrapper table thead tr th {
  width: 50%;
}
.stockAvailability_two_col.custom_dataTable .p-datatable-wrapper table thead tr th:last-child {
  width: 50% !important;
}
.stockAvailability_two_col.custom_dataTable .p-datatable-wrapper table thead tr th:last-child .p-column-header-content {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.stockAvailability_two_col.custom_dataTable .p-datatable-wrapper table tbody tr td {
  width: 50%;
}
.stockAvailability_two_col.custom_dataTable .p-datatable-wrapper table tbody tr td:last-child {
  width: 50% !important;
  text-align: left !important;
}

.popUpStockAvailability_two_col.custom_dataTable .p-datatable-wrapper table thead tr th {
  width: 65%;
}
.popUpStockAvailability_two_col.custom_dataTable .p-datatable-wrapper table thead tr th:last-child {
  width: 35% !important;
}
.popUpStockAvailability_two_col.custom_dataTable .p-datatable-wrapper table thead tr th:last-child .p-column-header-content {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  padding-left: 20px;
}
.popUpStockAvailability_two_col.custom_dataTable .p-datatable-wrapper table tbody tr td {
  width: 65%;
}
.popUpStockAvailability_two_col.custom_dataTable .p-datatable-wrapper table tbody tr td:last-child {
  width: 35% !important;
  text-align: left !important;
  padding-left: 20px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .min-768_max-991_wid {
    width: 50% !important;
  }
}

.selected_pump_shows {
  border: 1px solid #ED1D24;
  background: #fff;
  padding: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 83px;
}
@media (max-width: 767px) {
  .selected_pump_shows {
    height: 64px;
    padding: 9px 12px 9px 16px;
  }
}
.selected_pump_shows .selected_pump_img_name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 12px;
}
@media (min-width: 767px) and (max-width: 991px) {
  .selected_pump_shows .selected_pump_img_name {
    gap: 20px !important;
  }
}
@media (max-width: 991px) {
  .selected_pump_shows .selected_pump_img_name .pump_field_img {
    min-width: 60px;
  }
}
@media (max-width: 767px) {
  .selected_pump_shows .selected_pump_img_name .pump_field_img {
    min-width: 40px;
  }
}
@media (min-width: 768px) {
  .selected_pump_shows .selected_pump_img_name .vt_pump_img {
    max-width: 45px;
    min-width: 45px;
    min-height: 81px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .selected_pump_shows .selected_pump_img_name .vt_pump_img {
    margin: 0 auto;
  }
}
@media (max-width: 767px) {
  .selected_pump_shows .selected_pump_img_name .vt_pump_img {
    min-width: 32px;
    min-height: 60px;
    max-width: -webkit-max-content;
    max-width: -moz-max-content;
    max-width: max-content;
    display: block;
  }
}
.selected_pump_shows .selected_pump_img_name img {
  max-width: 56px;
  max-height: 64px;
}
@media (max-width: 767px) {
  .selected_pump_shows .selected_pump_img_name img {
    max-width: 42px;
    max-height: 50px;
  }
}
.selected_pump_shows .selected_pump_img_name .pump_name_show {
  font-family: "Proxima Nova Rg";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #15223B;
  white-space: nowrap;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .selected_pump_shows .selected_pump_img_name .pump_name_show {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .selected_pump_shows .selected_pump_img_name .pump_name_show {
    font-size: 16px;
  }
}
.selected_pump_shows .change_type a {
  font-family: "Proxima Nova Rg";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #ED1D24;
  text-decoration: none;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .selected_pump_shows .change_type a {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .selected_pump_shows .change_type a {
    font-size: 12px;
    line-height: 16px;
  }
}

@media (max-width: 991px) {
  .form-row .iPad_label_d-none {
    display: none;
  }
}

.fileTypeField {
  border: 1.5px dashed #808285;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 92px;
  padding: 20px;
}
@media (max-width: 767px) {
  .fileTypeField {
    padding: 9px 12px 9px 16px;
    height: auto;
  }
}
.fileTypeField p,
.fileTypeField label {
  margin-bottom: 0px;
}
.fileTypeField .input_file_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.fileTypeField .input_file_container span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-right: 12px;
}
.fileTypeField .input_file_container .instruction_show,
.fileTypeField .input_file_container .customFileInput {
  font-family: "Proxima Nova Bold";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
@media (max-width: 767px) {
  .fileTypeField .input_file_container .instruction_show,
  .fileTypeField .input_file_container .customFileInput {
    font-size: 12px;
  }
}
.fileTypeField .input_file_container .browse_text {
  color: #F57E20;
  cursor: pointer;
  font-weight: 700;
}
.fileTypeField .pdf_file_uploaded {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
}
@media (max-width: 767px) {
  .fileTypeField .pdf_file_uploaded {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    gap: 12px;
  }
}
.fileTypeField .pdf_file_uploaded .pdf_details_show {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 8px;
}
.fileTypeField .pdf_file_uploaded .pdf_details_show .pdf_name_size .file_name {
  font-family: "Proxima Nova Bold";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  color: #15223B;
  line-height: 18px;
}
@media (max-width: 767px) {
  .fileTypeField .pdf_file_uploaded .pdf_details_show .pdf_name_size .file_name {
    font-size: 16px;
  }
}
.fileTypeField .pdf_file_uploaded .pdf_details_show .pdf_name_size .file_size {
  font-family: "Proxima Nova Rg";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #5D5D70;
  line-height: normal;
  margin-top: 8px;
}
@media (max-width: 767px) {
  .fileTypeField .pdf_file_uploaded .pdf_details_show .pdf_name_size .file_size {
    font-size: 12px;
  }
}
.fileTypeField .pdf_file_uploaded .delete_and_change .uploaded_pdf_icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 12px;
}
@media (max-width: 767px) {
  .fileTypeField .pdf_file_uploaded .delete_and_change .uploaded_pdf_icon {
    gap: 8px;
    padding-left: 10px;
  }
}
.fileTypeField .pdf_file_uploaded .delete_and_change .uploaded_pdf_icon .icons_block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 8px;
}
@media (max-width: 767px) {
  .fileTypeField .pdf_file_uploaded .delete_and_change .uploaded_pdf_icon .icons_block {
    gap: 4px;
  }
}
.fileTypeField .pdf_file_uploaded .delete_and_change .uploaded_pdf_icon .icons_block .icon_svg {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (max-width: 767px) {
  .fileTypeField .pdf_file_uploaded .delete_and_change .uploaded_pdf_icon .icons_block .icon_svg svg {
    width: 16px;
    height: 16px;
  }
}
.fileTypeField .pdf_file_uploaded .delete_and_change .uploaded_pdf_icon .icons_block .icon_text {
  font-family: "Proxima Nova Rg";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: #808285;
  line-height: 16px;
  text-decoration: none;
}
@media (max-width: 767px) {
  .fileTypeField .pdf_file_uploaded .delete_and_change .uploaded_pdf_icon .icons_block .icon_text {
    font-size: 14px;
  }
}

.field_wrapper {
  position: relative;
}
.field_wrapper .p-error {
  position: absolute;
  bottom: 0px;
  white-space: nowrap;
}
@media (max-width: 767px) {
  .field_wrapper .p-error {
    font-size: 12px;
  }
}

.card {
  border: none;
}

.p-paginator-first {
  display: none;
}

.p-paginator-last {
  display: none;
}

.p-paginator-prev {
  border: none;
  background-color: rgba(0, 0, 0, 0);
  margin-right: 15px;
}
@media (max-width: 767px) {
  .p-paginator-prev {
    margin-right: 5px;
  }
}

.p-paginator-next {
  margin-left: 15px;
  border: none;
  background-color: rgba(0, 0, 0, 0);
}
@media (max-width: 767px) {
  .p-paginator-next {
    margin-left: 5px;
  }
}

.p-paginator {
  margin-top: 24px;
  border-bottom: 0px;
  padding-bottom: 46px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.p-paginator button {
  width: 30px;
  min-width: 30px;
  height: 30px;
  border: none;
}
@media (max-width: 767px) {
  .p-paginator button {
    width: 25px;
    min-width: 25px;
    height: 25px;
  }
}
.p-paginator button:hover {
  background-color: #e2e3e4;
  border-radius: 6px;
}
.p-paginator button:focus-visible {
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: none;
}
.p-paginator button:disabled:hover {
  background-color: rgba(0, 0, 0, 0);
}
.p-paginator button:disabled svg path {
  fill: #81878d;
}
@media (max-width: 767px) {
  .p-paginator button svg {
    width: 15px;
    height: 15px;
  }
}
.p-paginator button svg path {
  stroke-width: 1.5px;
  fill: #ED1D24;
}

.p-paginator-pages .p-paginator-page {
  font-family: "PT Sans bold";
  margin: 0px 10px;
  min-width: 30px;
  width: 30px;
  height: 30px;
  font-size: 14px;
  line-height: normal;
  font-weight: 700;
  color: #81878d;
  border-radius: 6px;
  border: none;
}
@media (max-width: 767px) {
  .p-paginator-pages .p-paginator-page {
    font-size: 12px;
    width: 25px;
    min-width: 25px;
    height: 25px;
    margin: 0px 5px;
  }
}
.p-paginator-pages .p-paginator-page:hover {
  background-color: #ED1D24;
  color: #fff;
  border: none;
}
.p-paginator-pages .p-paginator-page.p-highlight {
  background-color: #ED1D24;
  color: #fff;
  border: none;
}

.form-control:disabled {
  background-color: transparent;
}

.status_shows {
  border: 1px solid #e2e3e4;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 28px;
  border-radius: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0px 10px;
  white-space: nowrap;
}
.status_shows.sales_status svg {
  margin-right: 6px;
}
.status_shows.sales_status .sales_status_text {
  text-transform: capitalize;
}

.form-row input[type=textarea] {
  height: 100px;
  width: 100%;
  min-width: 100%;
}
.form-row .msg_textarea {
  height: 100px;
  width: 100%;
  min-width: 100%;
  word-break: break-word;
  border: 1px solid #e2e3e4;
  background-color: transparent;
  padding: 16px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  color: #15223B;
  border-radius: 0px;
}
.form-row .msg_textarea:focus-visible {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
  border: 1px solid #e2e3e4;
}
@media (max-width: 767px) {
  .form-row .msg_textarea {
    height: 88px;
    font-size: 12px;
  }
}

.min_100 {
  min-width: 100%;
}

@media (max-width: 767px) {
  .add_new {
    margin-top: 24px;
  }
}

.required_field .form-row label {
  position: relative;
}
.required_field .form-row label::after {
  content: "*";
  position: absolute;
  top: 1px;
  right: -7px;
  font-size: 12px;
  color: #BE1E2D;
}

.prime_dropdown .form-row .p-dropdown.p-component {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  min-height: 48px;
  width: 100% !important;
  border: 1px solid #e2e3e4;
  width: 100% !important;
  border-radius: 0px;
  padding: 16px 0px 16px 16px;
  font-family: "Proxima Nova Rg";
  font-size: 16px;
  font-style: normal;
  color: #15223B;
  line-height: 16px;
}
@media (max-width: 767px) {
  .prime_dropdown .form-row .p-dropdown.p-component {
    font-size: 12px;
    height: 44px;
  }
}
.prime_dropdown .form-row .p-dropdown.p-component:focus-visible {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
  border: 1px solid #e2e3e4;
}
.prime_dropdown .form-row .p-dropdown.p-component option {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  font-family: "Proxima Nova Rg";
  font-size: 16px;
  font-style: normal;
  color: #15223B;
  padding: 16px 0px !important;
  width: 100%;
}
.prime_dropdown .form-row .p-dropdown.p-component input {
  height: 100%;
  min-height: 48px;
  padding: 16px 0px !important;
}
.prime_dropdown .form-row .p-dropdown.p-component input::-webkit-input-placeholder {
  padding: 16px 0px;
}
.prime_dropdown .form-row .p-dropdown.p-component input::-moz-placeholder {
  padding: 16px 0px;
}
.prime_dropdown .form-row .p-dropdown.p-component input:-ms-input-placeholder {
  padding: 16px 0px;
}
.prime_dropdown .form-row .p-dropdown.p-component input::-ms-input-placeholder {
  padding: 16px 0px;
}
.prime_dropdown .form-row .p-dropdown.p-component input::placeholder {
  padding: 16px 0px;
}
@media (max-width: 767px) {
  .prime_dropdown .form-row .p-dropdown.p-component input {
    font-size: 12px;
    height: 44px;
  }
}
.prime_dropdown .form-row .p-dropdown.p-component span .flex {
  height: 100%;
}
.prime_dropdown .form-row .p-dropdown.p-component span .flex div {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  font-family: "Proxima Nova Rg";
  font-size: 16px;
  font-style: normal;
  color: #15223B;
  line-height: 16px;
}
@media (max-width: 767px) {
  .prime_dropdown .form-row .p-dropdown.p-component span .flex div {
    font-size: 12px;
  }
}
.prime_dropdown .form-row .p-dropdown .p-dropdown-trigger {
  background-image: url("../../images/chevron-down_dark.svg");
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 20px 20px;
}
.prime_dropdown .form-row .p-dropdown .p-dropdown-trigger svg {
  display: none;
}

.booking_country .p-component {
  height: 48px !important;
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}
.booking_country .p-component .p-dropdown-label span {
  font-family: "Proxima Nova Rg";
  color: #15223B;
}
@media (max-width: 767px) {
  .booking_country .p-component {
    height: 44px !important;
    min-height: 44px !important;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .booking_country .p-component .p-dropdown-label span {
    font-size: 12px;
  }
}
@media (max-width: 767px) {
  .booking_country.new_label label {
    margin-bottom: 8.61px;
  }
}

.p-dropdown:not(.p-disabled).p-focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.p-dropdown-panel {
  z-index: 1058 !important;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-container input {
  border: 1px solid #e2e3e4;
  background-color: transparent;
  padding: 16px 40px 16px 16px;
  font-family: "Proxima Nova Rg";
  font-size: 16px;
  font-style: normal;
  line-height: 16px;
  color: #15223B;
  border-radius: 0px;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-container input:focus-visible {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
  border: 1px solid #e2e3e4;
}
@media (max-width: 767px) {
  .p-dropdown-panel .p-dropdown-header .p-dropdown-filter-container input {
    height: 44px;
    font-size: 12px;
  }
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
  top: 17px;
  right: 20px;
}
.p-dropdown-panel .p-dropdown-empty-message {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 50px 0px;
  color: #15223B;
}

.contact_select_dropdown.p-dropdown-panel {
  max-width: 128px;
}

@media (max-width: 767px) {
  .filter_country_select_dropdown.p-dropdown-panel {
    max-width: 280px !important;
    left: unset !important;
    right: 20px !important;
  }
}

.country_select_dropdown.p-dropdown-panel {
  max-width: 321px;
}

.phone_code.prime_dropdown .form-row {
  width: 60%;
}
.phone_code.prime_dropdown .form-row label {
  left: -65%;
}
@media (max-width: 767px) {
  .phone_code.prime_dropdown .form-row label {
    left: -66%;
  }
}
.phone_code.prime_dropdown .form-row input {
  border-right: 1px solid #e2e3e4;
}
.phone_code.prime_dropdown .form-row:first-child {
  width: 40%;
}
.phone_code.prime_dropdown .form-row:first-child label {
  display: none;
}
.phone_code.prime_dropdown .form-row .p-dropdown.p-component {
  height: 48px;
  border-right: 0px;
}
@media (max-width: 767px) {
  .phone_code.prime_dropdown .form-row .p-dropdown.p-component {
    height: 44px;
    padding: 14px 0px 14px 16px;
    min-height: 44px;
  }
}
.phone_code.prime_dropdown .form-row .p-dropdown.p-component .p-dropdown-label {
  font-family: "Proxima Nova Rg";
  font-size: 12px;
  font-style: normal;
  line-height: 16px;
  color: #15223B;
}
@media (max-width: 767px) {
  .phone_code.prime_dropdown .form-row .p-dropdown.p-component .p-dropdown-label {
    font-size: 12px;
  }
}

.request {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 28px;
  padding: 10px 14px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  border-radius: 40px;
  background: #a4f4ff;
  border: none;
  color: #178ccd;
  font-family: "Proxima Nova Bold";
  font-size: 12px;
  font-style: normal;
}
@media (max-width: 767px) {
  .request {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.in_progress {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 28px;
  padding: 10px 14px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  border-radius: 40px;
  background: #ccfec8;
  border: none;
  color: #45ab43;
  font-family: "Proxima Nova Bold";
  font-size: 12px;
  font-style: normal;
}

.link_text {
  text-decoration: none;
  font-family: "Proxima Nova Bold";
  font-size: 14px;
  font-style: normal;
  color: #fff;
  margin-right: 20px;
  text-transform: capitalize;
  background-color: #ED1D24;
  padding: 12px 14px;
  border: 1px solid #ED1D24;
}
.link_text:hover {
  color: #BE1E2D;
  background-color: transparent;
}
@media (max-width: 767px) {
  .link_text {
    margin-top: 16px;
    padding: 10.5px 12px;
  }
}

.p-multiselect-items-wrapper .p-multiselect-items {
  padding: 16px;
  margin-bottom: 0px;
}
.p-multiselect-items-wrapper .p-multiselect-items .p-multiselect-item {
  color: #15223B;
  background: transparent;
  font-family: "Proxima Nova Rg";
  font-size: 16px;
  font-style: normal;
  margin-bottom: 12px;
}
.p-multiselect-items-wrapper .p-multiselect-items .p-multiselect-item:focus {
  background: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.p-multiselect-items-wrapper .p-multiselect-items .p-multiselect-item:focus-visible {
  background: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.p-multiselect-items-wrapper .p-multiselect-items .p-multiselect-item:last-child {
  margin-bottom: 0px;
}
.p-multiselect-items-wrapper .p-checkbox {
  margin-right: 10px;
}
.p-multiselect-items-wrapper .p-checkbox:not(.p-checkbox-disabled) {
  background: transparent;
  border-color: transparent;
}
.p-multiselect-items-wrapper .p-checkbox .p-checkbox-box {
  border-color: #ED1D24;
  border-radius: 0px;
}
.p-multiselect-items-wrapper .p-checkbox .p-checkbox-box.p-highlight {
  background-color: #ED1D24;
  border-color: #ED1D24;
}
.p-multiselect-items-wrapper .p-multiselect-item.p-highlight {
  color: #15223B;
  background: transparent;
  font-family: "Proxima Nova Rg";
  font-size: 16px;
  font-style: normal;
}

.p-checkbox .p-checkbox-box {
  border-color: #ED1D24;
  border-radius: 0px;
}
.p-checkbox .p-checkbox-box.p-highlight {
  background-color: #ED1D24;
  border-color: #ED1D24;
}
.p-checkbox .p-checkbox-box.p-focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.p-checkbox .p-checkbox-box.p-highlight {
  background-color: #ED1D24;
  border-color: #ED1D24;
  border-radius: 0px;
}

.p-multiselect-panel {
  border-radius: 0px;
}
.p-multiselect-panel .p-multiselect-header {
  padding: 5px 5px 5px 16px;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
  background-color: transparent;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-label {
  color: #15223B;
  font-family: "Proxima Nova Rg";
  font-size: 16px;
  font-style: normal;
  margin-right: 5px;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token svg {
  margin-right: 5px;
}

.new_label label {
  font-family: "Proxima Nova Rg";
  font-size: 14px;
  font-style: normal;
  color: #5D5D70;
  text-transform: capitalize;
  margin-bottom: 8px;
  display: inline-block;
  line-height: normal;
}
@media (max-width: 767px) {
  .new_label label {
    font-size: 12px;
  }
}
.new_label .form-row label {
  display: none;
}

.p-multiselect-header .p-multiselect-select-all::after {
  content: "Select All";
  position: absolute;
  color: #15223B;
  font-family: "Proxima Nova Rg";
  font-size: 16px;
  font-style: normal;
  margin-left: 10px;
  margin-top: 2px;
}

.textarea_main {
  position: relative;
}
.textarea_main .letter_count {
  position: absolute;
  right: 10px;
  bottom: 28px;
  background-color: #fff;
  font-family: "Proxima Nova Rg";
  font-size: 14px;
  font-style: normal;
  color: #5D5D70;
}
@media (max-width: 767px) {
  .textarea_main .letter_count {
    font-size: 12px;
    bottom: 35px;
  }
}

.ag-chart-wrapper {
  -ms-touch-action: auto !important;
      touch-action: auto !important;
}

.p-menu-list {
  list-style: none;
  list-style-type: none;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}

.loader_view_listing {
  overflow: hidden;
}
.loader_view_listing .modal.show.graph_modal .modal-dialog .modal-content {
  overflow: hidden;
}

.show_loader {
  position: absolute;
  z-index: 999999;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  opacity: 0.9;
}
.show_loader img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}

.show_loader_listing {
  position: fixed;
  z-index: 999999;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  opacity: 0.9;
}
.show_loader_listing img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}

.enquiry_img {
  max-width: 100%;
  margin: 0 auto;
  display: block;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .enquiry_img {
    max-width: 90%;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .enquiry_img {
    max-width: 85%;
  }
}

.filter_modal_body .p-dropdown-label span {
  font-family: "Proxima Nova Rg";
  font-size: 16px;
  font-style: normal;
  line-height: 16px;
  color: #15223B;
}
.filter_modal_body .p-dropdown-label div div {
  font-family: "Proxima Nova Rg";
  font-size: 16px;
  font-style: normal;
  line-height: 16px;
  color: #15223B;
}

@media (max-width: 767px) {
  .p-dropdown-panel .p-dropdown-items-wrapper .p-dropdown-items .p-dropdown-item div div {
    max-width: 240px;
    text-wrap: wrap;
  }
}
.graph_modal .operator_graph .inner_details .pump_img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100%;
}
.graph_modal .operator_graph .inner_details .pump_img .pumpImg {
  max-width: 133px;
}

.toaster_popup {
  background-color: #d1fadf;
  border: 1px solid #e2e3e4;
  -webkit-box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.0901960784);
          box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.0901960784);
  padding: 16px;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 16px;
  width: 100%;
  max-width: 317px;
  position: absolute;
  right: -10px;
  top: 10px;
  z-index: 99;
}
@media (max-width: 767px) {
  .toaster_popup {
    top: 12px;
    right: -6px;
  }
}
.toaster_popup h5 {
  display: block;
  margin-bottom: 4px;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.92px;
  text-align: left;
  font-family: "Proxima Nova Bold";
  color: #15223B;
}
.toaster_popup p {
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #868594;
}

.modal.show.login_popup_modal {
  padding-right: 0px !important;
}
.modal.show.login_popup_modal .modal-dialog {
  max-width: 468px;
}
.modal.show.login_popup_modal .modal-dialog .modal-content .modal-header {
  border-bottom: none !important;
}
@media (max-width: 767px) {
  .modal.show.login_popup_modal .modal-dialog .modal-content .modal-header {
    padding: 24px 16px 24px 16px;
  }
}
.modal.show.login_popup_modal .modal-dialog .modal-content .modal-header h2 {
  font-family: "Proxima Nova Bold";
  font-size: 20px;
  font-weight: 700;
  line-height: 24.36px;
  text-align: left;
}
.modal.show.login_popup_modal .modal-dialog .modal-content .modal-body {
  padding-top: 0px;
  padding-bottom: 13px;
  padding-left: 32px;
  padding-right: 32px;
}
@media (max-width: 767px) {
  .modal.show.login_popup_modal .modal-dialog .modal-content .modal-body {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 32px;
  }
}
.modal.show.login_popup_modal .modal-dialog .modal-content .modal-body label {
  line-height: 17.05px;
}
.modal.show.login_popup_modal .modal-dialog .modal-content .modal-body .mb__0 .form-row {
  margin-bottom: 0px;
}
@media (max-width: 767px) {
  .modal.show.login_popup_modal .modal-dialog .modal-content .modal-footer {
    padding-bottom: 24px;
  }
}
.modal.show.login_popup_modal .modal-dialog .modal-content .modal-footer .darkred_btn {
  width: 100%;
}
@media (max-width: 767px) {
  .modal.show.login_popup_modal .modal-dialog .modal-content .modal-footer .darkred_btn {
    font-size: 18px;
    line-height: 16px;
    height: 48px;
  }
}
.modal.show.login_popup_modal .modal-dialog .modal-content .modal-footer .footer_signup {
  font-size: 14px;
  font-weight: 400;
  line-height: 17.05px;
  text-align: left;
  color: #5d5d70;
  padding-top: 32px;
  text-align: center;
  margin: 0 auto;
}
.modal.show.login_popup_modal .modal-dialog .modal-content .modal-footer .footer_signup span {
  color: #000000;
}
.modal.show.login_popup_modal .modal-dialog .modal-content .btn-close {
  display: none;
}

@media (max-width: 575px) {
  .booking_drawer .filter_box.filterOpen .filter_modal_content {
    -webkit-transform: translateX(50%);
            transform: translateX(50%);
    right: 50%;
    max-width: 343px;
    top: 55px;
  }
}
.booking_drawer .filter_modal {
  display: none;
}
.booking_drawer .filter_modal_content.booking_cart {
  max-width: 445px;
  height: auto;
  top: 59px;
  position: absolute;
  z-index: 99999;
}
@media (max-width: 767px) {
  .booking_drawer .filter_modal_content.booking_cart {
    top: 49px;
  }
}
.booking_drawer .filter_modal_content.booking_cart .filter_modal_head {
  padding: 32px 32px 24px 32px;
  border-bottom: none;
}
@media (max-width: 575px) {
  .booking_drawer .filter_modal_content.booking_cart .filter_modal_head {
    padding: 16px 16px 24px 16px;
  }
}
.booking_drawer .filter_modal_content.booking_cart .filter_modal_head h2 {
  line-height: 29.23px;
}
.booking_drawer .filter_modal_content.booking_cart .product_wrapper {
  overflow: auto;
  max-height: 496px;
}
.booking_drawer .filter_modal_content.booking_cart .booking_cart_product {
  padding-bottom: 24px;
  margin: 0px 32px;
  border-bottom: 1px solid #b9b8c1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 28px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-top: 24px;
}
.booking_drawer .filter_modal_content.booking_cart .booking_cart_product:first-child {
  padding-top: 0px;
}
@media (max-width: 575px) {
  .booking_drawer .filter_modal_content.booking_cart .booking_cart_product {
    gap: 16px;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin: 0px 16px;
  }
}
.booking_drawer .filter_modal_content.booking_cart .booking_cart_product .product_compare {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 16px;
}
.booking_drawer .filter_modal_content.booking_cart .booking_cart_product .product_compare img {
  width: 39px;
}
.booking_drawer .filter_modal_content.booking_cart .booking_cart_product .product_compare .pro_order {
  margin-bottom: 0px;
}
.booking_drawer .filter_modal_content.booking_cart .booking_cart_product .product_compare .pro_order .product_name {
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #868594;
  margin-right: 8px;
}
.booking_drawer .filter_modal_content.booking_cart .booking_cart_product .product_compare .pro_order .product_id {
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
  font-family: "Proxima Nova Bold";
  color: #15223B;
}
.booking_drawer .filter_modal_content.booking_cart .booking_cart_product .product_compare .gpm {
  margin-bottom: 0px;
}
.booking_drawer .filter_modal_content.booking_cart .booking_cart_product .product_compare .gpm span {
  font-size: 14px;
  font-weight: 400;
  line-height: 17.05px;
  text-align: left;
  color: #000000;
  margin-right: 20px;
}
.booking_drawer .filter_modal_content.booking_cart .booking_cart_product .qty_carts {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 8px;
}
@media (max-width: 575px) {
  .booking_drawer .filter_modal_content.booking_cart .booking_cart_product .qty_carts {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 82%;
    margin-left: auto;
  }
}
.booking_drawer .filter_modal_content.booking_cart .booking_cart_product .qty_carts .available_carts {
  border: 1px solid #E2E3E4;
  padding-left: 6px;
}
.booking_drawer .filter_modal_content.booking_cart .booking_cart_product .qty_carts .available_carts input {
  border: none;
  width: auto;
  min-width: auto;
  max-width: 33px;
  color: #000000;
  font-size: 14px;
}
.booking_drawer .filter_modal_content.booking_cart .booking_cart_product .qty_carts .available_carts input:focus-visible {
  border: none;
  outline: none;
}
.booking_drawer .filter_modal_content.booking_cart .booking_cart_product .qty_carts .available_carts .form-row {
  margin-bottom: 0px;
  height: 29px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.booking_drawer .filter_modal_content.booking_cart .booking_cart_product .qty_carts .available_carts select {
  padding: 2px 6px;
  height: 28px;
  margin-right: 8px;
  min-width: 65px;
  max-width: 65px;
  font-size: 14px;
  font-weight: 400;
  color: #15223B;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  text-transform: unset !important;
  background-image: url("../../images/darker_down.svg");
  background-size: 9px !important;
  background-position: right 0.37rem center;
  color: #000000;
}
.booking_drawer .filter_modal_content.booking_cart .booking_cart_product .qty_carts .red_cross {
  height: 29px;
}
.booking_drawer .filter_modal_content.booking_cart .filter_modal_body {
  padding: 24px 32px 32px 32px;
}
@media (max-width: 575px) {
  .booking_drawer .filter_modal_content.booking_cart .filter_modal_body {
    padding: 24px 16px 16px 16px;
  }
}
.booking_drawer .filter_modal_content.booking_cart .filter_modal_body .booking_fields label {
  line-height: 17.05px;
  display: block;
  width: 100%;
  text-align: left;
}
@media (max-width: 575px) {
  .booking_drawer .filter_modal_content.booking_cart .filter_modal_body .booking_fields .fields-group .form-row {
    margin-bottom: 20px;
  }
}
.booking_drawer .filter_modal_content.booking_cart .filter_modal_body button {
  margin-top: 32px;
  background-color: #ED1D24;
}
.booking_drawer .filter_modal_content.booking_cart .filter_modal_body button:hover {
  background-color: #fff;
  border: 1px solid #ED1D24;
  color: #ED1D24;
}
.booking_drawer .filter_modal_content.booking_cart .filter_modal_body button:hover svg path {
  stroke: #ED1D24;
}
.booking_drawer .filter_modal_content.booking_cart .filter_modal_body .cart_heading {
  padding-bottom: 24px;
  font-size: 20px;
  font-weight: 700;
  line-height: 24.36px;
  text-align: left;
  color: #15223B;
  font-family: "Proxima Nova Bold";
  margin-bottom: 0px;
}
.booking_drawer .filter_modal_content.booking_cart button.darkred_btn {
  margin: 32px;
  max-width: 380px;
}
@media (max-width: 575px) {
  .booking_drawer .filter_modal_content.booking_cart button.darkred_btn {
    margin: 16px;
    max-width: 311px;
  }
}

.myOrder_logout {
  position: absolute;
  right: 0px;
  top: 1px;
  padding: 24px;
  border: 1px solid #E2E3E4;
  width: 100%;
  max-width: 299px;
  min-width: 299px;
  -webkit-box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.0901960784);
          box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.0901960784);
  z-index: 99;
  background-color: #fff;
}
@media (max-width: 991px) {
  .myOrder_logout {
    min-width: 181px;
    padding: 14px;
  }
}
.myOrder_logout .order_link {
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #15223B;
  font-family: "Proxima Nova Bold";
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #B9B8C1;
  text-decoration: none;
  display: block;
}
.myOrder_logout .logout_btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 16px;
}
.myOrder_logout .logout_btn .log_out_link {
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #15223B;
  font-family: "Proxima Nova Bold";
  color: #ED1D24;
}

.product_compare.order {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 22px;
}
.product_compare.order img {
  width: 61px;
  height: auto;
}
.product_compare.order .pro_order {
  margin-bottom: 0px;
}
.product_compare.order .pro_order .product_name {
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #868594;
  margin-right: 8px;
}
.product_compare.order .pro_order .product_id {
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
  font-family: "Proxima Nova Bold";
  color: #15223B;
}
.product_compare.order .gpm {
  margin-bottom: 0px;
}
.product_compare.order .gpm span {
  font-size: 14px;
  font-weight: 400;
  line-height: 17.05px;
  text-align: left;
  color: #000000;
  margin-right: 20px;
}

.order_status_btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 44px;
  min-width: 124px;
  gap: 10px;
  border: none;
  padding: 0px 12px;
}
.order_status_btn .status_dot_show {
  width: 8px;
  height: 8px;
  border-radius: 10px;
}
.order_status_btn .current_status {
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  color: #15223B;
}

.booking_cart_open .filterOpen .overlay {
  position: fixed;
  z-index: 9999;
}
.booking_cart_open .filterOpen .filter_modal {
  display: block;
}

.logout_popup .dashboard_page_main {
  position: relative;
}
.logout_popup .dashboard_page_main::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.2117647059);
  width: 100%;
  height: 100vh;
  min-height: 100%;
  display: block;
  z-index: 9;
}
@media (max-width: 991px) {
  .logout_popup .dashboard_page_main::before {
    display: none;
  }
}

@media (max-width: 991px) {
  .i_pad_50 {
    max-width: 50% !important;
  }
}
@media (max-width: 991px) and (max-width: 767px) {
  .i_pad_50 {
    max-width: 100% !important;
  }
}
@media (max-width: 991px) {
  .none_991 {
    display: none;
  }
}
.p_3 {
  padding: 3px;
}

.custom_dataTable .p-datatable-wrapper table thead tr th:last-child .p-column-header-content {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.error_text {
  color: red;
}

.ml_10 {
  margin-right: 10px;
}

.form-select:focus {
  border-color: none !important;
  outline: 0;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.float_error {
  margin-top: -20px;
  position: absolute;
}

.mt_20 {
  margin-top: 20px;
}

.combine_fields .form-row .single_box {
  background-color: #fff;
  width: 112px;
  border-right: 1px solid #e2e3e4;
}

.modal.show .modal-dialog .modal-content .modal-body {
  padding: 32px;
}

.stock_table.custom_dataTable .p-datatable-wrapper table thead tr th:last-child .p-column-header-content {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.stock_table.custom_dataTable .p-datatable-wrapper table tbody tr td:first-child {
  padding-left: 20px;
}

.stock_label_value {
  font-size: 21px;
  font-weight: 400;
  text-align: left;
}

.inner_child_col {
  position: relative;
}
.inner_child_col .form-row {
  margin-bottom: 16px !important;
}
@media (min-width: 1100px) {
  .inner_child_col .new_field_added {
    position: absolute;
    width: 100%;
    left: 0px;
    padding: 0px 10px;
  }
}
@media (max-width: 1100px) {
  .inner_child_col .new_field_added {
    margin-bottom: 24px;
  }
}
.inner_child_col .new_field_added .form-label, .inner_child_col .new_field_added label {
  display: none;
}

.fileTypeField .pdf_file_uploaded .pdf_details_show .pdf_name_size .file_name {
  color: #15223b;
  font-family: "Proxima Nova Bold";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  word-break: break-all;
  width: 362px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text_dec {
  text-decoration: none;
}

.booking_cart_open.modal-open .fade.modal-backdrop {
  z-index: 99999;
}
.booking_cart_open.modal-open .confirm_msg {
  text-align: center;
}
.booking_cart_open.modal-open .modal-footer {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.cart_icon {
  position: relative;
  cursor: pointer;
}
.cart_icon .booking_count {
  position: absolute;
  width: 18px;
  height: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #F57E20;
  font-size: 12px;
  font-weight: 400;
  line-height: 13.52px;
  text-align: left;
  top: -1px;
  right: -9px;
  border-radius: 100px;
}

.available_carts .cart_icon .booking_count {
  top: -9px;
}

.new_update_pumps .fire-select-heading {
  font-size: 48px;
}
@media (max-width: 991px) {
  .new_update_pumps .fire-select-heading {
    font-size: 28px;
  }
}

.flow_select_link {
  position: absolute;
  top: 128px;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
}
@media (max-width: 991px) {
  .flow_select_link {
    top: 50px;
    font-size: 14px;
  }
}

.under_review_block {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 90vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media (max-width: 767px) {
  .under_review_block {
    height: 60vh;
  }
}
.under_review_block img {
  max-width: 150px;
  margin-bottom: 40px;
}
@media (max-width: 767px) {
  .under_review_block img {
    max-width: 100px;
  }
}
.under_review_block h2 {
  font-weight: 700;
}
@media (max-width: 767px) {
  .under_review_block h2 {
    font-size: 18px;
  }
}

.order_download_btn {
  padding: 7px 10px;
  height: 38px;
  width: 120px;
  font-family: "Proxima Nova Bold";
  font-size: 12px;
  font-style: normal;
  gap: 10px;
}
.order_download_btn svg {
  margin-left: 0px;
  margin-right: 0px;
  min-width: 16px;
  height: 16px;
}
@media (max-width: 767px) {
  .order_download_btn {
    margin-top: 20px;
  }
}

.no_rec_found {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-top: 20px;
}
.no_rec_found h5 {
  font-weight: 600;
  margin-top: 10px;
}

.loader_listing {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-top: 20px;
}
.loader_listing img {
  mix-blend-mode: exclusion;
}

.search_orders_listing::-webkit-scrollbar-track {
  border-radius: 4px;
}

.search_orders_listing::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.search_orders_listing::-webkit-scrollbar-thumb {
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 6px #808285;
  background-color: #808285;
}

.order_tacking_head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 767px) {
  .order_tacking_head {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}

.new_download_btn {
  padding: 7px 10px;
  height: 30px;
  width: 97px;
  font-family: "Proxima Nova Bold";
  font-size: 12px;
  font-style: normal;
  gap: 5px;
}
.new_download_btn svg {
  margin-left: 0px;
  margin-right: 0px;
  min-width: 16px;
  height: 16px;
}

.progress_bar .download_btn {
  margin-left: auto;
}
@media (min-width: 768px) {
  .progress_bar .download_btn {
    margin-top: auto;
    margin-left: 0;
  }
}

.progress_bar .download_btn svg {
  margin-left: 0px;
}

.login_block {
  height: 100vh;
}
.login_block .bg_login {
  width: 54.38%;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  overflow: hidden;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (max-width: 991px) {
  .login_block .bg_login {
    width: 100%;
    padding-bottom: 190px;
  }
}
@media (max-width: 767px) {
  .login_block .bg_login {
    padding-bottom: 140px;
  }
}
.login_block .bg_login .login_banner {
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  width: auto;
}
@media (max-width: 991px) {
  .login_block .bg_login .login_banner {
    max-height: 360px;
    margin-top: 19px;
  }
}
@media (max-width: 767px) {
  .login_block .bg_login .login_banner {
    max-height: 240px;
  }
}
@media (max-width: 767px) {
  .login_block .bg_login .login_banner {
    max-height: 196px;
  }
}
.login_block .bg_login .login_banner.desktop_login_bg {
  display: block;
}
@media (max-width: 767px) {
  .login_block .bg_login .login_banner.desktop_login_bg {
    display: none;
  }
}
.login_block .bg_login .login_banner.mobile_login_bg {
  display: none;
}
@media (max-width: 767px) {
  .login_block .bg_login .login_banner.mobile_login_bg {
    display: block;
  }
}
.login_block .bg_login .login_link {
  display: block;
  height: 100%;
}
@media (min-width: 1201px) and (max-width: 1440px) {
  .login_block .bg_login .login_link {
    height: 80%;
  }
}
@media (min-width: 991px) and (max-width: 1200px) {
  .login_block .bg_login .login_link {
    height: 60%;
  }
}
.login_block .bg_login.h100vh {
  height: 100vh;
  min-height: 741px;
}
@media (max-width: 991px) {
  .login_block .bg_login.h100vh {
    height: 100%;
    min-height: auto;
  }
}
.login_block .bg_login.h100vh_min {
  height: 100vh;
  min-height: 902px;
}
@media (max-width: 991px) {
  .login_block .bg_login.h100vh_min {
    height: 100%;
    min-height: auto;
  }
}
@media (min-width: 2134px) {
  .login_block .bg_login {
    min-height: 100vh;
  }
}
.login_block .login_form_col {
  width: 45.62%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
}
@media (max-width: 991px) {
  .login_block .login_form_col {
    width: 100%;
    margin-bottom: 40px;
  }
}
.login_block .login_form_col .form-inner-col {
  padding-top: 40px;
  padding-bottom: 40px;
  width: 100%;
  background-color: #fff;
}
@media (max-width: 991px) {
  .login_block .login_form_col .form-inner-col {
    padding-top: 40px;
    padding-bottom: 60px;
    margin-left: 16px;
    margin-right: 16px;
    margin-top: -200px;
  }
}
.login_block .login_form_col .login_logo {
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 29px;
}
@media (max-width: 767px) {
  .login_block .login_form_col .login_logo {
    margin-bottom: 38px;
  }
  .login_block .login_form_col .login_logo img {
    max-width: 99px;
  }
}
.login_block .login_form_col .form {
  max-width: 457px;
  width: 100%;
  margin: 0 auto;
  border-top: 0px;
  padding: 0px;
  -webkit-box-shadow: none;
          box-shadow: none;
}
@media (max-width: 1200px) {
  .login_block .login_form_col .form {
    padding: 0px 20px;
  }
}
@media (max-width: 991px) {
  .login_block .login_form_col .form {
    max-width: 100%;
    padding: 0px 16px;
  }
}
.login_block .login_form_col .form h1 {
  font-family: "Proxima Nova Bold";
  font-size: 30px;
  font-style: normal;
  color: #000;
  margin-bottom: 8px;
  text-align: center;
  font-weight: 700;
}
@media (max-width: 767px) {
  .login_block .login_form_col .form h1 {
    font-size: 16px;
    margin-bottom: 12px;
  }
}
.login_block .login_form_col .form .sub_name_para {
  font-family: "Proxima Nova Rg";
  font-size: 18px;
  font-style: normal;
  color: #808285;
  line-height: 28px;
  letter-spacing: -0.18px;
  margin-bottom: 32px;
  margin-top: 0px;
  text-align: center;
}
@media (max-width: 767px) {
  .login_block .login_form_col .form .sub_name_para {
    font-size: 12px;
    margin-bottom: 34px;
  }
}
.login_block .password_field_box {
  position: relative;
}
.login_block .password_field_box .eye_close_box {
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 40px;
}
.login_block .password_field_box .eye_close_box .closed_eye_img {
  width: 17px;
}
.login_block .remember_check {
  margin-top: 0px;
}
.login_block .remember_check .checkbox_block label::after {
  background-image: none;
  border: 1px solid #B9B8C1;
  border-radius: 3px;
  height: 16px;
  width: 16px;
}
.login_block .remember_check .checkbox_block input[type=checkbox] {
  width: 16px;
}
.login_block .remember_check .checkbox_block input[type=checkbox]:checked + label:after {
  background-size: 16px 16px;
  border: 0px;
}
.login_block .remember_check .checkbox_block label {
  height: 16px;
  width: 16px;
}
.login_block .remember_check .remember_text {
  font-family: "Proxima Nova Rg";
  font-size: 14px;
  font-style: normal;
  color: #868594;
  line-height: normal;
  pointer-events: none;
}
@media (max-width: 767px) {
  .login_block .remember_check .remember_text {
    font-family: "Proxima Nova Rg";
    font-size: 12px;
    font-style: normal;
  }
}
.login_block .remember_check .forgot_link a {
  font-family: "Proxima Nova Bold";
  font-size: 14px;
  font-style: normal;
  color: #ED1D24;
  text-decoration: none;
  font-weight: 700;
}
@media (max-width: 767px) {
  .login_block .remember_check .forgot_link a {
    font-size: 12px;
  }
}
.login_block .login_btn {
  width: 100%;
  margin-top: 40px;
  margin-bottom: 20px;
}
.login_block .login_btn.btn-mt-32 {
  margin-top: 32px;
}
@media (max-width: 767px) {
  .login_block .login_btn {
    margin-top: 24px;
    margin-bottom: 16px;
  }
}
.login_block .switch_login {
  font-family: "Proxima Nova Rg";
  font-size: 16px;
  font-style: normal;
  line-height: 16px;
  color: #15223B;
  margin-top: 0px;
  text-align: center;
  font-weight: 400;
  margin-bottom: 0px;
}
@media (max-width: 767px) {
  .login_block .switch_login {
    font-size: 12px;
  }
}
.login_block .switch_login .member-btn {
  font-family: "Proxima Nova Bold";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #ED1D24;
  border: none;
  background: transparent;
}
@media (max-width: 767px) {
  .login_block .switch_login .member-btn {
    font-size: 12px;
  }
}
.login_block .api-error {
  color: #ED1D24;
  font-family: "Proxima Nova Rg";
  font-size: 14px;
  font-style: normal;
  line-height: 100%;
}
.login_block .fields-group {
  position: relative;
}
.login_block .fields-group .p-error {
  position: absolute;
  bottom: -22px;
}
@media (max-width: 767px) {
  .login_block .fields-group .p-error {
    font-size: 12px;
  }
}
.login_block #popover-password .progress {
  height: 8px;
  background-color: #E2E3E4;
  border-radius: 20px;
}
.login_block #popover-password .progress .progress-bar {
  border-radius: 20px;
  background-color: #F57E20;
}
.login_block #popover-password ul {
  margin-bottom: 0px;
}
.login_block #popover-password ul li {
  margin-bottom: 4px;
}
.login_block #popover-password ul li .password_details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 12px;
}
@media (max-width: 767px) {
  .login_block #popover-password ul li .password_details {
    gap: 4px;
  }
}
.login_block #popover-password ul li .password_details span {
  font-family: "Proxima Nova Rg";
  font-size: 14px;
  font-style: normal;
  color: #808285;
  line-height: 24px;
}
@media (max-width: 767px) {
  .login_block #popover-password ul li .password_details span {
    font-size: 12px;
    line-height: normal;
  }
}
.login_block #popover-password ul li .status_svg {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.login_block #popover-password ul li .status_svg .green_right_svg {
  display: none;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.login_block #popover-password ul li .status_svg .grey_dot_svg {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.verification-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.verification-container .simple-text {
  font-family: "Proxima Nova Rg";
  font-size: 16px;
  font-style: normal;
  color: #5D5D70;
  text-align: center;
}
@media (max-width: 991px) {
  .verification-container .simple-text {
    padding: 0 15px;
  }
}
.verification-container .verify-head {
  font-family: "Proxima Nova Bold";
  font-size: 30px;
  font-style: normal;
  color: #15223B;
}

.home_page {
  padding: 24px 0px 28px 0px;
  background-image: url("../../images/home_background.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100%;
}
@media (max-width: 767px) {
  .home_page {
    padding: 24px 0px 40px 0px;
  }
}
.home_page .home_left_col {
  padding-right: 10px !important;
  padding-top: 120px;
}
.home_page .home_left_col .home_hero_img {
  width: 100%;
}
@media (max-width: 991px) {
  .home_page .home_left_col {
    padding-top: 0px;
    margin-bottom: 24px;
    padding-right: 0px !important;
    width: 100%;
  }
}
@media (max-width: 991px) {
  .home_page .home_right_col {
    width: 100%;
  }
}
.home_page .home_right_col .home_right_content {
  padding: 32px;
  background-color: #f6f7f7;
  margin-left: 10px;
}
@media (max-width: 991px) {
  .home_page .home_right_col .home_right_content {
    margin-left: 0px;
    padding: 24px 0px 20px 0px;
  }
}
.home_page .home_right_col .home_right_content .home_heading_main {
  font-family: "Proxima Nova Bold";
  font-size: 30px;
  font-style: normal;
  line-height: 30px;
  font-weight: 700;
  margin-bottom: 12px;
  color: #15223B;
  margin-bottom: 12px;
}
@media (max-width: 991px) {
  .home_page .home_right_col .home_right_content .home_heading_main {
    padding: 0px 70px 0px 16px;
  }
}
@media (max-width: 767px) {
  .home_page .home_right_col .home_right_content .home_heading_main {
    font-size: 24px;
    margin-bottom: 20px;
  }
}
@media (max-width: 991px) {
  .home_page .home_right_col .home_right_content .hero_para_top {
    padding: 0px 16px;
  }
}
.home_page .home_right_col .home_right_content .hero_para_top p {
  max-width: 719px;
  margin-bottom: 32px;
}
@media (max-width: 767px) {
  .home_page .home_right_col .home_right_content .hero_para_top p {
    margin-bottom: 24px;
  }
  .home_page .home_right_col .home_right_content .hero_para_top p:first-child {
    padding-right: 10px;
  }
  .home_page .home_right_col .home_right_content .hero_para_top p:last-child {
    margin-bottom: 32px;
  }
}
.home_page .home_right_col .home_right_content p {
  font-family: "Proxima Nova Rg";
  font-size: 18px;
  font-style: normal;
  line-height: 28px;
  letter-spacing: -0.18px;
  color: #5D5D70;
  font-weight: 400;
  margin-bottom: 0px;
}
@media (max-width: 767px) {
  .home_page .home_right_col .home_right_content p {
    font-size: 14px;
    letter-spacing: -0.14px;
  }
}
.home_page .home_right_col .home_right_content .middle_hero_content .darkred_btn {
  width: 192px;
}
@media (max-width: 767px) {
  .home_page .home_right_col .home_right_content .middle_hero_content .darkred_btn {
    width: 174px;
  }
}
@media (max-width: 991px) {
  .home_page .home_right_col .home_right_content .middle_hero_content {
    padding: 0px 16px;
  }
}
.home_page .home_right_col .home_right_content .middle_hero_content h2 {
  font-family: "Proxima Nova Bold";
  font-size: 24px;
  font-style: normal;
  color: #15223B;
  line-height: normal;
  font-weight: 700;
  margin-bottom: 9px;
}
@media (max-width: 767px) {
  .home_page .home_right_col .home_right_content .middle_hero_content h2 {
    font-size: 16px;
    margin-bottom: 20px;
  }
}
.home_page .home_right_col .home_right_content .middle_hero_content p {
  max-width: 630px;
}
.home_page .home_right_col .home_right_content .middle_hero_content ol {
  padding-left: 20px;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .home_page .home_right_col .home_right_content .middle_hero_content ol {
    margin-bottom: 32px;
  }
}
.home_page .home_right_col .home_right_content .middle_hero_content ol li::marker {
  font-family: "Proxima Nova Rg";
  font-size: 18px;
  font-style: normal;
  line-height: 28px;
  letter-spacing: -0.18px;
  color: #5D5D70;
  font-weight: 400;
  margin-bottom: 0px;
}
@media (max-width: 767px) {
  .home_page .home_right_col .home_right_content .middle_hero_content ol li::marker {
    font-size: 14px;
    letter-spacing: -0.14px;
  }
}
.home_page .accordion_home {
  margin-top: 32px;
}
.home_page .accordion_home .accordion {
  background-color: transparent;
  border: none;
  --bs-border-color: transparent;
}
.home_page .accordion_home .accordion .accordion-item {
  background-color: transparent;
}
.home_page .accordion_home .accordion .accordion-item .accordion-button {
  font-family: "Proxima Nova Bold";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #15223B;
  padding: 20px 11px;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-radius: 0px;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  border-bottom: 1px solid #808285;
  background-color: transparent;
}
.home_page .accordion_home .accordion .accordion-item .accordion-button:focus-visible {
  -webkit-box-shadow: none;
          box-shadow: none;
}
@media (max-width: 767px) {
  .home_page .accordion_home .accordion .accordion-item .accordion-button {
    font-size: 12px;
    padding: 16px 11px;
  }
}
.home_page .accordion_home .accordion .accordion-item .accordion-button::after {
  background-image: url("../../images/minus-square.svg");
  -webkit-transform: rotate(0);
          transform: rotate(0);
}
@media (max-width: 767px) {
  .home_page .accordion_home .accordion .accordion-item .accordion-button::after {
    background-size: 16px 16px;
    width: 16px;
    height: 16px;
  }
}
.home_page .accordion_home .accordion .accordion-item .accordion-button.collapsed::after {
  background-image: url("../../images/add-square.svg");
  -webkit-transform: rotate(0);
          transform: rotate(0);
}
.home_page .accordion_home .accordion .accordion-item .accordion-button:not(.collapsed) {
  border-bottom: 0px;
  padding-bottom: 0px;
}
.home_page .accordion_home .accordion .accordion-item .accordion-body {
  border: none;
  border-bottom: 1px solid #808285;
  padding: 16px 11px;
  font-family: "Proxima Nova Rg";
  font-size: 18px;
  font-style: normal;
  line-height: 28px;
  letter-spacing: -0.18px;
  color: #5D5D70;
  font-weight: 400;
  margin-bottom: 0px;
}
@media (max-width: 767px) {
  .home_page .accordion_home .accordion .accordion-item .accordion-body {
    font-size: 12px;
    letter-spacing: -0.14px;
  }
}

header {
  background-color: #BE1E2D;
  position: relative;
}
header .nav {
  padding: 0px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 58px;
}
@media (max-width: 767px) {
  header .nav {
    height: 48px;
  }
}
header .nav .navbar_links_listing {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 12px;
  padding-left: 0px;
  margin: 0px;
  list-style: none;
  height: 100%;
}
@media (max-width: 991px) {
  header .nav .navbar_links_listing {
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  header .nav .navbar_links_listing {
    gap: 8px;
  }
}
header .nav .navbar_links_listing .navbar_links_items {
  height: 100%;
}
header .nav .navbar_links_listing .navbar_links_items .navbar_links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-decoration: none;
  font-family: "Proxima Nova Rg";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.28px;
  color: #fff;
  padding: 0px 16px;
  height: 100%;
}
@media (min-width: 992px) and (max-width: 1300px) {
  header .nav .navbar_links_listing .navbar_links_items .navbar_links {
    padding: 0px 8px;
  }
}
@media (min-width: 992px) and (max-width: 1100px) {
  header .nav .navbar_links_listing .navbar_links_items .navbar_links {
    font-size: 12px;
  }
}
header .nav .navbar_links_listing .navbar_links_items .navbar_links:hover {
  background-color: #BC141A;
}
header .nav .navbar_links_listing .navbar_links_items .navbar_links.active_link {
  background-color: #a21725;
}
header .nav .navbar_links_listing .navbar_links_items .navbar_links svg {
  margin-right: 10px;
}
@media (min-width: 992px) and (max-width: 1300px) {
  header .nav .navbar_links_listing .navbar_links_items .navbar_links svg {
    margin-right: 6px;
  }
}
header .nav .new_drop {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 32px;
}
@media (max-width: 991px) {
  header .nav .new_drop {
    gap: 20px;
  }
}
header .nav .btn-container {
  position: relative;
}
@media (max-width: 991px) {
  header .nav .btn-container {
    display: none;
  }
}
header .nav .btn-container .dropdown_login_btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: #15223B;
  width: 100%;
  text-align: start;
  max-width: 140px;
  padding: 8px 16px;
  font-weight: 700 !important;
  z-index: 999;
  margin-right: 0px;
}
header .nav .btn-container .dropdown_login_btn::before {
  content: url("../../images/Polygon_one.svg");
  position: absolute;
  top: -16px;
  right: 9px;
}
@media (max-width: 991px) {
  header .nav .btn-container .dropdown_login_btn::before {
    right: 4px;
  }
}
header .nav .btn-container .dropdown_login_btn svg {
  width: 16px;
  margin-right: 10px;
}
@media (min-width: 992px) and (max-width: 1100px) {
  header .nav .btn-container .dropdown_login_btn {
    gap: 6px;
  }
}
header .nav .btn-container .main_login {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
header .nav .btn-container .main_login svg {
  margin-right: 10px;
}
@media (min-width: 992px) and (max-width: 1100px) {
  header .nav .btn-container .main_login svg {
    width: 20px;
    height: 20px;
  }
}
header .nav .btn-container .login_btn {
  background-color: transparent;
  border: none;
  color: #fff;
  font-family: "Proxima Nova Rg";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.28px;
}
@media (min-width: 992px) and (max-width: 1100px) {
  header .nav .btn-container .login_btn {
    font-size: 12px;
  }
}
header .hamburger_box {
  display: none;
}
@media (max-width: 991px) {
  header .hamburger_box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  header .hamburger_box .cart_icon {
    padding: 0px;
    border: none;
    background-color: transparent;
    margin-right: 20px;
  }
}
header .hamburger_box.open .mobile_menu {
  opacity: 1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  width: 300px;
  visibility: visible;
  overflow-x: auto;
}
header .hamburger_box.open .closed_btn {
  background: transparent;
  border: none;
  padding: 0px;
  width: auto;
  position: absolute;
  top: 20px;
  right: 312px;
  z-index: 999;
  visibility: visible;
}
@media (max-width: 991px) {
  header .mobile_menu {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 999;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    width: 0px;
    visibility: hidden;
    overflow-x: hidden;
  }
}
header .mobile_menu .trans_mob {
  background-color: #BE1E2D;
  height: 100vh;
  padding: 24px 18px;
  margin-left: auto;
}
header .mobile_menu .trans_mob .navbar_links_listing {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  height: auto;
  gap: 16px;
  margin-bottom: 16px;
}
header .mobile_menu .trans_mob .navbar_links_listing .navbar_links_items {
  height: auto;
  width: 100%;
}
header .mobile_menu .trans_mob .navbar_links_listing .navbar_links_items .navbar_links {
  padding: 8px 16px;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
header .mobile_menu .trans_mob .btn-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 8px 16px;
}
header .hamburger {
  display: none;
}
@media (max-width: 991px) {
  header .hamburger {
    background-color: transparent;
    border: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
header .user_profile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0px;
  margin: 0px;
}
header .user_profile:focus-visible {
  border: 1px solid transparent;
  background-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
}
header .user_profile:active {
  border: 1px solid transparent;
  background-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-color: transparent !important;
}
@media (max-width: 991px) {
  header .user_profile {
    padding: 8px 16px;
  }
}
header .user_profile .user_profile_img {
  max-width: 27px;
  height: auto;
  border-radius: 100%;
  width: 100%;
}
header .user_profile .user_pro_name {
  white-space: nowrap;
  color: #fff;
  margin-right: 6px;
  font-family: "Proxima Nova Rg";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.28px;
}
@media (min-width: 992px) and (max-width: 1100px) {
  header .user_profile .user_pro_name {
    font-size: 12px;
  }
}
@media (max-width: 991px) {
  header .user_profile .user_pro_name {
    margin-right: 10px;
  }
}
header .user_profile .arrow_drop .arrow_drop_img {
  margin-top: -4px;
}
header .user_profile .user_profile_text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-right: 6px;
}
@media (max-width: 991px) {
  header .user_profile .user_profile_text {
    margin-right: 10px;
  }
}
header .user_profile .user_profile_text .user_name_show {
  background-color: rgb(177, 225, 177);
  border: none;
  border-radius: 100%;
  font-family: "Proxima Nova Bold";
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  padding: 16px 7px;
  width: auto;
  height: auto;
  min-width: 49px;
  color: #15223B;
}

.menu_open_overlay {
  overflow: hidden;
  position: relative;
}
.menu_open_overlay::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5019607843);
  z-index: 999;
  -webkit-transition: 0.5s linear;
  transition: 0.5s linear;
}

.closed_btn {
  background: transparent;
  border: none;
  padding: 0px;
  width: auto;
  position: absolute;
  top: 20px;
  right: 0px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  visibility: hidden;
}

.new_drop .btn-check:checked + .btn, .new_drop .btn.active, .new_drop .btn.show, .new_drop .btn:first-child:active, .new_drop :not(.btn-check) + .btn:active, .new_drop button {
  background-color: transparent;
  border: none;
  padding: 0px;
  margin: 0px;
}
.new_drop .btn-check:checked + .btn:hover, .new_drop .btn.active:hover, .new_drop .btn.show:hover, .new_drop .btn:first-child:active:hover, .new_drop :not(.btn-check) + .btn:active:hover, .new_drop button:hover {
  background-color: transparent;
  border: 0px;
}
.new_drop .dropdown-toggle::after {
  display: none;
}
.new_drop .dropdown-menu {
  padding: 0px;
  background-color: transparent;
  border-radius: 0px;
  min-width: 139px;
  right: 0px !important;
  border: none;
}
.new_drop .dropdown-menu .dropdown-item {
  padding: 0px;
}
@media (max-width: 991px) {
  .new_drop .dropdown-menu {
    min-width: 141px;
  }
}

.new_drop .dropdown-menu .dropdown-item {
  width: 139px;
  margin-left: auto;
}

.select_pump_main {
  padding-top: 32px;
  min-height: 100vh;
}
@media (max-width: 991px) {
  .select_pump_main {
    padding-top: 24px;
    padding-bottom: 13px;
  }
}
.select_pump_main .new-fire-pump {
  position: absolute;
  top: 100px;
  left: 90px;
}
@media (max-width: 1299px) {
  .select_pump_main .new-fire-pump {
    left: 7.5%;
  }
}
@media (max-width: 991px) {
  .select_pump_main .new-fire-pump {
    left: 0px;
    top: 0px;
  }
}
.select_pump_main .new-fire-pump.select-pump-type-heading {
  top: 150px;
}
@media (max-width: 991px) {
  .select_pump_main .new-fire-pump.select-pump-type-heading {
    top: 35px;
    font-size: 18px;
  }
}
.select_pump_main h1 {
  font-family: "Proxima Nova Bold";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  color: #15223B;
  margin-bottom: 24px;
}
@media (max-width: 991px) {
  .select_pump_main h1 {
    margin-bottom: 16px;
  }
}
@media (max-width: 767px) {
  .select_pump_main h1 {
    font-size: 24px;
  }
}
.select_pump_main .new_update_pumps {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-bottom: 100px;
  position: relative;
}
@media (max-width: 991px) {
  .select_pump_main .new_update_pumps {
    padding-top: 90px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-bottom: 30px;
  }
}
@media (max-width: 991px) {
  .select_pump_main .new_update_pumps .new-pump {
    margin-bottom: 30px;
  }
}
@media (max-width: 991px) {
  .select_pump_main .new_update_pumps .new-pump:nth-child(2) {
    margin-bottom: 70px;
  }
}
@media (max-width: 991px) {
  .select_pump_main .new_update_pumps .new-pump:last-child {
    margin-bottom: 0px;
  }
}
.select_pump_main .new_update_pumps .new-pump:last-child .pump_img img {
  max-height: 955px;
}
@media (max-width: 991px) {
  .select_pump_main .new_update_pumps .new-pump:last-child .pump_img img {
    max-height: 750px;
  }
}
.select_pump_main .pump_img {
  text-decoration: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #fff;
  min-height: 283px;
  position: relative;
  z-index: 1;
}
@media (max-width: 767px) {
  .select_pump_main .pump_img {
    min-height: auto;
    padding-top: 20px;
    padding-bottom: 18px;
    gap: 12px;
  }
}
.select_pump_main .pump_img .link_pump {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
}
.select_pump_main .pump_img .pump_name {
  font-family: "Proxima Nova Bold";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #15223B;
  text-decoration: none;
  padding: 10px;
  min-width: 220px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (max-width: 991px) {
  .select_pump_main .pump_img .pump_name {
    font-size: 18px;
    min-width: 150px;
  }
}
.select_pump_main .pump_img .pump_name::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #C9CACC;
  transition: -webkit-clip-path 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  -webkit-transition: -webkit-clip-path 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition: clip-path 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition: clip-path 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955), -webkit-clip-path 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.select_pump_main .pump_img .pump_name:hover {
  color: #fff;
}
.select_pump_main .pump_img .pump_name:hover::before {
  -webkit-clip-path: polygon(0 0, calc(100% + 40px) 0, 100% 100%, 0 100%);
  clip-path: polygon(0 0, calc(100% + 40px) 0, 100% 100%, 0 100%);
  background-color: #BE1E2D;
}
@media (max-width: 767px) {
  .select_pump_main .pump_img svg {
    width: 20px;
    height: 20px;
  }
}
.select_pump_main .pump_img img {
  width: 100%;
  margin-bottom: 0px;
  max-height: 618px;
}
@media (max-width: 991px) {
  .select_pump_main .pump_img img {
    margin-bottom: 15px;
    max-height: 550px;
  }
}

.selected_pump_details {
  padding-top: 32px;
}
@media (max-width: 767px) {
  .selected_pump_details {
    padding-top: 24px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .selected_pump_details .selected_top_details .row .col-md-4 {
    width: 50% !important;
  }
}
.selected_pump_details .selected_top_details h1 {
  font-family: "Proxima Nova Bold";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  color: #15223B;
  margin-bottom: 24px;
}
@media (max-width: 767px) {
  .selected_pump_details .selected_top_details h1 {
    font-size: 24px;
    margin-bottom: 16px;
  }
}
@media (max-width: 767px) {
  .selected_pump_details .selected_below_details_main {
    padding-bottom: 20px;
  }
}
.selected_pump_details .selected_below_details {
  padding: 20px 20px;
  border: 1px solid #efeff0;
  background: #f6f7f7;
  margin-bottom: 24px;
}
@media (max-width: 767px) {
  .selected_pump_details .selected_below_details {
    padding: 24px 16px 0px 16px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .selected_pump_details .selected_below_details .row .col-md-4 {
    width: 50% !important;
  }
}
.selected_pump_details .selected_below_details .criteria_block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 24px;
}
.selected_pump_details .selected_below_details .criteria_block .selected_heading {
  font-family: "Proxima Nova Bold";
  font-size: 18px;
  font-style: normal;
  color: #15223B;
  line-height: 18px;
  font-weight: 700;
  margin-bottom: 0px;
}
@media (max-width: 767px) {
  .selected_pump_details .selected_below_details .criteria_block .selected_heading {
    font-size: 16px;
  }
}
.selected_pump_details .form-row {
  margin-bottom: 0px;
}
.selected_pump_details .form-row input {
  background-color: #fff;
}
.selected_pump_details .form-row select {
  background-color: #fff;
}
.selected_pump_details .extra_pumpset {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  margin-bottom: 24px;
}
@media (max-width: 767px) {
  .selected_pump_details .extra_pumpset {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-bottom: 20px;
  }
}
.selected_pump_details .extra_pumpset .add_extra {
  text-decoration: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 8px;
  font-family: "Proxima Nova Bold";
  font-size: 16px;
  font-style: normal;
  color: #ED1D24;
  line-height: 16px;
  font-weight: 700;
}

.modal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.modal.show .modal-dialog {
  max-width: 632px;
  width: 100%;
  padding: 0px 16px;
}
@media (max-width: 767px) {
  .modal.show .modal-dialog {
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
.modal.show .modal-dialog .modal-content {
  margin: 0;
  border-radius: 0px;
  border: none;
}
.modal.show .modal-dialog .modal-content .modal-header {
  padding: 32px 32px 24px 32px;
  border-bottom: 1px solid #b9b8c1;
}
@media (max-width: 767px) {
  .modal.show .modal-dialog .modal-content .modal-header {
    padding: 20px 20px 20px 16px;
  }
}
.modal.show .modal-dialog .modal-content .modal-header h2 {
  margin-bottom: 0px;
  font-family: "Proxima Nova Bold";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #15223B;
}
.modal.show .modal-dialog .modal-content .modal-header .btn-close {
  background-image: url("../../images/Close.svg");
  padding: 0px;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  opacity: 1;
}
.modal.show .modal-dialog .modal-content .edit_customer_form .modal-body {
  padding: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 767px) {
  .modal.show .modal-dialog .modal-content .edit_customer_form .modal-body {
    padding: 24px 16px 4px 16px;
  }
}
.modal.show .modal-dialog .modal-content .edit_customer_form .modal-body .field_wrapper {
  width: 48%;
}
@media (max-width: 767px) {
  .modal.show .modal-dialog .modal-content .edit_customer_form .modal-body .field_wrapper {
    width: 100%;
  }
}
.modal.show .modal-dialog .modal-content .edit_customer_form .modal-body .field_wrapper .form-row #companyId {
  border: 1px solid #e2e3e4;
  padding: 16px;
  height: 48px;
  min-width: 325px;
}
@media (max-width: 767px) {
  .modal.show .modal-dialog .modal-content .edit_customer_form .modal-body .field_wrapper .form-row #companyId {
    min-width: auto;
  }
}
.modal.show .modal-dialog .modal-content .edit_customer_form .modal-body .field_wrapper .form-row .form_radio_wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.modal.show .modal-dialog .modal-content .edit_customer_form .modal-body .field_wrapper .form-row .form_radio_wrapper .form_radio_block {
  margin-top: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 48%;
}
.modal.show .modal-dialog .modal-content .edit_customer_form .modal-body .field_wrapper .form-row .form_radio_wrapper .form_radio_block .form-radio {
  height: 20px;
  width: 20px;
  border-color: #afb1b4;
  margin-right: 8px;
  accent-color: #ED1D24;
  border-radius: 100px;
  padding: 0px;
  background-color: rgba(0, 0, 0, 0);
}
@media (max-width: 767px) {
  .modal.show .modal-dialog .modal-content .edit_customer_form .modal-body .field_wrapper .form-row .form_radio_wrapper .form_radio_block .form-radio {
    height: 16px;
    width: 16px;
  }
}
.modal.show .modal-dialog .modal-content .edit_customer_form .modal-body .field_wrapper .form-row .form_radio_wrapper .form_radio_block .radio_label {
  color: #15223B;
  font-size: 16px;
  line-height: 1;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .modal.show .modal-dialog .modal-content .edit_customer_form .modal-body .field_wrapper .form-row .form_radio_wrapper .form_radio_block .radio_label {
    font-size: 12px;
  }
}
@media (max-width: 767px) {
  .modal.show .modal-dialog .modal-content .edit_customer_form .modal-body .field_wrapper .form-row .form_radio_wrapper .form_radio_block {
    width: 20%;
    margin-right: 12px;
  }
}
.modal.show .modal-dialog .modal-content .edit_customer_form .modal-body .field_wrapper .checkbox_main {
  margin-bottom: 0px;
}
@media (max-width: 767px) {
  .modal.show .modal-dialog .modal-content .edit_customer_form .modal-body .field_wrapper .checkbox_main .checkbox_container .checkbox_block label {
    width: 16px;
    height: 16px;
  }
}
@media (max-width: 767px) {
  .modal.show .modal-dialog .modal-content .edit_customer_form .modal-body .field_wrapper .checkbox_main .checkbox_container .checkbox_block label::after {
    background-size: 16px 16px;
    width: 16px;
    height: 16px;
  }
}
@media (max-width: 767px) {
  .modal.show .modal-dialog .modal-content .edit_customer_form .modal-body .field_wrapper .checkbox_main {
    margin-bottom: 0px;
    margin-top: -12px;
  }
}
.modal.show .modal-dialog .modal-content .confirm_msg {
  font-family: "Proxima Nova Rg";
  font-size: 18px;
  font-style: normal;
  color: #000;
  margin-bottom: 24px;
}
.modal.show .modal-dialog .modal-content .customer_del_wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 50px;
}
.modal.show .modal-dialog .modal-content .customer_del_wrapper .customer_del_block .customer_field {
  font-family: "Proxima Nova Rg";
  font-size: 14px;
  font-style: normal;
  margin-bottom: 8px;
  color: #868594;
}
.modal.show .modal-dialog .modal-content .customer_del_wrapper .customer_del_block .customer_data {
  font-family: "Proxima Nova Rg";
  font-size: 18px;
  font-style: normal;
  color: #000;
}
.modal.show .modal-dialog .modal-content .modal-footer {
  border-top: none;
  padding: 21px 32px 32px 32px;
}
@media (max-width: 767px) {
  .modal.show .modal-dialog .modal-content .modal-footer {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 0px 16px 22px 16px;
  }
}
.modal.show .modal-dialog .modal-content .modal-footer button {
  margin: 0px;
}
.modal.show .modal-dialog .modal-content .modal-footer .outline_btn {
  border: 1px solid #ED1D24;
  background-color: transparent;
  border-radius: 0;
  margin-right: 12px;
}
.modal.show .modal-dialog .modal-content .modal-footer .outline_btn:hover {
  background-color: #ED1D24;
}
@media (max-width: 767px) {
  .modal.show .modal-dialog .modal-content .modal-footer .outline_btn {
    width: 118px;
  }
}
.modal.show .modal-dialog .modal-content .modal-footer .outline_btn:disabled {
  color: #ed1d24;
}
.modal.show .modal-dialog .modal-content .modal-footer .darkred_btn {
  border-radius: 0;
}
@media (max-width: 767px) {
  .modal.show .modal-dialog .modal-content .modal-footer .darkred_btn {
    width: 157px;
    padding: 5px 5px;
  }
}
.modal.show .modal-dialog .checkbox_block span {
  font-family: "Proxima Nova Rg";
  font-size: 14px;
  font-style: normal;
  color: #5D5D70;
}
.modal.show .modal-dialog .checkbox_container {
  height: auto;
}
.modal.show.customer_popup_modal .form-row .form-control {
  padding: 0px 5px 0px 0px;
  border: none;
  height: 22px;
}
.modal.show.user_popup_modal .modal-dialog {
  max-width: 766px;
}
.modal.show.user_popup_modal.enquiries_popup_modal .modal-body {
  margin-bottom: 3px;
}
@media (max-width: 767px) {
  .modal.show.user_popup_modal.enquiries_popup_modal .modal-body {
    margin-bottom: 0px;
  }
}
.modal.show.user_popup_modal.enquiries_popup_modal .modal-footer {
  padding: 0px 32px 32px 32px;
}
@media (max-width: 767px) {
  .modal.show.user_popup_modal.enquiries_popup_modal .modal-footer {
    padding: 20px 16px 22px 16px;
  }
  .modal.show.user_popup_modal.enquiries_popup_modal .modal-footer .darkred_btn {
    width: 118px;
  }
}
.modal.show.user_popup_modal.modify_criteria_modal .modal-dialog {
  max-width: 1068px;
}
.modal.show.user_popup_modal.modify_criteria_modal .modal-body {
  padding: 32px 32px 10px 32px;
}
@media (max-width: 767px) {
  .modal.show.user_popup_modal.modify_criteria_modal .modal-body {
    padding: 24px 16px 4px 16px;
  }
}
.modal.show.user_popup_modal.modify_criteria_modal .modal-footer .outline_btn {
  width: 183px;
}
@media (max-width: 767px) {
  .modal.show.user_popup_modal.modify_criteria_modal .modal-footer .outline_btn {
    width: 118px;
  }
}
.modal.show.user_popup_modal.modify_criteria_modal .modal-footer .darkred_btn {
  width: 177px;
}
@media (max-width: 767px) {
  .modal.show.user_popup_modal.modify_criteria_modal .modal-footer .darkred_btn {
    width: 118px;
  }
}
.modal.show.delete_popup_modal {
  z-index: 99999;
}
.modal.show.delete_popup_modal .modal-dialog {
  max-width: 595px;
}
.modal.show.delete_popup_modal .modal-dialog .modal-body {
  padding: 40px 32px 29px 32px;
}
@media (max-width: 767px) {
  .modal.show.delete_popup_modal .modal-dialog .modal-body {
    padding: 24px 16px 4px 16px;
  }
}
.modal.show.delete_popup_modal .modal-dialog .modal-footer button {
  width: 140px !important;
}
.modal .email-popup-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.modal .email-popup-body .email-popup-title {
  font-family: "Proxima Nova Bold";
  font-size: 24px;
  font-style: normal;
  margin-top: 20px;
}
.modal .email-popup-body .email-content {
  font-family: "Proxima Nova Rg";
  font-size: 18px;
  font-style: normal;
  text-align: center;
}
.modal .email-popup-body .email-content a {
  font-family: "Proxima Nova Bold";
  font-size: 18px;
  font-style: normal;
  text-decoration: none;
  color: #15223B;
}

.custom_dataTable .p-datatable .p-datatable-header {
  background: transparent;
  border: none;
  font-weight: normal;
  color: #15223B;
}
.custom_dataTable .p-datatable-wrapper table thead {
  border: 1px solid #efeff0;
  background: #e9eaea;
}
.custom_dataTable .p-datatable-wrapper table thead tr th {
  width: 20%;
  min-width: 180px;
  font-family: "Proxima Nova Bold";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px;
  color: #393d53;
  padding: 32px 0px;
  text-transform: uppercase;
  pointer-events: none;
}
.custom_dataTable .p-datatable-wrapper table thead tr th:first-child {
  padding-left: 20px;
}
.custom_dataTable .p-datatable-wrapper table thead tr th:last-child {
  padding-right: 20px;
}
.custom_dataTable .p-datatable-wrapper table thead tr th:last-child .p-column-header-content {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.custom_dataTable .p-datatable-wrapper table thead tr th span svg {
  display: none;
}
.custom_dataTable .p-datatable-wrapper table thead tr th:hover {
  cursor: auto;
}
.custom_dataTable .p-datatable-wrapper table thead tr th.location_cell_th {
  padding-left: 30px !important;
}
.custom_dataTable .p-datatable-wrapper table tbody tr {
  border-right: 1px solid #e2e3e4;
  border-bottom: 1px solid #e2e3e4;
  border-left: 1px solid #e2e3e4;
}
.custom_dataTable .p-datatable-wrapper table tbody tr td {
  font-family: "Proxima Nova Rg";
  font-size: 14px;
  font-style: normal;
  line-height: 12px;
  color: #15223B;
  padding: 16px 0px;
  width: 22%;
  min-width: 180px;
  padding-right: 20px;
}
.custom_dataTable .p-datatable-wrapper table tbody tr td .newUser {
  font-family: "Proxima Nova Bold";
  font-size: 14px;
  font-style: normal;
  padding-left: 20px;
}
.custom_dataTable .p-datatable-wrapper table tbody tr td a {
  color: #15223B;
}
.custom_dataTable .p-datatable-wrapper table tbody .p-datatable-emptymessage td {
  text-align: center;
  padding: 24px 20px;
  font-weight: 700;
  text-transform: capitalize;
}
.custom_dataTable .p-splitbutton-defaultbutton {
  display: none;
}
.custom_dataTable .p-splitbutton-menubutton {
  background-color: transparent;
  border: none;
  background-image: url("../../images/More.svg");
  background-repeat: no-repeat;
  background-size: 24px 24px;
  background-position: right;
  height: 24px;
}
.custom_dataTable .p-splitbutton-menubutton:focus-visible, .custom_dataTable .p-splitbutton-menubutton:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
  border: none;
}
.custom_dataTable .p-splitbutton-menubutton svg {
  opacity: 0;
}

.p-menu .p-menu-list {
  margin-bottom: 0px;
  padding-left: 0px;
}
.p-menu .p-menu-list::before {
  content: url("../../images/Polygon_one.svg");
  position: absolute;
  top: -15px;
  right: auto;
  left: 20px;
}
@media (max-width: 1785px) {
  .p-menu .p-menu-list::before {
    right: 5px;
    left: auto;
  }
}
.p-menu .p-menu-list .p-menuitem {
  border-bottom: 1px solid #5d5d70;
}
.p-menu .p-menu-list .p-menuitem .p-menuitem-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  padding: 16px;
  background: #15223B;
  text-decoration: none;
  text-transform: capitalize;
  border-bottom: 0px;
  min-width: 200px;
}
.p-menu .p-menu-list .p-menuitem .p-menuitem-link .p-menuitem-text {
  font-family: "Proxima Nova Bold";
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  font-weight: 700;
  color: #fff;
  margin-left: 9px;
}
.p-menu .p-menu-list .p-menuitem .p-menuitem-link:focus-visible {
  outline: none;
}
.p-menu .p-menu-list .p-menuitem .p-menuitem-link:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.table-header {
  background-color: transparent;
  border: none;
}
.table-header h1 {
  font-family: "Proxima Nova Bold";
  font-size: 30px;
  font-style: normal;
  line-height: 30px;
  font-weight: 700;
  color: #15223B;
  margin-bottom: 0px;
}
@media (max-width: 767px) {
  .table-header h1 {
    font-size: 24px;
  }
}

@media (max-width: 767px) {
  .customer_table {
    display: none;
  }
}

.user_table .custom_dataTable .p-datatable-wrapper table thead tr th {
  width: 41%;
}
.user_table .custom_dataTable .p-datatable-wrapper table tbody tr td {
  width: 41%;
}

.pump_table_width .p-datatable-wrapper table thead tr {
  pointer-events: all;
  cursor: auto;
}
.pump_table_width .p-datatable-wrapper table thead tr:first-child th:first-child {
  position: relative;
}
.pump_table_width .p-datatable-wrapper table thead tr:first-child th:first-child span svg {
  position: absolute;
  right: 160px;
}
@media (max-width: 1200px) {
  .pump_table_width .p-datatable-wrapper table thead tr:first-child th:first-child span svg {
    right: 153px;
  }
}
.pump_table_width .p-datatable-wrapper table thead tr:first-child th:nth-child(3) {
  position: relative;
}
.pump_table_width .p-datatable-wrapper table thead tr:first-child th:nth-child(3) span svg {
  position: absolute;
  right: 40px;
  top: 33px;
}
.pump_table_width .p-datatable-wrapper table thead tr:nth-child(2) th {
  position: relative;
}
.pump_table_width .p-datatable-wrapper table thead tr:nth-child(2) th span svg {
  position: absolute;
  right: 32px;
  top: 10px;
}
.pump_table_width .p-datatable-wrapper table thead tr th {
  position: relative;
  cursor: auto;
  pointer-events: all;
}
.pump_table_width .p-datatable-wrapper table thead tr th.p-sortable-column {
  cursor: pointer;
}
.pump_table_width .p-datatable-wrapper table thead tr th.p-sortable-column:hover {
  cursor: pointer;
}
.pump_table_width .p-datatable-wrapper table thead tr th:hover {
  background-color: #f8f9fa;
  cursor: auto;
}
.pump_table_width .p-datatable-wrapper table thead tr th span svg {
  display: inline-block;
  size: 16px;
  height: 16px;
  width: 16px;
  color: #5D5D70;
  position: absolute;
  right: 60px;
  top: 32px;
}

.custom_dataTable .p-datatable-wrapper table thead tr th:hover {
  background-color: #f8f9fa;
}

.pump_table_width .p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover .p-sortable-column-icon {
  color: #15223B;
}

.pump_table_width .p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
  background-color: #f8f9fa;
}

.table_box {
  padding-top: 32px;
}

.pumps_tabs .show_enquiries_detail {
  font-family: "Proxima Nova Bold";
  font-size: 12px;
  font-style: normal;
  color: #15223B;
  line-height: normal;
  font-weight: 700;
  padding: 4.5px 8px;
  background-color: #e2e3e4;
  border-radius: 40px;
  margin-left: 4px;
  cursor: pointer;
  position: relative;
  display: inline-block;
}
.pumps_tabs .show_enquiries_detail:hover .tooltip_show {
  display: block;
}
.pumps_tabs .show_enquiries_detail .tooltip_show {
  display: none;
  position: absolute;
  bottom: 33px;
  left: -32px;
  background-color: #15223B;
  padding: 8.5px 9.5px;
  border-radius: 6px;
  list-style: none;
  margin-bottom: 0px;
  margin-left: 0px;
}
.pumps_tabs .show_enquiries_detail .tooltip_show::before {
  content: url("../../images/Polygon_one.svg");
  position: absolute;
  bottom: -10px;
  left: 40px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.pumps_tabs .show_enquiries_detail .tooltip_show .tooltip_show_data {
  font-family: "Proxima Nova Bold";
  font-size: 12px;
  font-style: normal;
  color: #fff;
  line-height: normal;
  white-space: nowrap;
}
.pumps_tabs .location_td {
  padding-left: 30px;
}

.filter_modal_content {
  max-width: 440px;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 999;
  background: #fff;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  -webkit-transition: -webkit-transform 0.3s linear;
  transition: -webkit-transform 0.3s linear;
  transition: transform 0.3s linear;
  transition: transform 0.3s linear, -webkit-transform 0.3s linear;
}
@media (max-width: 767px) {
  .filter_modal_content {
    max-width: 320px;
  }
}
.filter_modal_content .filter_modal_head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 0px;
  border-bottom: 1px solid #b9b8c1;
  padding: 30px 20px 20px 20px;
}
@media (max-width: 767px) {
  .filter_modal_content .filter_modal_head {
    padding: 20px 20px 20px 20px;
  }
}
.filter_modal_content .filter_modal_head h2 {
  font-family: "Proxima Nova Bold";
  font-size: 24px;
  font-style: normal;
  line-height: normal;
  color: #15223B;
  margin-bottom: 0px;
}
.filter_modal_content .filter_modal_body {
  padding: 20px 15px 100px 20px;
  margin-right: 5px;
  max-height: 100%;
  overflow: auto;
}
.filter_modal_content .filter_modal_body button {
  margin-top: 40px;
}
.filter_modal_content .filter_modal_body .p-dropdown {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  width: 100% !important;
  height: 48px;
  padding: 0px 0px 0px 16px;
  background-image: url("../../images/chevron-down_dark.svg");
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 20px 20px;
  border: 1px solid #e2e3e4;
  border-radius: 0px;
}
.filter_modal_content .filter_modal_body .p-dropdown select {
  background-color: transparent;
  width: 100% !important;
  height: 48px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  color: #15223B;
  border-radius: 0px;
}
.filter_modal_content .filter_modal_body .p-dropdown .p-dropdown-label {
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  color: #15223B;
}
.filter_modal_content .filter_modal_body .p-dropdown .p-dropdown-trigger {
  opacity: 0;
}
.filter_modal_content .filter_modal_body .p-dropdown:not(.p-disabled).p-focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.filter_modal_content .filter_modal_body .p-dropdown:focus-visible {
  border: 1px solid #e2e3e4;
  outline: none;
}
.filter_modal_content .filter_modal_body .p-multiselect {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  width: 100% !important;
  height: 48px;
  padding: 0px 0px 0px 16px;
  background-image: url("../../images/chevron-down_dark.svg");
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 20px 20px;
  border: 1px solid #e2e3e4;
  border-radius: 0px;
}
.filter_modal_content .filter_modal_body .p-multiselect select {
  background-color: transparent;
  width: 100% !important;
  height: 48px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  color: #15223B;
  border-radius: 0px;
}
.filter_modal_content .filter_modal_body .p-multiselect .p-multiselect-label {
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  color: #15223B;
}
.filter_modal_content .filter_modal_body .p-multiselect .p-multiselect-trigger {
  opacity: 0;
}
.filter_modal_content .filter_modal_body .p-multiselect:not(.p-disabled).p-focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.filter_modal_content .filter_modal_body .p-multiselect:focus-visible {
  border: 1px solid #e2e3e4;
  outline: none;
}

.filter_box .filter_link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 12px 14px;
  text-transform: capitalize;
  border: 1px solid rgba(108, 118, 144, 0.1490196078);
  background: #fff;
  text-decoration: none;
  color: #5d5d70;
  font-family: "Proxima Nova Rg";
  font-size: 14px;
  font-style: normal;
}
@media (max-width: 767px) {
  .filter_box .filter_link {
    padding: 10.5px 12px;
    margin-top: 16px;
  }
}
.filter_box .filter_link svg {
  margin-right: 6px;
}
.filter_box .filter_link:hover {
  border: 1px solid #ED1D24;
  color: #ED1D24;
}
.filter_box .filter_link:hover svg path {
  stroke: #ED1D24;
}

.filter_box.filterOpen .filter_modal_content {
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}

.conditional_block_show {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 767px) {
  .conditional_block_show {
    width: 100%;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
}

.enquiries_details {
  padding-bottom: 23px;
}
@media (max-width: 991px) {
  .enquiries_details .enquiries_main_row {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    margin: 0 auto;
  }
}
.enquiries_details .enquiries_main_row .enquiries_left_box {
  padding-right: 10px;
}
.enquiries_details .enquiries_main_row .enquiries_left_box .details_block {
  overflow: hidden;
}
@media (max-width: 1400px) {
  .enquiries_details .enquiries_main_row .enquiries_left_box {
    width: 70%;
  }
}
@media (max-width: 1200px) {
  .enquiries_details .enquiries_main_row .enquiries_left_box {
    width: 60%;
  }
  .enquiries_details .enquiries_main_row .enquiries_left_box .col_1200_w_100 {
    width: 100%;
  }
}
@media (max-width: 991px) {
  .enquiries_details .enquiries_main_row .enquiries_left_box {
    width: 100%;
    margin: 0 auto;
    margin-top: 20px;
    padding: 0px;
  }
}
.enquiries_details .enquiries_main_row .enquiries_right_box {
  padding-right: 10px;
}
@media (max-width: 1400px) {
  .enquiries_details .enquiries_main_row .enquiries_right_box {
    width: 30%;
  }
}
@media (max-width: 1200px) {
  .enquiries_details .enquiries_main_row .enquiries_right_box {
    width: 40%;
  }
}
@media (max-width: 991px) {
  .enquiries_details .enquiries_main_row .enquiries_right_box {
    width: 100%;
    margin: 0 auto;
    padding: 0px;
  }
}

.activity_block {
  border: 1px solid #e2e3e4;
  padding: 20px 20px 24px 20px;
}

.status_change .p-dropdown {
  border: 1px solid #e2e3e4;
  background-color: #fff;
  border-radius: 0px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.status_change .p-dropdown:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #e2e3e4;
  outline: none;
}
.status_change .p-dropdown:focus-visible {
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #e2e3e4;
  outline: none;
}
.status_change .p-dropdown:not(.p-disabled).p-focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #e2e3e4;
  outline: none;
}
.status_change .p-dropdown-label {
  font-family: "Proxima Nova Rg";
  font-size: 16px;
  font-style: normal;
  line-height: 16px;
  color: #15223B;
  padding-left: 16px;
  padding-right: 5px;
  border-right: 0px;
}
.status_change .p-dropdown-trigger {
  background-image: url("../../images/chevron-down_dark.svg");
  background-repeat: no-repeat;
  background-position: left;
  background-size: 20px 20px;
  width: 30px;
  height: 50px;
  margin-left: auto;
}
.status_change .p-dropdown-trigger svg {
  display: none;
}

.p-dropdown-panel .p-dropdown-items-wrapper .p-dropdown-items {
  padding-left: 0px;
  margin-bottom: 0px;
  background-color: #fff;
}
.p-dropdown-panel .p-dropdown-items-wrapper .p-dropdown-items .p-dropdown-item {
  list-style: none;
  font-family: "Proxima Nova Rg";
  font-size: 16px;
  font-style: normal;
  line-height: 16px;
  color: #15223B;
  padding: 16px;
  border-top: 1px solid #e2e3e4;
  border-right: 1px solid #e2e3e4;
  border-left: 1px solid #e2e3e4;
  border-bottom: 0px solid #e2e3e4;
}
.p-dropdown-panel .p-dropdown-items-wrapper .p-dropdown-items .p-dropdown-item:last-child {
  border-bottom: 1px solid #e2e3e4;
}
.p-dropdown-panel .p-dropdown-items-wrapper .p-dropdown-items .p-dropdown-item:hover {
  background-color: #efc8b8;
}
.p-dropdown-panel .p-dropdown-items-wrapper .p-dropdown-items .p-dropdown-item.p-highlight {
  background-color: #efc8b8;
}

.steps_flow {
  margin-top: 32px;
  overflow: hidden;
}
@media (max-width: 767px) {
  .steps_flow {
    margin-top: 24px;
  }
}
.steps_flow .progress_bar {
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.steps_flow .progress_bar li {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  position: relative;
  text-align: center;
  cursor: pointer;
  min-height: 124px;
}
@media (max-width: 991px) {
  .steps_flow .progress_bar li {
    min-height: 100px;
  }
}
.steps_flow .progress_bar li::before {
  content: "";
  min-width: 20px;
  min-height: 20px;
  width: 20px;
  height: 20px;
  border: 2px solid #c9cacc;
  border-radius: 100%;
  display: block;
  text-align: center;
  background-color: #fff;
  z-index: 8;
  position: relative;
}
.steps_flow .progress_bar li::after {
  content: "";
  position: absolute;
  width: 3px;
  height: 100%;
  top: -100%;
  left: 8.4px;
  z-index: -1;
  border-left: 3px dashed #c9cacc;
}
.steps_flow .progress_bar li:first-child .step_data .step_info {
  margin-top: 0px;
}
.steps_flow .progress_bar li:first-child::after {
  content: none;
}
.steps_flow .progress_bar li.active {
  color: #f57e20;
}
.steps_flow .progress_bar li.active::before {
  border-color: #f57e20;
  border-width: 5px;
}
.steps_flow .progress_bar li.active::after {
  border-left: 3px solid #f57e20;
  z-index: 1;
}
.steps_flow .progress_bar li .step_data {
  margin-bottom: 20px;
  margin-left: 20px;
}
@media (max-width: 767px) {
  .steps_flow .progress_bar li .step_data {
    margin-bottom: 24px;
    margin-right: 10px;
  }
}
.steps_flow .progress_bar li .step_data .step_info {
  margin-bottom: 12px;
  font-family: "Proxima Nova Rg";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #000;
  text-align: start;
  margin-top: -4px;
}
@media (max-width: 767px) {
  .steps_flow .progress_bar li .step_data .step_info {
    font-size: 18px;
    margin-bottom: 8px;
  }
}
.steps_flow .progress_bar li .step_data .step_date {
  text-align: start;
  margin-bottom: 0px;
  font-family: "Proxima Nova Rg";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #5D5D70;
}
@media (max-width: 767px) {
  .steps_flow .progress_bar li .step_data .step_date {
    font-size: 12px;
  }
}
.steps_flow .progress_bar li .step_data .step_date .editable-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 5px;
}
.steps_flow .progress_bar li .step_data .step_date .editable-container a {
  text-decoration: none;
  color: #5D5D70;
}
.steps_flow .progress_bar li .step_data .step_date .editable-container .form-group .form-control {
  width: 140px !important;
}
.steps_flow .progress_bar li .step_data .step_date .editable-container .editable-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 5px;
}

.modal.show.graph_modal .modal-dialog {
  max-width: 900px;
  padding: 0px;
  margin-top: 40px;
}
@media (max-width: 767px) {
  .modal.show.graph_modal .modal-dialog {
    margin: 40px auto;
    width: 100%;
    padding: 0px 10px;
  }
}
.modal.show.graph_modal .modal-dialog .modal-content {
  border-radius: 0px;
  border: none;
  max-height: 715px;
  overflow: auto;
}
@media (max-width: 767px) {
  .modal.show.graph_modal .modal-dialog .modal-content {
    max-height: 612px;
  }
}
.modal.show.graph_modal .modal-dialog .modal-content .modal-header {
  padding: 31px 32px 24px 32px;
  border-bottom: 1px solid #B9B8C1;
  position: sticky;
  top: 0px;
  background: #fff;
  z-index: 1;
}
.modal.show.graph_modal .modal-dialog .modal-content .modal-header .btn-close {
  width: 24px;
  height: 24px;
  padding-right: 16px;
}
@media (max-width: 767px) {
  .modal.show.graph_modal .modal-dialog .modal-content .modal-header {
    padding: 16px 15px 21px 15px;
    position: static;
  }
}
@media (max-width: 767px) {
  .modal.show.graph_modal .modal-dialog .modal-content .modal-header h2 {
    padding-right: 33px;
  }
}
.modal.show.graph_modal .modal-dialog .modal-content .modal-body {
  padding: 12px 13px;
}
@media (max-width: 767px) {
  .modal.show.graph_modal .modal-dialog .modal-content .modal-body {
    padding: 15px 16px 29px 16px;
  }
}
.modal.show.graph_modal .modal-dialog .modal-content .modal-body .graph_img {
  position: relative;
  margin-bottom: 18px;
}
@media (max-width: 767px) {
  .modal.show.graph_modal .modal-dialog .modal-content .modal-body .graph_img {
    margin-bottom: 24px;
  }
}
.modal.show.graph_modal .modal-dialog .modal-content .modal-body .graph_img .imgGraph {
  max-width: 100%;
}
.modal.show.graph_modal .modal-dialog .modal-content .modal-body .selection_bar {
  margin-bottom: 11px;
}
@media (max-width: 767px) {
  .modal.show.graph_modal .modal-dialog .modal-content .modal-body .selection_bar {
    margin-bottom: 16px;
  }
}
.modal.show.graph_modal .modal-dialog .modal-content .modal-body .selection_bar .selection_bar_heading {
  color: #fff;
  font-family: "Proxima Nova Bold";
  font-size: 24px;
  font-style: normal;
  line-height: normal;
  font-weight: 700;
  background-color: #BE1E2D;
  padding: 10px 19px;
}
@media (max-width: 767px) {
  .modal.show.graph_modal .modal-dialog .modal-content .modal-body .selection_bar .selection_bar_heading {
    padding: 17px 16px;
    font-size: 16px;
  }
}
.modal.show.graph_modal .modal-dialog .modal-content .modal-body .operator_graph .details_block {
  padding: 26px 30px 30px 30px;
}
.modal.show.graph_modal .modal-dialog .modal-content .modal-body .operator_graph .details_block:first-child {
  padding: 30px 0px 30px 30px;
}
@media (max-width: 767px) {
  .modal.show.graph_modal .modal-dialog .modal-content .modal-body .operator_graph .details_block:first-child {
    padding: 30px 16px 32px 16px;
  }
}
.modal.show.graph_modal .modal-dialog .modal-content .modal-body .operator_graph .details_block:last-child {
  margin-bottom: 0px;
}
@media (max-width: 767px) {
  .modal.show.graph_modal .modal-dialog .modal-content .modal-body .operator_graph .details_block {
    padding: 24px 16px 27px 16px;
  }
}
.modal.show.graph_modal .modal-dialog .modal-content .modal-body .operator_graph .details_block .form-row {
  margin-top: 20px;
}
@media (max-width: 767px) {
  .modal.show.graph_modal .modal-dialog .modal-content .modal-body .operator_graph .details_block .form-row {
    margin-top: 24px;
  }
  .modal.show.graph_modal .modal-dialog .modal-content .modal-body .operator_graph .details_block .form-row label {
    margin-bottom: 8px;
    font-size: 14px;
  }
}
.modal.show.graph_modal .modal-dialog .modal-content .modal-body .operator_graph .details_block .inner_details .sub_details_head {
  margin-bottom: 8px;
}
@media (max-width: 767px) {
  .modal.show.graph_modal .modal-dialog .modal-content .modal-body .operator_graph .details_block .inner_details .sub_details_head {
    margin-bottom: 0px;
  }
}
.modal.show.graph_modal .modal-dialog .modal-content .modal-body .operator_graph .details_block .inner_details .col-md-4, .modal.show.graph_modal .modal-dialog .modal-content .modal-body .operator_graph .details_block .inner_details .col-md-3 {
  padding-top: 0px;
  padding-bottom: 0px;
}
.modal.show.graph_modal .modal-dialog .modal-content .modal-body .operator_graph .details_block .inner_details .operator_box {
  padding-left: 38px;
}
@media (max-width: 767px) {
  .modal.show.graph_modal .modal-dialog .modal-content .modal-body .operator_graph .details_block .inner_details .operator_box {
    padding-left: 12px;
    padding-top: 33px;
  }
}
@media (max-width: 767px) {
  .modal.show.graph_modal .modal-dialog .modal-content .modal-body .operator_graph .details_block .inner_details .col-md-9.operator_box .sm-px-5:nth-child(even) {
    width: 49%;
  }
  .modal.show.graph_modal .modal-dialog .modal-content .modal-body .operator_graph .details_block .inner_details .col-md-9.operator_box .sm-px-5:nth-child(odd) {
    width: 51%;
  }
}
@media (max-width: 767px) {
  .modal.show.graph_modal .modal-dialog .modal-content .modal-body .operator_graph .details_block .details_data .unit {
    font-size: 12px;
    color: #5D5D70;
  }
}

.pumple_list .container .pump_table_main {
  margin-bottom: 80px;
}
@media (max-width: 767px) {
  .pumple_list .container .pump_table_main {
    margin-bottom: 40px;
  }
}
.pumple_list .container .pump_table_overflow {
  overflow: auto;
}
@media (max-width: 767px) {
  .pumple_list .container .pump_table_overflow {
    overflow: hidden;
  }
}
.pumple_list .container .pump_table_overflow .pump_table_width {
  min-width: 1240px;
}
@media (max-width: 767px) {
  .pumple_list .container .pump_table_overflow .pump_table_width {
    min-width: auto;
  }
}
@media (max-width: 767px) {
  .pumple_list .container .pump_table_overflow .pump_table_width .p-datatable {
    display: none;
  }
}
.pumple_list .container .pump_table_overflow .pump_table_width .p-datatable-wrapper {
  overflow: hidden !important;
}
.pumple_list .container .pump_table_overflow .pump_table_width .p-datatable-wrapper .p-datatable-table .p-datatable-thead tr th {
  border-left: 0px;
  padding-top: 14px;
  padding-bottom: 14px;
}
@media (max-width: 767px) {
  .pumple_list .container .pump_table_overflow .pump_table_width .p-datatable-wrapper .p-datatable-table .p-datatable-thead tr th {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
.pumple_list .container .pump_table_overflow .pump_table_width .p-datatable-wrapper .p-datatable-table .p-datatable-thead tr th .p-column-header-content .p-column-title {
  width: 100%;
  text-align: center;
}
.pumple_list .container .pump_table_overflow .pump_table_width .p-datatable-wrapper .p-datatable-table .p-datatable-thead tr:first-child th:first-child {
  min-width: 230px;
}
@media (max-width: 767px) {
  .pumple_list .container .pump_table_overflow .pump_table_width .p-datatable-wrapper .p-datatable-table .p-datatable-thead tr:first-child th:first-child {
    min-width: 180px;
    position: sticky;
    top: 0;
    left: 0px;
    background-color: #C9CACC;
  }
}
.pumple_list .container .pump_table_overflow .pump_table_width .p-datatable-wrapper .p-datatable-table .p-datatable-thead tr:first-child th:first-child .p-column-title {
  text-align: left;
}
.pumple_list .container .pump_table_overflow .pump_table_width .p-datatable-wrapper .p-datatable-table .p-datatable-thead tr:first-child th:last-child {
  min-width: 50px;
}
@media (max-width: 767px) {
  .pumple_list .container .pump_table_overflow .pump_table_width .p-datatable-wrapper .p-datatable-table .p-datatable-thead tr:first-child th:last-child {
    display: none;
  }
}
.pumple_list .container .pump_table_overflow .pump_table_width .p-datatable-wrapper .p-datatable-table .p-datatable-tbody tr td {
  text-align: center;
}
.pumple_list .container .pump_table_overflow .pump_table_width .p-datatable-wrapper .p-datatable-table .p-datatable-tbody tr td:first-child {
  text-align: left;
}
@media (max-width: 767px) {
  .pumple_list .container .pump_table_overflow .pump_table_width .p-datatable-wrapper .p-datatable-table .p-datatable-tbody tr td:first-child {
    min-width: 180px;
    position: sticky;
    top: 0;
    left: 0px;
  }
}
.pumple_list .container .pump_table_overflow .pump_table_width .p-datatable-wrapper .p-datatable-table .p-datatable-tbody tr td:first-child .newUser {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.pumple_list .container .pump_table_overflow .pump_table_width .p-datatable-wrapper .p-datatable-table .p-datatable-tbody tr td:first-child .newUser input {
  height: 20px;
  width: 20px;
  accent-color: #ED1D24;
  margin-right: 20px;
  display: none;
}
@media (max-width: 767px) {
  .pumple_list .container .pump_table_overflow .pump_table_width .p-datatable-wrapper .p-datatable-table .p-datatable-tbody tr td:first-child .newUser input {
    display: block;
  }
}
.pumple_list .container .pump_table_overflow .pump_table_width .p-datatable-wrapper .p-datatable-table .p-datatable-tbody tr td:first-child .newUser p {
  margin: 0;
}
.pumple_list .container .pump_table_overflow .pump_table_width .p-datatable-wrapper .p-datatable-table .p-datatable-tbody tr td:first-child .newUser .model_img {
  margin-left: 8px;
}
.pumple_list .container .pump_table_overflow .pump_table_width .p-datatable-wrapper .p-datatable-table .p-datatable-tbody tr td:last-child {
  min-width: 50px;
  text-align: right;
}
@media (max-width: 767px) {
  .pumple_list .container .pump_table_overflow .pump_table_width .p-datatable-wrapper .p-datatable-table .p-datatable-tbody tr td:last-child {
    display: none;
  }
}
.pumple_list .container .pump_table_overflow .pump_table_width .p-datatable-wrapper .p-datatable-table .p-datatable-tbody tr td:last-child input {
  height: 20px;
  width: 20px;
  accent-color: #ED1D24;
}
.pumple_list .container .pump_table_overflow .pump_table_width .p-datatable-wrapper .p-datatable-table .p-datatable-tbody tr td:last-child input:checked {
  accent-color: #ED1D24;
}
.pumple_list .container .pump_table_overflow .pump_mobile_table {
  display: none;
}
@media (max-width: 767px) {
  .pumple_list .container .pump_table_overflow .pump_mobile_table {
    display: block;
  }
  .pumple_list .container .pump_table_overflow .pump_mobile_table .pump_thead {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding: 24px 39px 24px 42px;
    background-color: #f3f4f4;
    border: 1px solid #e9eaeb;
  }
  .pumple_list .container .pump_table_overflow .pump_mobile_table .pump_thead .pump_mobile_th h6 {
    font-family: "Proxima Nova Bold";
    font-size: 12px;
    font-style: normal;
    text-transform: uppercase;
    color: #393d53;
    margin-bottom: 0px;
  }
  .pumple_list .container .pump_table_overflow .pump_mobile_table .pump_mobile_tabs {
    margin-bottom: 70px;
  }
  .pumple_list .container .pump_table_overflow .pump_mobile_table .pump_mobile_tabs .p-icon {
    display: none;
  }
  .pumple_list .container .pump_table_overflow .pump_mobile_table .pump_mobile_tabs .p-accordion-header {
    min-width: none;
    height: auto;
    max-height: unset;
  }
  .pumple_list .container .pump_table_overflow .pump_mobile_table .pump_mobile_tabs .p-accordion-header:focus {
    outline: none;
  }
  .pumple_list .container .pump_table_overflow .pump_mobile_table .pump_mobile_tabs .p-accordion-header-text {
    width: 100%;
  }
  .pumple_list .container .pump_table_overflow .pump_mobile_table .pump_mobile_tabs .p-accordion-header-link {
    text-decoration: none;
    border: none;
    border-bottom: 1px solid #e2e3e4;
    border-left: 1px solid #e2e3e4;
    border-right: 1px solid #e2e3e4;
    border-radius: 0px;
  }
  .pumple_list .container .pump_table_overflow .pump_mobile_table .pump_mobile_tabs .p-accordion-header-link:focus {
    outline: 0px !important;
  }
  .pumple_list .container .pump_table_overflow .pump_mobile_table .pump_mobile_tabs .main_toggle_mobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 100%;
    padding: 14px 16px 14px 16px;
  }
  .pumple_list .container .pump_table_overflow .pump_mobile_table .pump_mobile_tabs .main_toggle_mobile .radio_and_text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .pumple_list .container .pump_table_overflow .pump_mobile_table .pump_mobile_tabs .main_toggle_mobile .radio_and_text h4 {
    font-family: "Proxima Nova Bold";
    font-size: 14px;
    font-style: normal;
    color: #15223B;
    margin-bottom: 0px;
    text-decoration: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    margin-right: 8px;
    margin-left: 8px;
  }
  .pumple_list .container .pump_table_overflow .pump_mobile_table .pump_mobile_tabs .main_toggle_mobile .radio_and_text h4 .show_graph_mobile {
    margin-left: 8px;
  }
  .pumple_list .container .pump_table_overflow .pump_mobile_table .pump_mobile_tabs .main_toggle_mobile .stage_details .stage_content {
    font-family: "Proxima Nova Rg";
    font-size: 14px;
    font-style: normal;
    color: #15223B;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    gap: 7px;
  }
  .pumple_list .container .pump_table_overflow .pump_mobile_table .pump_mobile_tabs .main_toggle_mobile .stage_details .stage_content::after {
    content: url("../../images/chevron-down_dark.svg");
    width: 24px;
    height: 24px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
  .pumple_list .container .pump_table_overflow .pump_mobile_table .pump_mobile_tabs .mobile_pump_accordion_body {
    padding: 10px 8px 20px 48px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    row-gap: 24px;
  }
  .pumple_list .container .pump_table_overflow .pump_mobile_table .pump_mobile_tabs .mobile_pump_accordion_body .form-row {
    width: 49%;
    margin-bottom: 0px;
  }
  .pumple_list .container .pump_table_overflow .pump_mobile_table .pump_mobile_tabs .mobile_pump_accordion_body .form-row .field_td_mobile {
    margin-bottom: 0px;
    font-family: "Proxima Nova Rg";
    font-size: 18px;
    font-style: normal;
    color: #000;
  }
  .pumple_list .container .pump_table_overflow .pump_mobile_table .pump_mobile_tabs .p-radiobutton .p-radiobutton-box {
    border: 2px solid #ED1D24;
    background-color: #fff;
    width: 15px;
    height: 15px;
    border-radius: 50px !important;
  }
  .pumple_list .container .pump_table_overflow .pump_mobile_table .pump_mobile_tabs .p-radiobutton .p-radiobutton-icon {
    background-color: #ED1D24;
  }
  .pumple_list .container .pump_table_overflow .pump_mobile_table .pump_mobile_tabs .p-radiobutton.p-highlight:not(.p-disabled):hover {
    background-color: #fff;
  }
  .pumple_list .container .pump_table_overflow .pump_mobile_table .pump_mobile_tabs .p-accordion-tab-active .p-accordion-header-link {
    border-bottom: 0px;
  }
  .pumple_list .container .pump_table_overflow .pump_mobile_table .pump_mobile_tabs .p-accordion-tab-active .p-accordion-header-link:focus {
    outline: 0px !important;
  }
  .pumple_list .container .pump_table_overflow .pump_mobile_table .pump_mobile_tabs .p-accordion-tab-active .stage_details .stage_content::after {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
}
.pumple_list .p-datatable-wrapper table {
  border-collapse: separate;
}
.pumple_list .p-datatable-wrapper table thead tr:first-child th:nth-last-child(2) {
  border-bottom: 0px;
}
.pumple_list .p-datatable-wrapper table thead tr:first-child th:first-child {
  border-left: 1px solid #e2e3e4 !important;
}
.pumple_list .p-datatable-wrapper table thead tr:first-child th:last-child {
  border-right: 1px solid #e2e3e4 !important;
}
.pumple_list .p-datatable-wrapper table thead tr th {
  border-bottom: 1px solid #e2e3e4;
}
.pumple_list .p-datatable-wrapper table tbody tr {
  border-right: none;
  border-left: none;
  border-bottom: none;
}
.pumple_list .p-datatable-wrapper table tbody tr td {
  border-bottom: 1px solid #e2e3e4;
}
.pumple_list .p-datatable-wrapper table tbody tr td:first-child {
  border-left: 1px solid #e2e3e4;
}
.pumple_list .p-datatable-wrapper table tbody tr td:last-child {
  border-right: 1px solid #e2e3e4;
}

.pump_btn_fix {
  position: sticky;
  bottom: 0px;
  background-color: #fff;
}

.pumple_list_btn {
  padding: 20px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
@media (max-width: 767px) {
  .pumple_list_btn {
    padding: 12px 16px 12px 0px;
  }
}
.pumple_list_btn .outline_btn {
  border: 1px solid #ED1D24;
  background-color: transparent;
  border-radius: 0;
  margin-right: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (max-width: 767px) {
  .pumple_list_btn .outline_btn {
    margin-right: 12px;
    font-size: 16px;
    line-height: 16px;
    width: 166px;
    height: 40px;
  }
}
.pumple_list_btn .outline_btn svg {
  margin-top: 0px;
}
.pumple_list_btn .outline_btn svg path {
  stroke: #ED1D24;
  fill: transparent;
}
.pumple_list_btn .outline_btn:hover {
  background-color: #ED1D24;
  color: #fff;
}
.pumple_list_btn .outline_btn:hover path {
  stroke: #fff;
}
@media (max-width: 767px) {
  .pumple_list_btn .darkred_btn {
    font-size: 14px;
  }
}
.pumple_list_btn .darkred_btn:disabled {
  border-radius: 0;
  background-color: #808285;
  border-color: #808285;
}
.pumple_list_btn .darkred_btn:disabled svg {
  margin-top: 0px;
}
.pumple_list_btn .darkred_btn:disabled svg path {
  fill: transparent;
}
.pumple_list_btn .darkred_btn:disabled:hover {
  background-color: transparent;
  color: #808285;
}
.pumple_list_btn .darkred_btn:disabled:hover path {
  stroke: #808285;
}
@media (max-width: 767px) {
  .pumple_list_btn .darkred_btn:disabled {
    height: 40px;
    font-size: 14px;
    line-height: 14px;
    width: 157px;
  }
  .pumple_list_btn .darkred_btn:disabled:hover {
    background-color: transparent;
  }
}
.pumple_list_btn .full_hr {
  margin-top: -20px;
}
@media (max-width: 767px) {
  .pumple_list_btn .full_hr {
    margin-top: -12px;
  }
}
.pumple_list_btn button:disabled {
  pointer-events: none;
}

.sales_main {
  margin-top: 32px;
  padding-bottom: 32px;
}
@media (max-width: 767px) {
  .sales_main {
    margin-top: 24px;
    padding-bottom: 24px;
  }
}
@media (max-width: 767px) {
  .sales_main .upload_offer_btn {
    margin-top: 24px;
  }
}
.sales_main .order_track .details_block {
  margin-bottom: 18px;
}
.sales_main.sale_order_list_view .custom_dataTable .p-datatable-wrapper table tbody tr td:first-child {
  padding-left: 20px;
}

.trackStatus_block {
  padding: 38px 36px 70px 36px;
  border: 1px solid #e2e3e4;
}
@media (max-width: 767px) {
  .trackStatus_block {
    padding: 24px 16px 40px 16px;
    max-width: 100%;
    margin: 0 auto;
  }
}
@media (min-width: 768px) {
  .trackStatus_block .steps_flow.track_flow {
    overflow: auto;
  }
}

.track_flow {
  margin-top: 40px;
}
@media (max-width: 767px) {
  .track_flow {
    margin-top: 20px;
  }
}
.track_flow .progress_bar {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
@media (max-width: 767px) {
  .track_flow .progress_bar {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.track_flow .progress_bar li {
  row-gap: 12px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-right: 10px;
}
@media (max-width: 767px) {
  .track_flow .progress_bar li {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-columns: 12px;
       -moz-columns: 12px;
            columns: 12px;
  }
}
@media (min-width: 768px) {
  .track_flow .progress_bar li .step_data {
    margin-left: 0;
  }
}
.track_flow .progress_bar li .step_data .step_info {
  font-family: "Proxima Nova Rg";
  font-size: 18px;
  font-style: normal;
}
.track_flow .progress_bar li .step_data .step_date {
  font-family: "Proxima Nova Rg";
  font-size: 14px;
  font-style: normal;
  color: #868594;
}
.track_flow .progress_bar li::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  top: 8.3px;
  left: -100%;
  z-index: -1;
  border-bottom: 3px dashed #c9cacc;
}
@media (max-width: 767px) {
  .track_flow .progress_bar li::after {
    left: 0px;
    border-bottom: 0px;
    border-left: 3px dashed #c9cacc;
  }
}
.track_flow .progress_bar li:first-child .step_data .step_info {
  margin-top: 0px;
}
.track_flow .progress_bar li:first-child::after {
  content: none;
}
@media (max-width: 767px) {
  .track_flow .progress_bar li:nth-child(2)::after {
    border-bottom: 0px;
    z-index: 1;
    width: auto;
    border-left: 3px solid #f57e20;
    height: 42%;
    top: -78%;
    left: 8px;
  }
}
.track_flow .progress_bar li.active + li::before {
  border: 5px solid #f57e20 !important;
}
.track_flow .progress_bar li.active:first-child::before {
  border-color: #f57e20 !important;
  border-width: 5px !important;
}
.track_flow .progress_bar li.active::after {
  z-index: -1;
}
@media (max-width: 767px) {
  .track_flow .progress_bar li.active::after {
    height: 100%;
    border-bottom: 0px;
    border-left: 3px solid #f57e20;
    top: 0;
    left: 8px;
    width: 100%;
    z-index: 1;
  }
}
.track_flow .progress_bar li.active::before {
  border-color: #f57e20 !important;
  border-width: 5px !important;
}
.track_flow .progress_bar li.active .fix_border::before {
  content: "";
  width: 100%;
  position: absolute;
  left: 0px;
  border-bottom: 3px solid #f57e20;
}
@media (max-width: 767px) {
  .track_flow .progress_bar li.active .fix_border::before {
    top: -100px;
    border-left: 3px solid #f57e20;
    border-bottom: 0px;
    left: -3px;
    height: 100%;
  }
}
.track_flow .progress_bar li:last-child .fix_border {
  border: none;
}
.track_flow .progress_bar li:last-child .fix_border::before {
  display: none;
}
@media (max-width: 767px) {
  .track_flow .progress_bar li:last-child::after {
    display: none;
  }
}
.track_flow .step_remarks {
  text-align: start;
  font-size: 14px;
}

span.fix_border {
  position: absolute;
  border-bottom: 3px dashed #c9cacc;
  width: 100%;
  left: 0px;
  top: 8px;
}
@media (max-width: 767px) {
  span.fix_border {
    border-bottom: 0px;
    height: 100%;
    left: 8px;
    width: 1px;
    border-left: 3px dashed #c9cacc;
  }
}

.itemsShows {
  display: block !important;
}

.UploadOfferShow {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.stock_pump_master {
  margin-top: 32px;
  padding-bottom: 32px;
}
@media (max-width: 767px) {
  .stock_pump_master {
    margin-top: 24px;
    padding-bottom: 24px;
  }
}
.stock_pump_master .stock_pump_master_table {
  margin-top: -9px;
}

.stock_pump_master .pumps_tabs .p-tabview-nav {
  overflow: auto;
}
.stock_pump_master .pumps_tabs .p-tabview-nav .p-unselectable-text.p-highlight {
  overflow: unset;
}
@media (max-width: 1023px) {
  .stock_pump_master .pumps_tabs .overview_left {
    width: 100%;
  }
}
.stock_pump_master .pumps_tabs .overview_left .details_block {
  padding: 20px 20px 44px 20px;
}
@media (max-width: 767px) {
  .stock_pump_master .pumps_tabs .overview_left .details_block {
    padding: 24px 16px 24px 16px;
  }
}
.stock_pump_master .pumps_tabs .overview_left .overview_pump_img {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
}
.stock_pump_master .pumps_tabs .overview_left .overview_pump_img .overview_img {
  width: 100%;
  max-width: 220px;
  height: auto;
  margin: 0 auto;
  display: block;
}
.stock_pump_master .pumps_tabs .overview_left .overview_pump_img .overview_img.vt_mgr_img {
  max-width: 190px;
}
@media (min-width: 1201px) {
  .stock_pump_master .pumps_tabs .overview_left .overview_pump_img .overview_img.vt_mgr_img {
    position: absolute;
    top: -25px;
  }
}
.stock_pump_master .pumps_tabs .overview_left .row_center {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
}
@media (max-width: 1280px) {
  .stock_pump_master .pumps_tabs .overview_left .laptop_w_50 .ipad_col_50 {
    width: 50%;
  }
}
.stock_pump_master .pumps_tabs .overview_left .form-row {
  margin-top: 68px;
}
@media (max-width: 1200px) {
  .stock_pump_master .pumps_tabs .overview_left .form-row {
    margin-top: 24px;
  }
}
@media (max-width: 991px) {
  .stock_pump_master .pumps_tabs .overview_left .form-row {
    margin-top: 16px;
  }
}
@media (min-width: 1201px) {
  .stock_pump_master .pumps_tabs .overview_left .form-row.location_row {
    margin-top: 0px;
  }
}
@media (max-width: 1023px) {
  .stock_pump_master .pumps_tabs .overview_right {
    width: 100%;
  }
}
@media (min-width: 1201px) {
  .stock_pump_master .pumps_tabs .overview_right .custom_width {
    width: 27.7%;
  }
}
.stock_pump_master .pumps_tabs .overview_right .custom_min_w {
  white-space: nowrap;
}
.stock_pump_master .pumps_tabs .overview_right .form-row {
  margin-top: 24px;
}
@media (max-width: 991px) {
  .stock_pump_master .pumps_tabs .overview_right .form-row {
    margin-top: 24px;
  }
}
@media (max-width: 1200px) {
  .stock_pump_master .pumps_tabs .row {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important;
  }
}
@media (max-width: 767px) {
  .stock_pump_master .pumps_tabs .form-row label {
    font-size: 14px;
  }
}
.stock_pump_master .pumps_tabs .details_block {
  margin-bottom: 16px;
}
@media (max-width: 767px) {
  .stock_pump_master .pumps_tabs .details_block {
    margin-bottom: 20px;
    padding: 24px 16px 24px 16px;
  }
}
.stock_pump_master .show_enquiries_detail {
  display: none;
}

.print_data_block {
  padding: 18px 6px 36px 36px;
  background-color: #e2e3e4;
}
@media (max-width: 767px) {
  .print_data_block {
    padding: 16px 6px 16px 16px;
  }
}
.print_data_block .print_data .pdf_icons_box_main {
  margin-top: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.print_data_block .print_data .pdf_icons_box_main .print_data_img {
  position: relative;
  display: block;
  max-width: 100%;
}
.print_data_block .print_data .pdf_icons_box_main .print_data_img .dataImage_block {
  max-height: 808px;
  overflow: auto;
  padding-right: 26px;
}
@media (max-width: 767px) {
  .print_data_block .print_data .pdf_icons_box_main .print_data_img .dataImage_block {
    padding-right: 10px;
    max-height: 768px;
  }
}
.print_data_block .print_data .pdf_icons_box_main .print_data_img .dataImage_block .data_img {
  width: auto;
  max-width: 100%;
  height: auto;
}
.print_data_block .print_data .pdf_icons_box_main .controller_icons {
  position: absolute;
  bottom: 24px;
  left: 50%;
  -webkit-transform: translate(-58%, 0%);
          transform: translate(-58%, 0%);
}
@media (max-width: 767px) {
  .print_data_block .print_data .pdf_icons_box_main .controller_icons {
    bottom: 10px;
  }
}
.print_data_block .print_data .pdf_icons_box_main .controller_icons ul {
  padding-left: 0px;
  margin-bottom: 0px;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 8px;
}
.print_data_block .print_data .pdf_icons_box_main .controller_icons ul li a.pdf_icons {
  height: 32px;
  width: 32px;
}
@media (max-width: 767px) {
  .print_data_block .print_data .pdf_icons_box_main .controller_icons ul li a.pdf_icons {
    height: 20px;
    width: 20px;
  }
  .print_data_block .print_data .pdf_icons_box_main .controller_icons ul li a.pdf_icons svg {
    width: 14px;
    height: 14px;
  }
}

.Add_new_pump_block {
  padding-top: 32px;
}
.Add_new_pump_block .table-header.table_heading_head h1 {
  margin-bottom: 0px;
}
.Add_new_pump_block .new_pump_details {
  min-height: 756px;
}
.Add_new_pump_block select {
  color: #5D5D70;
}
.Add_new_pump_block .selected_pump_shows {
  border: 1px solid #E2E3E4;
}
.Add_new_pump_block .selected_pump_shows.pump_checked {
  border: 1px solid #ED1D24;
}
.Add_new_pump_block .selected_pump_shows .selected_pump_img_name {
  gap: 12px;
}
.Add_new_pump_block .selected_pump_shows input[type=radio] {
  width: 24px;
  height: 24px;
}
.Add_new_pump_block .add_new_pump_btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 12px;
}
.Add_new_pump_block .add_new_pump_btns button {
  width: 177px;
}
@media (max-width: 767px) {
  .Add_new_pump_block .add_new_pump_btns button {
    width: 156px;
  }
}

.sales_order_tracking .order_search_listing {
  padding-top: 53px;
  padding-right: 20px;
  padding-left: 0px;
  width: 26.9%;
  height: 100vh;
  overflow: hidden;
}
@media (max-width: 991px) {
  .sales_order_tracking .order_search_listing {
    width: 40%;
    padding-left: 12px;
  }
}
@media (max-width: 767px) {
  .sales_order_tracking .order_search_listing {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 24px;
    height: auto;
  }
}
.sales_order_tracking .order_search_listing .table_heading_content {
  margin-bottom: 20px;
}
.sales_order_tracking .order_search_listing .sales_left_heading {
  font-family: "Proxima Nova Bold";
  font-size: 30px;
  font-style: normal;
  line-height: 30px;
  color: #15223B;
}
@media (max-width: 1199px) {
  .sales_order_tracking .order_search_listing .sales_left_heading {
    font-size: 24px;
  }
}
.sales_order_tracking .order_search_listing .search_field_block {
  margin-right: 0px;
  margin-bottom: 20px;
}
.sales_order_tracking .order_search_listing .search_field_block .search_heading {
  font-family: "Proxima Nova Bold";
  font-size: 18px;
  font-style: normal;
  color: #15223B;
  line-height: 18px;
  font-weight: 700;
  margin-bottom: 18px;
}
.sales_order_tracking .order_search_listing .search_field_block .form-row {
  position: relative;
}
.sales_order_tracking .order_search_listing .search_field_block .form-row .search_svg {
  top: 7px;
}
.sales_order_tracking .order_search_listing .search_field_block .form-row .search_field {
  width: 100%;
  max-width: 100%;
}
.sales_order_tracking .order_details_col {
  width: 73.1%;
  padding-left: 0px;
  padding-right: 0px;
  position: relative;
  height: 100vh;
}
@media (max-width: 991px) {
  .sales_order_tracking .order_details_col {
    width: 60%;
    height: auto;
  }
}
@media (max-width: 767px) {
  .sales_order_tracking .order_details_col {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
}
.sales_order_tracking .order_details_col::before {
  content: "";
  border-right: 1px solid #e2e3e4;
  width: 1px;
  height: 100vh;
  position: absolute;
  left: 0px;
  top: 0px;
}
@media (max-width: 991px) {
  .sales_order_tracking .order_details_col::before {
    height: 100%;
  }
}
@media (max-width: 767px) {
  .sales_order_tracking .order_details_col::before {
    display: none;
  }
}
.sales_order_tracking .order_details_col .table_heading_content {
  margin-bottom: 0px;
}
.sales_order_tracking .order_details_col .track_order_details {
  padding: 62px 5px 40px 32px;
  border-top: 1px solid #e2e3e4;
  border-bottom: 1px solid #e2e3e4;
}
@media (max-width: 767px) {
  .sales_order_tracking .order_details_col .track_order_details {
    padding: 24px 0px;
  }
}
.sales_order_tracking .order_details_col .track_order_details .details_block {
  padding: 0px;
  margin-bottom: 0px;
  border: none;
}
.sales_order_tracking .order_details_col .track_order_details .details_block .max_w_inner_310 .details_data {
  max-width: 310px;
}
@media (max-width: 991px) {
  .sales_order_tracking .order_details_col .track_order_details .details_block .max_w_inner_310 .details_data {
    max-width: 100%;
  }
}
.sales_order_tracking .order_details_col .track_order_details .details_block .max_w_inner_275 .details_data {
  max-width: 275px;
}
@media (max-width: 991px) {
  .sales_order_tracking .order_details_col .track_order_details .details_block .max_w_inner_275 .details_data {
    max-width: 100%;
  }
}
.sales_order_tracking .order_details_col .sales_track_status .trackStatus_block {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  padding: 40px 5px 40px 32px;
}
@media (max-width: 991px) {
  .sales_order_tracking .order_details_col .sales_track_status .trackStatus_block {
    border-bottom: 0px;
  }
}
@media (max-width: 767px) {
  .sales_order_tracking .order_details_col .sales_track_status .trackStatus_block {
    border-bottom: 0px;
    padding: 24px 0px;
  }
}
.sales_order_tracking .order_details_col .sales_track_status .trackStatus_block .sub_details_head {
  font-family: "Proxima Nova Rg";
  font-size: 24px;
  font-style: normal;
  color: #5D5D70;
  line-height: normal;
  font-weight: 400;
  text-transform: capitalize;
}
.sales_order_tracking .order_details_col .sales_track_status .trackStatus_block .track_flow {
  margin-top: 19px;
}
.sales_order_tracking .order_details_col .sales_track_status .trackStatus_block .track_flow .won {
  width: 391px;
}
.sales_order_tracking .order_details_col .sales_track_status .trackStatus_block .track_flow .dispatched {
  width: 300px;
}
@media (max-width: 991px) {
  .sales_order_tracking .order_details_col .sales_track_status .trackStatus_block .track_flow .progress_bar {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.sales_order_tracking .order_details_col .sales_track_status .trackStatus_block .track_flow .progress_bar li {
  row-gap: 12px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media (max-width: 991px) {
  .sales_order_tracking .order_details_col .sales_track_status .trackStatus_block .track_flow .progress_bar li {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-columns: 12px;
       -moz-columns: 12px;
            columns: 12px;
  }
}
.sales_order_tracking .order_details_col .sales_track_status .trackStatus_block .track_flow .progress_bar li .step_data {
  margin-bottom: 10px;
}
@media (max-width: 991px) {
  .sales_order_tracking .order_details_col .sales_track_status .trackStatus_block .track_flow .progress_bar li .step_data {
    margin-bottom: 20px;
    margin-left: 20px !important;
  }
}
@media (max-width: 991px) {
  .sales_order_tracking .order_details_col .sales_track_status .trackStatus_block .track_flow .progress_bar li::after {
    left: 8px;
    top: -98px;
    border-bottom: 0px;
    border-left: 3px dashed #c9cacc;
    height: 100%;
  }
}
.sales_order_tracking .order_details_col .sales_track_status .trackStatus_block .track_flow .progress_bar li.active + li::after {
  border: 2px solid #f57e20 !important;
}
@media (max-width: 991px) {
  .sales_order_tracking .order_details_col .sales_track_status .trackStatus_block .track_flow .progress_bar li.active + li::after {
    border: 0px solid #f57e20 !important;
    border-left: 3px solid #f57e20 !important;
    top: -80px;
  }
}
.sales_order_tracking .order_details_col .sales_track_status .trackStatus_block .track_flow .progress_bar li.active::after {
  border-bottom: 3px solid #f57e20;
  z-index: 1;
  width: 100%;
}
@media (max-width: 991px) {
  .sales_order_tracking .order_details_col .sales_track_status .trackStatus_block .track_flow .progress_bar li.active::after {
    height: 100%;
    border-bottom: 0px;
    border-left: 3px solid #f57e20;
    top: 0;
    left: 8px;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .sales_order_tracking .order_details_col .sales_track_status .trackStatus_block .track_flow .progress_bar li.active::after {
    top: -100px;
  }
}
.sales_order_tracking .order_details_col .sales_track_status .trackStatus_block .track_flow .progress_bar li.active .fix_border {
  border: none;
}
.sales_order_tracking .order_details_col .sales_track_status .trackStatus_block .track_flow .progress_bar li.active .fix_border::before {
  display: none;
}
.sales_order_tracking .order_details_col .sales_track_status .trackStatus_block .track_flow .progress_bar li.won::after {
  width: 220%;
  left: -233%;
}
@media (max-width: 991px) {
  .sales_order_tracking .order_details_col .sales_track_status .trackStatus_block .track_flow .progress_bar li.won::after {
    left: 8px;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .sales_order_tracking .order_details_col .sales_track_status .trackStatus_block .track_flow .progress_bar li:last-child::after {
    display: block;
  }
}
@media (max-width: 991px) {
  .sales_order_tracking .order_details_col .sales_track_status .trackStatus_block .track_flow .progress_bar span.fix_border {
    top: -100px;
    border-left: 3px solid #f57e20;
    border-bottom: 0px;
    left: -3px;
    height: 100%;
  }
}
.sales_order_tracking .order_details_col .sales_track_status .download_btn {
  padding: 7px 10px;
  height: 30px;
  width: 97px;
  font-family: "Proxima Nova Bold";
  font-size: 12px;
  font-style: normal;
  gap: 5px;
}
@media (max-width: 991px) {
  .sales_order_tracking .order_details_col .sales_track_status .download_btn {
    margin-top: 0;
    margin-left: auto;
  }
}
.sales_order_tracking .order_details_col .sales_track_status .download_btn svg {
  margin-left: 0px;
  margin-right: 0px;
  min-width: 16px;
  height: 16px;
}
.sales_order_tracking .search_orders_listing {
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0px;
  margin-top: 20px;
  max-height: calc(100vh - 210px);
  overflow: auto;
}
@media (max-width: 767px) {
  .sales_order_tracking .search_orders_listing {
    height: auto;
    max-height: 350px;
    overflow: auto;
  }
}
.sales_order_tracking .search_orders_listing li .order_search_items {
  padding: 20px;
  border-bottom: 1px solid #E2E3E4;
  text-decoration: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 8px;
}
.sales_order_tracking .search_orders_listing li .order_search_items .order_id_heading {
  font-family: "Proxima Nova Bold";
  font-size: 14px;
  font-style: normal;
  color: #15223B;
  line-height: normal;
}
.sales_order_tracking .search_orders_listing li .order_search_items .order_location {
  font-family: "Proxima Nova Rg";
  font-size: 12px;
  font-style: normal;
  color: #15223B;
  line-height: 12px;
}
.sales_order_tracking .search_orders_listing li .order_search_items .order_purchaser {
  font-family: "Proxima Nova Rg";
  font-size: 12px;
  font-style: normal;
  color: #15223B;
  line-height: 12px;
}
.sales_order_tracking .search_orders_listing li .order_search_items.active {
  background-color: #ECCABF;
  border-bottom: 1px solid #ECCABF;
}

.custom_treeTable table thead tr {
  width: auto;
  border: 1px solid #E2E3E4;
  min-width: 100%;
  display: block;
  min-height: auto;
  height: auto;
}
.custom_treeTable table thead tr th {
  display: inline-block;
  width: 50%;
  height: 100%;
  padding: 18px 24px 18px 37px;
}
.custom_treeTable table thead tr th:last-child {
  text-align: end;
  padding-right: 39px;
}
.custom_treeTable table thead tr th span {
  font-family: "Proxima Nova Bold";
  font-size: 12px;
  font-style: normal;
  line-height: 12px;
  font-weight: 700;
  color: #393D53;
  text-transform: uppercase;
}
.custom_treeTable table tbody tr {
  width: auto;
  border: 1px solid #E2E3E4;
  min-width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-bottom: 0px;
  gap: 12px;
}
.custom_treeTable table tbody tr td {
  display: inline-block;
  width: 50%;
  padding-left: 35px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.custom_treeTable table tbody tr td.mobile_td_two {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding-left: 0px;
}
.custom_treeTable table tbody tr td.mobile_td_two [aria-label=Collapse] {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.custom_treeTable table tbody tr td.mobile_td_two [aria-label=Expand] {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.custom_treeTable table tbody tr td .p-treetable-toggler {
  background-color: transparent;
  width: 18px;
  height: 18px;
}
.custom_treeTable table tbody tr td .p-treetable-toggler:hover {
  background-color: transparent;
}
.custom_treeTable table tbody tr td .p-treetable-toggler svg path {
  fill: #393D53;
}
.custom_treeTable table tbody tr:first-child {
  border-top: 0px solid #E2E3E4;
}
.custom_treeTable table tbody tr:last-child {
  border-bottom: 1px solid #E2E3E4 !important;
}
.custom_treeTable table tbody tr .optionToggle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 2px;
  margin-left: -30px;
}
.custom_treeTable table tbody tr .optionToggle h4 {
  margin-bottom: 0px;
  font-family: "Proxima Nova Bold";
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  color: #15223B;
}
.custom_treeTable table tbody tr .optionToggle .dots_col {
  background-image: url("../../images/More.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  width: 30px;
}
.custom_treeTable table tbody tr .optionToggle .dots_col button {
  opacity: 0;
}
.custom_treeTable table tbody tr .form-row p {
  margin-bottom: 0px;
  font-family: "Proxima Nova Rg";
  font-size: 18px;
  font-style: normal;
  line-height: normal;
  color: #000;
  font-weight: 400;
}
.custom_treeTable table tbody .p-row-odd:has(.form-row) {
  border-top: 0px;
  border-bottom: 0px;
}
.custom_treeTable table tbody .p-row-odd td:has(.form-row) {
  padding-top: 0px;
  padding-bottom: 0px;
}
.custom_treeTable table tbody .p-row-odd .mobile_td_two:has(.head_right_top) {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  padding-right: 16px;
  gap: 6px;
}

.head_right_top .status_shows {
  height: auto;
  padding: 5px 10px;
}
.head_right_top .status_shows div {
  white-space: nowrap;
  font-size: 10px;
  line-height: normal;
}

@media (max-width: 767px) {
  .fire_pump_booking {
    padding-top: 24px;
  }
}
.fire_pump_booking .table_heading_content {
  margin-bottom: 12px;
}
@media (max-width: 767px) {
  .fire_pump_booking .table_heading_content {
    margin-bottom: 24px;
  }
}
.fire_pump_booking .new_pump_details {
  padding-top: 12px;
  background-color: #f6f7f7;
  min-height: auto;
}
@media (min-width: 992px) {
  .fire_pump_booking .new_pump_details {
    min-height: 381.14px;
    position: relative;
  }
}
@media (min-width: 1101px) {
  .fire_pump_booking .new_pump_details {
    min-height: 291px;
    position: static;
  }
}
@media (max-width: 767px) {
  .fire_pump_booking .new_pump_details {
    background-color: transparent;
    padding-top: 0px;
    padding-bottom: 48px;
  }
}
.fire_pump_booking .new_pump_details .checkbox_find .form_radio_wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 40px;
  padding-top: 10px;
}
@media (min-width: 992px) and (max-width: 1299px) {
  .fire_pump_booking .new_pump_details .checkbox_find .form_radio_wrapper {
    gap: 10px;
  }
}
@media (max-width: 991px) {
  .fire_pump_booking .new_pump_details .checkbox_find .form_radio_wrapper {
    min-height: 48px;
  }
}
.fire_pump_booking .new_pump_details .checkbox_find .form_radio_block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 12px;
}
.fire_pump_booking .new_pump_details .checkbox_find .form_radio_block input[type=radio] {
  width: 24px;
  height: 24px;
}
.fire_pump_booking .new_pump_details .checkbox_find .form_radio_block .radio_label {
  margin-bottom: 0px;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.92px;
  text-align: left;
  color: #15223B;
}
@media (max-width: 1399px) {
  .fire_pump_booking .new_pump_details .checkbox_find .form_radio_block .radio_label {
    font-size: 14px;
  }
}
@media (max-width: 991px) {
  .fire_pump_booking .new_pump_details .checkbox_find .form_radio_block .radio_label {
    font-size: 16px;
    line-height: 19.49px;
  }
}
.fire_pump_booking .new_pump_details .mb__24 .form-row {
  margin-bottom: 24px;
}
@media (max-width: 767px) {
  .fire_pump_booking .new_pump_details .mb__24 .form-row {
    margin-bottom: 16px;
  }
}
.fire_pump_booking .new_pump_details .mb__24 .form-row .pump_name_show {
  font-size: 18px;
  line-height: 21.92px;
}
@media (min-width: 1100px) and (max-width: 1250px) {
  .fire_pump_booking .new_pump_details .mb__24 .form-row .pump_name_show {
    font-size: 13px;
  }
}
@media (min-width: 992px) and (max-width: 1100px) {
  .fire_pump_booking .new_pump_details .mb__24 .col-md-3 {
    width: 50%;
  }
}
@media (max-width: 991px) {
  .fire_pump_booking .new_pump_details .mb__24 .col-md-3:last-child .iPad_label_d-none {
    display: none;
  }
}
@media (max-width: 767px) {
  .fire_pump_booking .new_pump_details .mb__24 .col-md-3:nth-last-child(2) {
    margin-bottom: 8px;
  }
}
.fire_pump_booking .new_pump_details .form-row label {
  line-height: 17.05px;
}
@media (max-width: 767px) {
  .fire_pump_booking .new_pump_details .form-row label {
    line-height: 14.62px;
  }
}
.fire_pump_booking .new_pump_details .form-row input {
  color: #15223B;
}
.fire_pump_booking .sec_col_heading h5 {
  font-family: "Proxima Nova Bold";
  font-size: 18px;
  font-weight: 700;
  line-height: 21.92px;
  text-align: left;
  margin-bottom: 24px;
}
@media (max-width: 767px) {
  .fire_pump_booking .sec_col_heading h5 {
    font-size: 16px;
    line-height: 18px;
  }
}
@media (min-width: 1101px) {
  .fire_pump_booking .white_bg_field {
    position: relative;
  }
}
.fire_pump_booking .white_bg_field input,
.fire_pump_booking .white_bg_field select {
  background-color: #fff;
  color: #15223B;
}
.fire_pump_booking .white_bg_field .min_max_block {
  width: 100%;
}
@media (min-width: 992px) and (max-width: 1100px) {
  .fire_pump_booking .white_bg_field .col-md-3 {
    width: 50%;
  }
}
@media (max-width: 1100px) {
  .fire_pump_booking .white_bg_field .col-md-3:last-child .sec_col_heading {
    display: none;
  }
}
@media (min-width: 1101px) {
  .fire_pump_booking .white_bg_field .col-md-3.mobo_sticky {
    bottom: unset !important;
    right: 0px !important;
  }
}
@media (min-width: 992px) {
  .fire_pump_booking .white_bg_field .col-md-3.mobo_sticky {
    position: absolute;
    right: 30px;
    bottom: 0px;
  }
}
@media (min-width: 992px) and (max-width: 1100px) {
  .fire_pump_booking .white_bg_field .col-md-3.mobo_sticky {
    width: 47.3%;
  }
}
@media (max-width: 767px) {
  .fire_pump_booking .white_bg_field .col-md-3.mobo_sticky {
    position: fixed;
    bottom: 0px;
    z-index: 99;
    margin-top: 41px;
    background-color: #fff;
  }
}
.fire_pump_booking .min_max_block_main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 8px;
}
@media (max-width: 1100px) {
  .fire_pump_booking .min_max_block_main {
    gap: 12px;
  }
}
.fire_pump_booking .bottom_action_btns {
  text-decoration: none;
}
.fire_pump_booking .bottom_action_btns.newSearch_btn {
  padding: 28px 0px 20px 0px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .fire_pump_booking .bottom_action_btns.newSearch_btn {
    max-width: 400px;
    margin: 0 auto;
  }
}
@media (max-width: 767px) {
  .fire_pump_booking .bottom_action_btns.newSearch_btn {
    padding: 12px 0px;
    margin-bottom: 0px;
    position: relative;
  }
  .fire_pump_booking .bottom_action_btns.newSearch_btn::before {
    content: "";
    position: absolute;
    width: calc(100vw - 15px);
    height: 1px;
    background-color: #e2e3e4;
    top: 0px;
    left: -15px;
    z-index: 99999;
  }
}
.fire_pump_booking .bottom_action_btns .darkred_btn {
  width: 100%;
}
.fire_pump_booking .available_table_block {
  padding-top: 20px;
}
@media (max-width: 767px) {
  .fire_pump_booking .available_table_block {
    padding-top: 24px;
  }
}
.fire_pump_booking .available_table_block .available_table .p-datatable-wrapper table thead tr th {
  width: 12%;
  min-width: 130px;
}
.fire_pump_booking .available_table_block .available_table .p-datatable-wrapper table thead tr th:nth-child(3) {
  width: 10%;
  min-width: 100px;
}
.fire_pump_booking .available_table_block .available_table .p-datatable-wrapper table thead tr th:nth-child(2) {
  width: 10%;
  min-width: 90px;
}
.fire_pump_booking .available_table_block .available_table .p-datatable-wrapper table thead tr th:nth-child(5) {
  width: 10%;
  min-width: 100px;
}
.fire_pump_booking .available_table_block .available_table .p-datatable-wrapper table thead tr th:nth-child(7) {
  width: 10%;
  min-width: 100px;
}
.fire_pump_booking .available_table_block .available_table .p-datatable-wrapper table thead tr th:nth-child(8) {
  padding-right: 28px;
}
.fire_pump_booking .available_table_block .available_table .p-datatable-wrapper table thead tr th:nth-child(9) {
  width: 10%;
  min-width: 100px;
}
.fire_pump_booking .available_table_block .available_table .p-datatable-wrapper table thead tr th:nth-child(9) .p-column-title {
  text-align: center;
  display: block;
}
.fire_pump_booking .available_table_block .available_table .p-datatable-wrapper table thead tr th:last-child .p-column-header-content {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.fire_pump_booking .available_table_block .available_table .p-datatable-wrapper table tbody tr td {
  width: 12%;
  min-width: 130px;
  padding: 19px 0px;
  line-height: 14px;
}
.fire_pump_booking .available_table_block .available_table .p-datatable-wrapper table tbody tr td:nth-child(3) {
  width: 10%;
  min-width: 100px;
}
.fire_pump_booking .available_table_block .available_table .p-datatable-wrapper table tbody tr td:nth-child(2) {
  width: 10%;
  min-width: 90px;
}
.fire_pump_booking .available_table_block .available_table .p-datatable-wrapper table tbody tr td:nth-child(5) {
  width: 10%;
  min-width: 100px;
}
.fire_pump_booking .available_table_block .available_table .p-datatable-wrapper table tbody tr td:nth-child(7) {
  width: 10%;
  min-width: 100px;
}
.fire_pump_booking .available_table_block .available_table .p-datatable-wrapper table tbody tr td:nth-child(8) {
  padding-right: 40px;
  text-align: right;
  width: 10%;
  min-width: 100px;
}
.fire_pump_booking .available_table_block .available_table .p-datatable-wrapper table tbody tr td:nth-child(9) {
  text-align: center;
  padding-left: 19px;
  width: 10%;
  min-width: 100px;
}
.fire_pump_booking .available_table_block .available_table .p-datatable-wrapper table tbody tr td:nth-child(10) {
  padding-right: 50px;
}
.fire_pump_booking .available_table_block .available_table .p-datatable-wrapper table tbody tr td .checkbox_container {
  height: 20px;
  gap: 0px;
}
.fire_pump_booking .available_table_block .available_table .p-datatable-wrapper table tbody tr td .checkbox_container .checkbox_block label {
  margin-right: 0px;
}
.fire_pump_booking .available_table_block .available_table .p-datatable-wrapper table tbody tr td .available_carts {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 8px;
}
.fire_pump_booking .available_table_block .available_table .p-datatable-wrapper table tbody tr td .available_carts input {
  border: 1px solid #E2E3E4;
  width: auto;
  min-width: auto;
  max-width: 41px;
  padding-left: 6px;
  height: 28px;
  color: #000000;
  font-size: 14px;
}
.fire_pump_booking .available_table_block .available_table .p-datatable-wrapper table tbody tr td .available_carts input:focus-visible {
  outline: none;
}
.fire_pump_booking .available_table_block .available_table .p-datatable-wrapper table tbody tr td .available_carts .form-row {
  margin-bottom: 0px;
  height: 28px;
}
.fire_pump_booking .available_table_block .available_table .p-datatable-wrapper table tbody tr td .available_carts .form-row .form-label {
  display: none;
}
.fire_pump_booking .available_table_block .available_table .p-datatable-wrapper table tbody tr td .available_carts .form-row select {
  padding: 0px 6px;
  font-size: 14px;
  font-weight: 400;
  color: #15223B;
  min-width: 67px;
  height: 28px;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  text-transform: unset !important;
  background-image: url("../../images/darker_down.svg");
  background-size: 12px !important;
  background-position: right 0.27rem center;
}
.fire_pump_booking .available_table_block .available_table .p-datatable-wrapper table tbody tr td .available_carts .form-row select option {
  text-transform: unset !important;
}
.fire_pump_booking .available_table_block .available_table .p-datatable-wrapper table tbody tr td .action_td {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 40px;
}
.fire_pump_booking .available_table_block .available_table .p-datatable-wrapper table tbody tr td .action_td .action_eye {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 8px;
  text-decoration: none;
}
.fire_pump_booking .available_table_block .available_table .p-datatable-wrapper table tbody tr td .action_td .action_eye .eye_digit {
  font-size: 14px;
  line-height: 14px;
  color: #15223B;
}
.fire_pump_booking .sticky_compare_block {
  border: 1px solid #e2e3e4;
  padding: 24px 0px;
  position: sticky;
  bottom: 0px;
  width: 100%;
  background-color: #fff;
  z-index: 99;
  margin-top: 60px;
}
@media (max-width: 767px) {
  .fire_pump_booking .sticky_compare_block {
    padding: 16px 0px;
    -webkit-box-shadow: 0px -4px 12px 0px rgba(0, 0, 0, 0.0509803922);
            box-shadow: 0px -4px 12px 0px rgba(0, 0, 0, 0.0509803922);
    margin-top: 36px;
  }
}
.fire_pump_booking .sticky_compare_block .sticky_compare {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 991px) {
  .fire_pump_booking .sticky_compare_block .sticky_compare {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    gap: 15px;
  }
}
.fire_pump_booking .sticky_compare_block .sticky_compare ul {
  padding: 0px;
  margin-bottom: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 16px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
@media (max-width: 991px) {
  .fire_pump_booking .sticky_compare_block .sticky_compare ul {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
.fire_pump_booking .sticky_compare_block .sticky_compare ul li {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border: 1px solid rgba(185, 184, 193, 0.6);
  padding: 5px 12px;
  width: 100%;
  max-width: 343px;
  min-width: 343px;
  height: 48px;
}
.fire_pump_booking .sticky_compare_block .sticky_compare ul li .check_pump_main {
  margin-right: 12px;
}
@media (max-width: 991px) {
  .fire_pump_booking .sticky_compare_block .sticky_compare ul li .check_pump_main {
    min-width: 45px;
  }
}
.fire_pump_booking .sticky_compare_block .sticky_compare ul li img {
  max-width: 36px;
}
.fire_pump_booking .sticky_compare_block .sticky_compare ul li img.es_check_img {
  max-width: 30px;
}
.fire_pump_booking .sticky_compare_block .sticky_compare ul li img.vt_check_img {
  max-width: 23px;
}
@media (max-width: 991px) {
  .fire_pump_booking .sticky_compare_block .sticky_compare ul li img.vt_check_img {
    margin-left: 5px;
    display: block;
  }
}
@media (max-width: 767px) {
  .fire_pump_booking .sticky_compare_block .sticky_compare ul li img.vt_check_img {
    max-width: 28px;
  }
}
.fire_pump_booking .sticky_compare_block .sticky_compare ul li .product_compare {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.fire_pump_booking .sticky_compare_block .sticky_compare ul li .product_name {
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #868594;
  margin-right: 8px;
}
.fire_pump_booking .sticky_compare_block .sticky_compare ul li .product_id {
  font-family: "Proxima Nova Bold";
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
}
.fire_pump_booking .sticky_compare_block .sticky_compare ul li .red_cross {
  width: 18px;
  height: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.fire_pump_booking .sticky_compare_block .sticky_compare .bottom_action_btns {
  padding: 0px;
  max-width: 177px;
  margin-left: 20px;
}
@media (max-width: 991px) {
  .fire_pump_booking .sticky_compare_block .sticky_compare .bottom_action_btns {
    margin-bottom: 0px;
    margin-left: 0px;
  }
}
.fire_pump_booking .sticky_compare_block .sticky_compare button.darkred_btn[disabled] {
  margin: 0px !important;
  pointer-events: none;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  border-radius: 0px !important;
}
@media (max-width: 767px) {
  .fire_pump_booking.desktop_block .available_table_block {
    display: none;
  }
  .fire_pump_booking.desktop_block .sticky_compare_block {
    display: none;
  }
}
.fire_pump_booking.mobile_view_available .mobile_back_btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 8px;
}
.fire_pump_booking.mobile_view_available .mobile_back_btn .table_heading_content {
  margin-bottom: 0px;
}
@media (max-width: 767px) {
  .fire_pump_booking.mobile_view_available .sticky_compare {
    gap: 24px;
  }
  .fire_pump_booking.mobile_view_available .sticky_compare ul {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    gap: 24px;
    width: 100%;
  }
  .fire_pump_booking.mobile_view_available .sticky_compare ul li {
    padding: 0px 0px;
    border: none;
    max-width: 100%;
    min-width: auto;
  }
  .fire_pump_booking.mobile_view_available .sticky_compare ul li .product_name {
    font-size: 14px;
  }
  .fire_pump_booking.mobile_view_available .sticky_compare ul li .product_id {
    font-size: 16px;
  }
  .fire_pump_booking.mobile_view_available .sticky_compare .bottom_action_btns {
    margin-left: 0px;
    max-width: 100%;
  }
}

.compare_product_page .compare_product_block {
  position: relative;
}
.compare_product_page .breadcrumb_verses_products {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 767px) {
  .compare_product_page .breadcrumb_verses_products .details_head_block {
    width: 100%;
  }
}
@media (max-width: 1330px) {
  .compare_product_page .breadcrumb_verses_products .details_heading_block .heading_details {
    font-size: 24px;
    margin-left: 6px;
  }
}
@media (max-width: 1300px) {
  .compare_product_page .breadcrumb_verses_products .details_heading_block .heading_details {
    font-size: 20px;
  }
}
@media (max-width: 991px) {
  .compare_product_page .breadcrumb_verses_products .details_heading_block .heading_details {
    font-size: 24px;
  }
}
@media (max-width: 767px) {
  .compare_product_page .breadcrumb_verses_products .crumb_link {
    line-height: 17.05px;
    font-size: 13px;
  }
}
.compare_product_page .breadcrumb_verses_products .verse_compare_product {
  margin: 32px 0px;
  margin-left: 10px;
}
@media (max-width: 1024px) {
  .compare_product_page .breadcrumb_verses_products .verse_compare_product {
    margin-left: 0px;
  }
}
@media (max-width: 991px) {
  .compare_product_page .breadcrumb_verses_products .verse_compare_product {
    margin: 0px 0px;
    margin-top: -6px;
    margin-bottom: 16px;
    width: 100%;
  }
}
.compare_product_page .breadcrumb_verses_products .verse_compare_product ul {
  padding-left: 0px;
  margin-bottom: 0px;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 16px;
  width: 100%;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
@media (max-width: 1024px) {
  .compare_product_page .breadcrumb_verses_products .verse_compare_product ul {
    gap: 12px;
  }
}
.compare_product_page .breadcrumb_verses_products .verse_compare_product ul li {
  position: relative;
  min-width: 165px;
}
.compare_product_page .breadcrumb_verses_products .verse_compare_product ul li .product_compare {
  border: 1px solid #b9b8c1;
  padding: 10px 11px;
}
@media (max-width: 1200px) {
  .compare_product_page .breadcrumb_verses_products .verse_compare_product ul li .product_compare {
    padding: 11px 9px;
  }
}
@media (max-width: 767px) {
  .compare_product_page .breadcrumb_verses_products .verse_compare_product ul li .product_compare {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    gap: 8px;
    padding: 9px 7px;
    border: 1px solid rgba(185, 184, 193, 0.3019607843);
  }
}
.compare_product_page .breadcrumb_verses_products .verse_compare_product ul li .product_compare .img_qty {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 16px;
}
.compare_product_page .breadcrumb_verses_products .verse_compare_product ul li .product_compare img {
  width: 100%;
}
.compare_product_page .breadcrumb_verses_products .verse_compare_product ul li .product_compare img.es_compare_img {
  max-width: 42px;
}
.compare_product_page .breadcrumb_verses_products .verse_compare_product ul li .product_compare img.hp_compare_img {
  max-width: 50px;
}
.compare_product_page .breadcrumb_verses_products .verse_compare_product ul li .product_compare img.vt_compare_img {
  max-width: 30px;
  max-height: 52px;
}
.compare_product_page .breadcrumb_verses_products .verse_compare_product ul li .product_compare .product_item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 8px;
}
@media (max-width: 767px) {
  .compare_product_page .breadcrumb_verses_products .verse_compare_product ul li .product_compare .product_item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}
.compare_product_page .breadcrumb_verses_products .verse_compare_product ul li .product_compare .product_item .color_text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.compare_product_page .breadcrumb_verses_products .verse_compare_product ul li .product_compare .product_item .product_color {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 8px;
}
@media (max-width: 1200px) {
  .compare_product_page .breadcrumb_verses_products .verse_compare_product ul li .product_compare .product_item .product_color {
    margin-right: 4px;
  }
}
@media (max-width: 767px) {
  .compare_product_page .breadcrumb_verses_products .verse_compare_product ul li .product_compare .product_item .product_color {
    width: 14px;
    height: 14px;
  }
}
.compare_product_page .breadcrumb_verses_products .verse_compare_product ul li .product_compare .product_item .product_name {
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  color: #868594;
}
@media (max-width: 1200px) {
  .compare_product_page .breadcrumb_verses_products .verse_compare_product ul li .product_compare .product_item .product_name {
    font-size: 12px;
  }
}
.compare_product_page .breadcrumb_verses_products .verse_compare_product ul li .product_compare .product_item .product_id {
  font-family: "Proxima Nova Bold";
  font-size: 20px;
  font-weight: 700;
  line-height: 18px;
  color: #15223B;
}
@media (max-width: 1300px) {
  .compare_product_page .breadcrumb_verses_products .verse_compare_product ul li .product_compare .product_item .product_id {
    font-size: 16px;
  }
}
@media (max-width: 1100px) {
  .compare_product_page .breadcrumb_verses_products .verse_compare_product ul li .product_compare .product_item .product_id {
    font-size: 12px;
  }
}
@media (max-width: 767px) {
  .compare_product_page .breadcrumb_verses_products .verse_compare_product ul li .product_compare .product_item .product_id {
    display: block;
    width: 100%;
    font-size: 16px;
  }
}
.compare_product_page .breadcrumb_verses_products .verse_compare_product ul li .qty_carts .available_carts {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 12px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 767px) {
  .compare_product_page .breadcrumb_verses_products .verse_compare_product ul li .qty_carts .available_carts {
    padding-top: 8px;
  }
}
.compare_product_page .breadcrumb_verses_products .verse_compare_product ul li .qty_carts .available_carts input {
  border: 1px solid #E2E3E4;
  width: auto;
  min-width: auto;
  max-width: 41px;
  padding-left: 6px;
  height: 28px;
  color: #000000;
  font-size: 14px;
}
.compare_product_page .breadcrumb_verses_products .verse_compare_product ul li .qty_carts .available_carts input:focus-visible {
  outline: none;
}
.compare_product_page .breadcrumb_verses_products .verse_compare_product ul li .qty_carts .available_carts .form-row {
  margin-bottom: 0px;
  height: 28px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.compare_product_page .breadcrumb_verses_products .verse_compare_product ul li .qty_carts .available_carts .form-row select {
  padding: 2px 6px;
  height: 28px;
  margin-right: 8px;
  min-width: 65px;
  font-size: 14px;
  font-weight: 400;
  color: #15223B;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  text-transform: unset !important;
  background-image: url("../../images/darker_down.svg");
  background-size: 10px !important;
  background-position: right 0.27rem center;
  color: #000000;
}
.compare_product_page .breadcrumb_verses_products .verse_compare_product ul li .qty_carts .available_carts .form-row select option {
  text-transform: unset !important;
}
@media (max-width: 767px) {
  .compare_product_page .breadcrumb_verses_products .verse_compare_product ul li .qty_carts .available_carts .form-row select {
    height: 24px;
    padding: 0px 6px;
  }
}
.compare_product_page .breadcrumb_verses_products .verse_compare_product ul li .qty_carts .available_carts .cart_icon {
  margin-top: -1px;
}
@media (max-width: 767px) {
  .compare_product_page .breadcrumb_verses_products .verse_compare_product ul li .qty_carts .available_carts a {
    width: 24px;
    height: 24px;
  }
  .compare_product_page .breadcrumb_verses_products .verse_compare_product ul li .qty_carts .available_carts a svg {
    width: 24px;
    height: 24px;
    margin-top: -7.4px;
  }
}
.compare_product_page .breadcrumb_verses_products .verse_compare_product ul li .close_compare {
  position: absolute;
  right: -10px;
  top: -12px;
}
@media (max-width: 767px) {
  .compare_product_page .breadcrumb_verses_products .verse_compare_product ul li .close_compare {
    width: 20px;
    height: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    top: -7px;
    right: -7px;
  }
}
.compare_product_page .breadcrumb_verses_products .verse_compare_product ul li::before {
  content: url("../../images/vs.svg");
  position: absolute;
  right: -26px;
  top: calc(50% - 17px);
  z-index: 99;
}
@media (max-width: 1024px) {
  .compare_product_page .breadcrumb_verses_products .verse_compare_product ul li::before {
    right: -23px;
  }
}
@media (max-width: 767px) {
  .compare_product_page .breadcrumb_verses_products .verse_compare_product ul li::before {
    display: none;
  }
}
.compare_product_page .breadcrumb_verses_products .verse_compare_product ul li:last-child::before {
  display: none;
}
@media (max-width: 991px) {
  .compare_product_page .breadcrumb_verses_products {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}

.table_compare {
  padding: 8px 0px 100px 0px;
}
@media (max-width: 767px) {
  .table_compare {
    padding: 8px 0px 34px 0px;
  }
}
.table_compare .compare_title {
  font-size: 18px;
  font-weight: 700;
  line-height: 21.92px;
  font-family: "Proxima Nova Bold";
  margin-bottom: 16px;
}
@media (max-width: 991px) {
  .table_compare .col-md-6.px_10 {
    width: 100%;
    margin-bottom: 24px;
  }
  .table_compare .col-md-6.px_10:last-child {
    margin-bottom: 0px;
  }
}
.table_compare .custom_dataTable .p-datatable-wrapper table thead tr {
  border: 1px solid #e2e3e4;
}
.table_compare .custom_dataTable .p-datatable-wrapper table thead tr th {
  padding: 12px 0px;
  background-color: #e2e3e4;
  width: auto;
  min-width: auto;
}
.table_compare .custom_dataTable .p-datatable-wrapper table thead tr th .p-column-header-content {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.table_compare .custom_dataTable .p-datatable-wrapper table thead tr th .p-column-title .compare_table_heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 12px;
}
@media (max-width: 1300px) {
  .table_compare .custom_dataTable .p-datatable-wrapper table thead tr th .p-column-title .compare_table_heading {
    display: block;
  }
}
.table_compare .custom_dataTable .p-datatable-wrapper table thead tr th .p-column-title .compare_table_heading .color_code {
  width: 18px;
  height: 18px;
  display: inline-block;
}
@media (max-width: 1300px) {
  .table_compare .custom_dataTable .p-datatable-wrapper table thead tr th .p-column-title .compare_table_heading .color_code {
    margin-right: 8px;
    margin-bottom: -4px;
  }
}
.table_compare .custom_dataTable .p-datatable-wrapper table thead tr th .p-column-title .compare_table_heading .Product_code {
  font-family: "Proxima Nova Bold";
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
}
@media (max-width: 767px) {
  .table_compare .custom_dataTable .p-datatable-wrapper table thead tr th .p-column-title .compare_table_heading .Product_code {
    font-size: 14px;
  }
}
@media (max-width: 1300px) {
  .table_compare .custom_dataTable .p-datatable-wrapper table thead tr th .p-column-title .compare_table_heading .Product_code:last-child {
    display: block;
    width: 100%;
    margin-top: 5px;
    text-align: center;
  }
}
.table_compare .custom_dataTable .p-datatable-wrapper table tbody tr.p-rowgroup-header {
  background-color: #f6f7f7;
}
.table_compare .custom_dataTable .p-datatable-wrapper table tbody tr.p-rowgroup-header td {
  border: none !important;
}
.table_compare .custom_dataTable .p-datatable-wrapper table tbody tr td {
  padding: 11px 20px;
  width: auto;
  min-width: auto;
}
@media (max-width: 767px) {
  .table_compare .custom_dataTable .p-datatable-wrapper table tbody tr td {
    padding: 7px 14px;
  }
}
.table_compare .custom_dataTable .p-datatable-wrapper table tbody tr td:first-child {
  border-right: 1px solid #e2e3e4;
}
.table_compare .custom_dataTable .p-datatable-wrapper table tbody tr td:nth-child(2) {
  border-right: 1px solid #e2e3e4;
}
.table_compare .custom_dataTable .p-datatable-wrapper table tbody tr td .subHeading_compare {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.table_compare .custom_dataTable .p-datatable-wrapper table tbody tr td .subHeading_compare span {
  font-family: "Proxima Nova Bold";
  font-size: 16px;
  font-weight: 600 !important;
  line-height: 16px;
  text-align: center;
}
@media (max-width: 767px) {
  .table_compare .custom_dataTable .p-datatable-wrapper table tbody tr td .subHeading_compare span {
    font-size: 14px;
    line-height: 14px;
  }
}
.table_compare .custom_dataTable .p-datatable-wrapper table tbody tr td .compare_data {
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  padding: 5px 0px;
  color: #000000;
}
@media (max-width: 767px) {
  .table_compare .custom_dataTable .p-datatable-wrapper table tbody tr td .compare_data {
    padding: 2px 0px;
    font-size: 14px;
    line-height: 14px;
  }
}

.my_order_page {
  padding-bottom: 20px;
}
.my_order_page .order_heading_back {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  margin-bottom: 40px;
}
@media (max-width: 767px) {
  .my_order_page .order_heading_back {
    margin-bottom: 24px;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}
.my_order_page .order_heading_back .table_heading_content {
  margin-bottom: 0px;
  margin-left: 16px;
}
@media (max-width: 767px) {
  .my_order_page .order_heading_back .table_heading_content {
    margin-left: 6px;
  }
}
.my_order_page .order_heading_back .table_heading_head {
  display: block;
  width: 100%;
}
.my_order_page .my_order_list {
  margin-bottom: 0px;
  padding-left: 0px;
  list-style: none;
}
.my_order_page .my_order_list li {
  margin-bottom: 16px;
}
.my_order_page .my_order_list li a {
  text-decoration: none;
}
.my_order_page .my_order_list .order_head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 16px;
  border: 1px solid #e2e3e4;
  background-color: #f6f7f7;
}
.my_order_page .my_order_list .order_head h4 {
  margin-bottom: 0px;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  font-family: "Proxima Nova Bold";
  color: #15223B;
}
.my_order_page .my_order_list .order_head span {
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  color: #15223B;
  text-align: left;
}
.my_order_page .my_order_list .order_body {
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 16px 16px 26px 16px;
  border: 1px solid #e2e3e4;
  border-top: 0px;
  gap: 16px;
  position: relative;
  height: 137px;
}
@media (max-width: 767px) {
  .my_order_page .my_order_list .order_body {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    padding: 16px 16px 36px 16px;
    height: 230px;
  }
  .my_order_page .my_order_list .order_body .product_compare {
    height: 97px;
  }
}
.my_order_page .my_order_list .order_body .order_pump_main {
  min-width: 61px;
}
.my_order_page .my_order_list .order_body .vt_order_img {
  max-width: 50px;
}
.my_order_page .my_order_list .order_body .my_order_status {
  width: 100%;
  max-width: 300px;
  margin-left: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 12px;
}
@media (max-width: 767px) {
  .my_order_page .my_order_list .order_body .my_order_status {
    margin-left: unset;
    min-width: 100%;
  }
}
.my_order_page .my_order_list .order_body .my_order_status .quantity span {
  font-size: 14px;
  font-weight: 400;
  line-height: 17.05px;
  text-align: left;
  color: #5D5D70;
  margin-bottom: 8px;
}
.my_order_page .my_order_list .order_body .my_order_status .quantity p {
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #000000;
  margin-bottom: 0px;
}
.my_order_page .my_order_list .order_body .pro_order .product_name {
  font-size: 16px;
}
.my_order_page .my_order_list .order_body .pro_order .product_id {
  font-size: 16px;
}
.my_order_page .my_order_list .order_body .order_footer {
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  margin-bottom: 0px;
  position: absolute;
  right: 18px;
  bottom: 13px;
}
.my_order_page .my_order_list .order_body .order_footer a {
  color: #000000;
  text-decoration: underline;
}

@media (max-width: 767px) {
  .order_detail_page {
    padding-top: 24px;
  }
}
.order_detail_page .details_head_block {
  margin: 0px;
}
.order_detail_page .cancel_order {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (max-width: 767px) {
  .order_detail_page .cancel_order {
    display: none;
  }
}
.order_detail_page .cancel_order .order_status_btn {
  margin-right: 12px;
  height: 48px;
  min-width: 124px;
}
.order_detail_page .order_details_head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 32px;
}
@media (max-width: 767px) {
  .order_detail_page .order_details_head {
    margin-bottom: 24px;
  }
}
.order_detail_page .order_details_head .darkred_btn {
  min-width: 177px;
}
.order_detail_page .order_detail_main {
  margin-bottom: 16px;
}
.order_detail_page .order_detail_main .order_heading {
  font-family: "Proxima Nova Bold";
  font-size: 18px;
  font-weight: 700;
  line-height: 21.92px;
  text-align: left;
  margin-bottom: 24px;
}
@media (max-width: 767px) {
  .order_detail_page .order_detail_main .order_heading {
    margin-bottom: 16px;
  }
}
.order_detail_page .order_detail_main .product_delivered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 24px;
}
@media (max-width: 767px) {
  .order_detail_page .order_detail_main .product_delivered {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}
.order_detail_page .order_detail_main .product_delivered .order_body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background-color: rgba(226, 227, 228, 0.3019607843);
  width: 100%;
  max-width: 100%;
  padding: 16px;
}
@media (max-width: 991px) {
  .order_detail_page .order_detail_main .product_delivered .order_body {
    gap: 8px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}
.order_detail_page .order_detail_main .product_delivered .order_body .product_compare.order {
  gap: 26px;
}
@media (max-width: 991px) {
  .order_detail_page .order_detail_main .product_delivered .order_body .product_compare.order {
    gap: 16px;
  }
}
.order_detail_page .order_detail_main .product_delivered .order_body .product_compare.order img {
  width: auto;
  height: auto;
}
@media (max-width: 991px) {
  .order_detail_page .order_detail_main .product_delivered .order_body .product_compare.order img {
    width: 48px;
  }
}
.order_detail_page .order_detail_main .product_delivered .order_body .product_compare.order .pro_order {
  margin-bottom: 8px;
}
@media (max-width: 991px) {
  .order_detail_page .order_detail_main .product_delivered .order_body .my_order_status {
    width: 100%;
  }
}
@media (max-width: 991px) {
  .order_detail_page .order_detail_main .product_delivered .order_body .quantity {
    width: 100%;
    max-width: calc(100% - 66px);
    margin-left: auto;
    display: block;
  }
}
.order_detail_page .order_detail_main .product_delivered .order_body .quantity span {
  font-size: 14px;
  font-weight: 400;
  line-height: 17.05px;
  text-align: left;
  color: #5d5d70;
  margin-bottom: 8px;
}
@media (max-width: 991px) {
  .order_detail_page .order_detail_main .product_delivered .order_body .quantity p {
    display: inline-block;
    vertical-align: baseline;
    margin-left: 8px;
  }
}
.order_detail_page .order_detail_main .product_delivered .order_body p {
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #000000;
  margin-bottom: 0px;
}
.order_detail_page .order_detail_main .product_delivered .delivered_to {
  width: 100%;
  max-width: 337px;
  padding: 16px 14px 16px 16px;
  border: 1px solid #e2e3e4;
}
@media (max-width: 767px) {
  .order_detail_page .order_detail_main .product_delivered .delivered_to {
    max-width: 100%;
    border: none;
    padding: 0px;
  }
}
.order_detail_page .order_detail_main .product_delivered .delivered_to h4 {
  font-size: 18px;
  font-weight: 700;
  line-height: 21.92px;
  text-align: left;
  font-family: "Proxima Nova Bold";
  margin-bottom: 16px;
}
.order_detail_page .order_detail_main .product_delivered .delivered_to h5 strong {
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  color: #15223B;
}
.order_detail_page .order_detail_main .product_delivered .delivered_to p {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #868594;
  margin-bottom: 8px;
  line-height: 16px;
}
.order_detail_page .order_detail_main .product_delivered .delivered_to p span {
  color: #15223B;
}
.order_detail_page .order_detail_main .product_delivered .delivered_to p.address {
  line-height: 18px;
}
.order_detail_page .order_status_progress .sub_details_head {
  font-size: 18px;
  font-weight: 700;
  line-height: 21.92px;
  text-align: left;
  font-family: "Proxima Nova Bold";
}
.order_detail_page .order_status_progress .track_flow {
  padding-left: 35px;
  max-width: 995px;
}
@media (max-width: 767px) {
  .order_detail_page .order_status_progress .track_flow {
    margin-top: 16px;
    padding-left: 0px;
  }
}
.order_detail_page .order_status_progress .track_flow .progress_bar li.active::before {
  border-color: #be1e2d !important;
  border-width: 12px !important;
}
.order_detail_page .order_status_progress .track_flow .progress_bar li.active::after {
  border-left: 2px solid #be1e2d;
}
@media (max-width: 767px) {
  .order_detail_page .order_status_progress .track_flow .progress_bar li.active::after {
    width: 1px;
    height: 91%;
    left: 11px;
    z-index: 9;
  }
}
.order_detail_page .order_status_progress .track_flow .progress_bar li.active .fix_border::before {
  border-bottom: 2px solid #be1e2d;
  width: 100%;
  position: absolute;
  left: 0%;
}
@media (max-width: 767px) {
  .order_detail_page .order_status_progress .track_flow .progress_bar li.active .fix_border::before {
    border-left: 2px solid #be1e2d;
    border-bottom: none;
    height: 68%;
    left: -2px;
  }
}
.order_detail_page .order_status_progress .track_flow .progress_bar li span.fix_border {
  border-bottom: 2px solid #d9d9d9;
  top: 10px;
}
@media (max-width: 767px) {
  .order_detail_page .order_status_progress .track_flow .progress_bar li span.fix_border {
    border-left: 2px solid #d9d9d9;
    border-bottom: 0px;
    left: 11px;
  }
}
.order_detail_page .order_status_progress .track_flow .progress_bar li::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  top: 10.2px;
  left: 0%;
  z-index: -1;
  border-bottom: 2px dashed #d9d9d9;
}
@media (max-width: 767px) {
  .order_detail_page .order_status_progress .track_flow .progress_bar li::after {
    border-left: 2px dashed #d9d9d9;
    border-bottom: 0px;
  }
}
.order_detail_page .order_status_progress .track_flow .progress_bar li::before {
  content: "";
  min-width: 24px;
  min-height: 24px;
  width: 24px;
  height: 24px;
  border: 1px solid #15223B;
  border-radius: 100%;
  display: block;
  text-align: center;
  background-color: #fff;
  z-index: 8;
  position: relative;
}
.order_detail_page .order_status_progress .track_flow .progress_bar li .step_info {
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  font-family: "Proxima Nova Bold";
  color: #15223B;
  margin-bottom: 4px;
}
@media (max-width: 767px) {
  .order_detail_page .order_status_progress .track_flow .progress_bar li .step_info {
    text-align: start;
  }
}
.order_detail_page .order_status_progress .track_flow .progress_bar li .step_data {
  margin-bottom: 40px;
  margin-left: -30px;
  margin-top: 0px;
}
@media (max-width: 767px) {
  .order_detail_page .order_status_progress .track_flow .progress_bar li .step_data {
    margin-bottom: 61px;
    margin-top: 2px;
    margin-left: 12px;
  }
}
.order_detail_page .order_status_progress .track_flow .progress_bar li .step_data .step_date {
  text-align: center;
  color: #15223b;
  line-height: 14px;
}
@media (max-width: 767px) {
  .order_detail_page .order_status_progress .track_flow .progress_bar li .step_data .step_date {
    text-align: left;
  }
}
.order_detail_page .order_status_progress .track_flow .progress_bar li:nth-child(3) .step_data {
  margin-left: -41px;
}
@media (max-width: 767px) {
  .order_detail_page .order_status_progress .track_flow .progress_bar li:nth-child(3) .step_data {
    margin-left: 12px;
    text-align: left;
  }
}
.order_detail_page .order_status_progress .track_flow .progress_bar li:last-child .fix_border {
  display: none;
}
.order_detail_page .order_status_progress .track_flow .progress_bar li:last-child::after {
  display: none;
}
.order_detail_page .order_status_progress .track_flow .progress_bar li.upcoming::before {
  content: url("../../images/checkbg.png");
  border: none;
}
.order_detail_page .order_status_progress .track_flow .progress_bar li.upcoming::after {
  display: block;
  left: -25%;
  z-index: 1;
  border-bottom: 2px solid #d9d9d9;
  width: 60px;
}
@media (max-width: 767px) {
  .order_detail_page .order_status_progress .track_flow .progress_bar li.upcoming::after {
    left: 11px;
    z-index: 99;
    border-left: 2px solid #d9d9d9;
    border-bottom: none;
    width: 1px;
    top: -25px;
    height: 25px;
  }
}

footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  padding: 24px 0px 26px 0px;
  border-top: 1px solid #e9eaee;
  position: absolute;
  bottom: 0px;
  margin-bottom: -66px;
}
@media (max-width: 767px) {
  footer {
    padding: 20px 0px 20px 0px;
    margin-bottom: -56px;
  }
}
footer h6 {
  font-family: "Proxima Nova Rg";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #4E565F;
  text-align: center;
  margin-bottom: 0px;
}/*# sourceMappingURL=style.css.map */