.modal {
    &.show {
        &.graph_modal {
            .modal-dialog {
                max-width: 900px;
                padding: 0px;
                margin-top: 40px;
                @media (max-width:767px) {
                   margin: 40px auto;
                   width: 100%;
                   padding: 0px 10px;
                }
                .modal-content {
                    border-radius: 0px;
                    border: none;
                    max-height: 715px;
                    overflow: auto;
                    @media (max-width:767px) {
                        max-height: 612px;
                    }
                    .modal-header {
                        padding: 31px 32px 24px 32px;
                        border-bottom: 1px solid #B9B8C1;
                        position: sticky;
                        top: 0px;
                        background: #fff;
                        z-index: 1;
                        .btn-close{
                            width: 24px;
                            height: 24px;
                            padding-right: 16px;
                        }
                        @media (max-width: 767px){
                            padding: 16px 15px 21px 15px;
                            position: static;
                        }
                        h2{
                            @media (max-width: 767px){
                                padding-right: 33px;
                            } 
                        }
                    }
                    .modal-body{
                        padding: 12px 13px;
                        @media (max-width: 767px){
                            padding: 15px 16px 29px 16px;
                        }
                        .graph_img{
                            position: relative;
                            margin-bottom: 18px;
                            @media (max-width: 767px){
                                margin-bottom: 24px;
                            }
                            .imgGraph{
                                max-width: 100%;
                            }
                        }
                        .selection_bar{
                            margin-bottom: 11px;
                            @media (max-width: 767px){
                                margin-bottom: 16px;
                            }
                            .selection_bar_heading{
                                color: $white;
                                @include font_24_700;
                                line-height: normal;
                                font-weight: 700;
                                background-color: $dark_red;
                                padding: 10px 19px;
                                @media (max-width: 767px){
                                    padding: 17px 16px;
                                    font-size: 16px;
                                }
                            }
                        }
                        .operator_graph{
                            .details_block {
                                &:first-child{
                                    padding: 30px 0px 30px 30px;
                                    @media (max-width: 767px){
                                        padding: 30px 16px 32px 16px;
                                    }
                                }
                                &:last-child{
                                    margin-bottom: 0px;
                                }
                                padding: 26px 30px 30px 30px;
                                @media (max-width: 767px){
                                    padding: 24px 16px 27px 16px;
                                }

                                .form-row{
                                    margin-top: 20px;
                                    @media (max-width: 767px){
                                        margin-top: 24px;
                                        label{
                                            margin-bottom: 8px;
                                            font-size: 14px;
                                        }
                                    }
                                }
                                .inner_details{
                                    .sub_details_head{
                                        margin-bottom: 8px;
                                        @media (max-width: 767px){
                                            margin-bottom: 0px;
                                        }
                                    }
                                    .col-md-4, .col-md-3{
                                        padding-top: 0px;
                                        padding-bottom: 0px;
                                    }
                                    .operator_box{
                                        padding-left: 38px;
                                        @media (max-width: 767px){
                                            padding-left: 12px;
                                            padding-top: 33px; 
                                        }
                                    }
                                    .col-md-9{
                                         &.operator_box{
                                            @media (max-width: 767px){
                                                .sm-px-5{
                                                    &:nth-child(even){
                                                        width: 49%;
                                                    }
                                                    &:nth-child(odd){
                                                        width: 51%;
                                                    }
                                                }
                                            }
                                         }
                                    }
                                }
                                .details_data{
                                    @media (max-width:767px) {
                                        .unit{
                                            font-size: 12px; 
                                            color: $shadeGrey;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
