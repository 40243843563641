.select_pump_main {
    padding-top: 32px;
    min-height: 100vh;
    // display: contents;
    @media (max-width: 991px) {
        padding-top: 24px;
        padding-bottom: 13px;
    }
    .new-fire-pump{
        position: absolute;
        top: 100px;
        left: 90px;
        @media (max-width: 1299px) {
            left: 7.5%;
        }
        @media (max-width: 991px) {
            left: 0px;
            top: 0px;
        }
        &.select-pump-type-heading{
            top: 150px;
            @media (max-width: 991px) {
                top: 35px;
                font-size: 18px;
            }
        }
    }
    h1 {
        @include font_30_700;
        font-weight: 700;
        line-height: 30px;
        color: $dark_blue;
        margin-bottom: 24px;
        @media (max-width: 991px) {
            margin-bottom: 16px;
        }
        @media (max-width: 767px) {
            font-size: 24px;
        }
    }
    .new_update_pumps {
        align-items: flex-end;
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding-bottom: 100px;
        position: relative;
        // min-height: 100vh;
        @media (max-width: 991px) {
            padding-top: 90px;
            flex-direction: column;
            align-items: center;
            padding-bottom: 30px;
        }
        .new-pump{
            @media (max-width: 991px) {
                margin-bottom: 30px;
            }
            &:nth-child(2){
                @media (max-width: 991px) {
                    margin-bottom: 70px;
                }
            }
            &:last-child {
                @media (max-width: 991px) {
                    margin-bottom: 0px;
                }
                .pump_img {
                    img {
                        max-height: 955px;
                        @media (max-width: 991px) {
                            max-height: 750px;
                        }
                    }
                }
            }
        }
    }
    .pump_img {
        text-decoration: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // gap: 29px;
        // border: 1px solid #E2E3E4;
        background-color: $white;
        // padding-top: 32px;
        // padding-bottom: 20px;
        // padding-left: 20px;
        // padding-right: 20px;
        min-height: 283px;
        position: relative;
        z-index: 1;
        @media (max-width: 767px) {
            min-height: auto;
            padding-top: 20px;
            padding-bottom: 18px;
            gap: 12px;
        }
        .link_pump {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            // gap: 8px;
            // @media (max-width:767px){
            //     gap: 6px;
            // }
        }
        .pump_name {
            @include font_24_700;
            font-weight: 700;
            line-height: normal;
            color: $dark_blue;
            text-decoration: none;
            padding: 10px;
            min-width: 220px;
            display: flex;
            align-items: center;
            justify-content: center;
            @media (max-width: 991px) {
                font-size: 18px;
                min-width: 150px;
            }
            &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: -1;
                background-color: $dark_grey_shade;
                // -webkit-clip-path: polygon(0 0, 40px 0, 0 100%, 0 100%);
                // clip-path: polygon(0 0, 40px 0, 0 100%, 0 100%);
                transition: -webkit-clip-path 0.4s
                    cubic-bezier(0.455, 0.03, 0.515, 0.955);
                transition: clip-path 0.4s
                    cubic-bezier(0.455, 0.03, 0.515, 0.955);
                transition: clip-path 0.4s
                        cubic-bezier(0.455, 0.03, 0.515, 0.955),
                    -webkit-clip-path 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
            }
            &:hover {
                color: $white;
                &::before {
                    -webkit-clip-path: polygon(
                        0 0,
                        calc(100% + 40px) 0,
                        100% 100%,
                        0 100%
                    );
                    clip-path: polygon(
                        0 0,
                        calc(100% + 40px) 0,
                        100% 100%,
                        0 100%
                    );
                    background-color: $dark_red;
                }
            }
        }
        svg {
            @media (max-width: 767px) {
                width: 20px;
                height: 20px;
            }
        }
        img {
            // max-width: 230px;
            // max-height: 250px;
            width: 100%;
            margin-bottom: 0px;
            // object-fit: contain;
            // @media (min-width: 768px) and(max-width:991px) {
            //     height: 200px;
            //     width: 170px;
            // }
            max-height: 618px;
            @media (max-width: 991px) {
                margin-bottom: 15px;
                max-height: 550px;
            }
            // @media (max-width: 767px) {
            //     max-width: 103px;
            //     max-width: 100%;
            //     max-height: 123px;
            // }
        }
    }
}
