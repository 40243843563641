.filter_modal_content {
    max-width: 440px;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0px;
    right: 0px;
    z-index: 999;
    background: $white;
    transform: translateX(100%);
    transition: transform 0.3s linear;
    @media (max-width: 767px) {
        max-width: 320px;
    }
    .filter_modal_head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0px;
        border-bottom: 1px solid #b9b8c1;
        padding: 30px 20px 20px 20px;
        @media (max-width: 767px) {
            padding: 20px 20px 20px 20px;
        }
        h2 {
            @include font_24_700;
            line-height: normal;
            color: $dark_blue;
            margin-bottom: 0px;
        }
    }
    .filter_modal_body {
        padding: 20px 15px 100px 20px;
        margin-right: 5px;
        max-height: calc(100%);
        overflow: auto;
        button {
            margin-top: 40px;
        }
        .p-dropdown {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100% !important;
            height: 48px;
            padding: 0px 0px 0px 16px;
            background-image: url("../../images/chevron-down_dark.svg");
            transition: border-color 0.15s ease-in-out,
                box-shadow 0.15s ease-in-out;
            background-repeat: no-repeat;
            background-position: right 0.75rem center;
            background-size: 20px 20px;
            border: 1px solid #e2e3e4;
            border-radius: 0px;
            select {
                background-color: transparent;
                width: 100% !important;
                height: 48px;
                font-size: 16px;
                line-height: 16px;
                font-weight: 400;
                color: $dark_blue;
                border-radius: 0px;
            }
            .p-dropdown-label {
                font-size: 16px;
                line-height: 16px;
                font-weight: 400;
                color: $dark_blue;
            }
            .p-dropdown-trigger {
                opacity: 0;
            }
            &:not(.p-disabled).p-focus{
                box-shadow: none;
            }
            &:focus-visible{
                border: 1px solid #e2e3e4;
                outline: none;
            }
        }
        .p-multiselect {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100% !important;
            height: 48px;
            padding: 0px 0px 0px 16px;
            background-image: url("../../images/chevron-down_dark.svg");
            transition: border-color 0.15s ease-in-out,
                box-shadow 0.15s ease-in-out;
            background-repeat: no-repeat;
            background-position: right 0.75rem center;
            background-size: 20px 20px;
            border: 1px solid #e2e3e4;
            border-radius: 0px;
            select {
                background-color: transparent;
                width: 100% !important;
                height: 48px;
                font-size: 16px;
                line-height: 16px;
                font-weight: 400;
                color: $dark_blue;
                border-radius: 0px;
            }
            .p-multiselect-label {
                font-size: 16px;
                line-height: 16px;
                font-weight: 400;
                color: $dark_blue;
            }
            .p-multiselect-trigger {
                opacity: 0;
            }
            &:not(.p-disabled).p-focus{
                box-shadow: none;
            }
            &:focus-visible{
                border: 1px solid #e2e3e4;
                outline: none;
            }
        }
    }
}

.filter_box {
    .filter_link {
        display: flex;
        align-items: center;
        justify-content: center;
        // gap: 6px;
        padding: 12px 14px;
        text-transform: capitalize;
        border: 1px solid #6c769026;
        background: #fff;
        text-decoration: none;
        color: #5d5d70;
        @media (max-width: 767px) {
            padding: 10.5px 12px;
            margin-top: 16px;
        }
        @include font_14_400;
        svg{
            margin-right: 6px;
        }
        &:hover {
            border: 1px solid $red;
            color: $red;
            svg {
                path {
                    stroke: $red;
                }
            }
        }
    }
}
.filter_box {
    &.filterOpen {
        .filter_modal_content {
            transform: translateX(0%);
        }
    }
}

.conditional_block_show {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media (max-width: 767px) {
        width: 100%;
        justify-content: flex-start;
    }
}
