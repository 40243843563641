.pumple_list {
    .container {
        .pump_table_main {
            margin-bottom: 80px;
            @media (max-width: 767px) {
                margin-bottom: 40px;
            }
        }
        .pump_table_overflow {
            overflow: auto;
            @media (max-width: 767px) {
                overflow: hidden;
            }
            .pump_table_width {
                min-width: 1240px;
                @media (max-width: 767px) {
                    min-width: auto;
                }
                .p-datatable {
                    @media (max-width: 767px) {
                        display: none;
                    }
                }
                .p-datatable-wrapper {
                    overflow: hidden !important;

                    .p-datatable-table {
                        .p-datatable-thead {
                            tr {
                                th {
                                    border-left: 0px;
                                    padding-top: 14px;
                                    padding-bottom: 14px;
                                    @media (max-width: 767px) {
                                        padding-top: 8px;
                                        padding-bottom: 8px;
                                    }
                                    .p-column-header-content {
                                        .p-column-title {
                                            width: 100%;
                                            text-align: center;
                                        }
                                    }
                                }
                                &:first-child {
                                    th {
                                        &:first-child {
                                            min-width: 230px;
                                            @media (max-width: 767px) {
                                                min-width: 180px;
                                                position: sticky;
                                                top: 0;
                                                left: 0px;
                                                background-color: $dark_grey_shade;
                                            }
                                            .p-column-title {
                                                text-align: left;
                                            }
                                        }
                                        &:last-child {
                                            min-width: 50px;
                                            @media (max-width: 767px) {
                                                display: none;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .p-datatable-tbody {
                            tr {
                                td {
                                    text-align: center;
                                    &:first-child {
                                        text-align: left;
                                        @media (max-width: 767px) {
                                            min-width: 180px;
                                            position: sticky;
                                            top: 0;
                                            left: 0px;
                                        }
                                        .newUser {
                                            display: flex;
                                            align-items: center;
                                            input {
                                                height: 20px;
                                                width: 20px;
                                                accent-color: $red;
                                                margin-right: 20px;
                                                display: none;
                                                @media (max-width: 767px) {
                                                    display: block;
                                                }
                                            }
                                            p {
                                                margin: 0;
                                            }
                                            .model_img {
                                                margin-left: 8px;
                                            }
                                        }
                                    }
                                    &:last-child {
                                        min-width: 50px;
                                        text-align: right;
                                        @media (max-width: 767px) {
                                            display: none;
                                        }
                                        input {
                                            height: 20px;
                                            width: 20px;
                                            accent-color: $red;
                                            &:checked{
                                                accent-color: $red;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .pump_mobile_table {
                display: none;
                @media (max-width: 767px) {
                    display: block;
                    .pump_thead {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 24px 39px 24px 42px;
                        background-color: #f3f4f4;
                        border: 1px solid #e9eaeb;
                        .pump_mobile_th {
                            h6 {
                                @include font_12_700;
                                text-transform: uppercase;
                                color: #393d53;
                                margin-bottom: 0px;
                            }
                        }
                    }

                    .pump_mobile_tabs {
                        margin-bottom: 70px;
                        .p-icon {
                            display: none;
                        }
                        .p-accordion-header{
                            min-width: none;
                            height: auto;
                            max-height: unset;
                            &:focus{
                                outline: none;
                            }
                        }
                        .p-accordion-header-text {
                            width: 100%;
                        }
                        .p-accordion-header-link {
                            text-decoration: none;
                            border: none;
                            border-bottom: 1px solid #e2e3e4;
                            border-left: 1px solid #e2e3e4;
                            border-right: 1px solid #e2e3e4;
                            border-radius: 0px;
                            &:focus{
                                outline: 0px !important;
                            }
                        }
                        .main_toggle_mobile {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 100%;
                            padding: 14px 16px 14px 16px;
                            .radio_and_text {
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                // gap: 12px;
                                h4 {
                                    @include font_14_700;
                                    color: $dark_blue;
                                    margin-bottom: 0px;
                                    text-decoration: none;
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-start;
                                    // gap: 8px;
                                    margin-right: 8px;
                                    margin-left: 8px;
                                    .show_graph_mobile{
                                        margin-left: 8px;
                                    }
                                }
                            }
                            .stage_details {
                                .stage_content {
                                    @include font_14_400;
                                    color: $dark_blue;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    gap: 7px;
                                    &::after {
                                        content: url("../../images/chevron-down_dark.svg");
                                        width: 24px;
                                        height: 24px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        transition: 0.5s;
                                    }
                                }
                            }
                        }
                        .mobile_pump_accordion_body {
                            padding: 10px 8px 20px 48px;
                            display: flex;
                            align-items: flex-start;
                            justify-content: space-between;
                            flex-wrap: wrap;
                            row-gap: 24px;
                            .form-row {
                                width: 49%;
                                margin-bottom: 0px;
                                .field_td_mobile {
                                    margin-bottom: 0px;
                                    @include font_18_400;
                                    color: $black;
                                }
                            }
                        }
                        .p-radiobutton {
                            .p-radiobutton-box {
                                border: 2px solid $red;
                                background-color: $white;
                                width: 15px;
                                height: 15px;
                                border-radius: 50px !important;
                            }
                            .p-radiobutton-icon {
                                background-color: $red;
                            }
                            &.p-highlight:not(.p-disabled) {
                                &:hover {
                                    background-color: #fff;
                                }
                            }
                        }
                        .p-accordion-tab-active {
                            .p-accordion-header-link {
                                border-bottom: 0px;
                                &:focus{
                                    outline: 0px !important;
                                }
                            }
                            .stage_details {
                                .stage_content {
                                    &::after {
                                        transform: rotate(180deg);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .p-datatable-wrapper {
        // .p-virtualscroller{
        //     border-bottom: 1px solid #e2e3e4;
        // }
        table {
            // &::after{
            //     content: "";
            //     width: 99.99%;
            //     height: 1px;
            //     border: 1px solid red;
            //     display: block;
            //     position: absolute;
            //     left: 0px;
            // }

            border-collapse: separate;
            thead{
                tr{
                    &:first-child{
                        th{
                            &:nth-last-child(2){
                                border-bottom: 0px;
                            }
                            &:first-child{
                                border-left:1px solid #e2e3e4 !important;
                            }
                            &:last-child{
                                border-right:1px solid #e2e3e4 !important;
                            }
                        }
                    }
                th{
                    border-bottom: 1px solid #e2e3e4;
                }
            }
            }
            tbody {
                tr {
                    border-right: none;
                    border-left: none;
                    border-bottom: none;
                    td{
                        border-bottom: 1px solid #e2e3e4;
                        &:first-child{
                            border-left: 1px solid #e2e3e4;
                        }
                        &:last-child{
                            border-right: 1px solid #e2e3e4;
                        }
                    }
                    // &:last-child{
                    //     border-bottom: 0px;
                    // }
                }
            }
        }
    }
}
.pump_btn_fix{
    position: sticky;
    bottom: 0px;
    background-color: $white;
}
.pumple_list_btn {
    padding: 20px 0px;
    display: flex;
    justify-content: flex-end;
    @media (max-width: 767px) {
        padding: 12px 16px 12px 0px;
    }
    .outline_btn {
        border: 1px solid $red;
        background-color: transparent;
        border-radius: 0;
        margin-right: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 767px) {
            margin-right: 12px;
            font-size: 16px;
            line-height: 16px;
            width: 166px;
            height: 40px;
        }
        svg {
            margin-top: 0px;
            path {
                stroke: $red;
                fill: transparent;
            }
        }
        &:hover {
            background-color: $red;
            color: $white;
            path {
                stroke: $white;
            }
        }
    }
    .darkred_btn {
        @media (max-width: 767px) {
            font-size: 14px;
        }
        &:disabled {
            border-radius: 0;
            background-color: $gray;
            border-color: $gray;
            svg {
                margin-top: 0px;
                path {
                    fill: transparent;
                }
            }
            &:hover {
                background-color: transparent;
                color: $gray;
                path {
                    stroke: $gray;
                }
            }
            @media (max-width: 767px) {
                height: 40px;
                font-size: 14px;
                line-height: 14px;
                width: 157px;
                &:hover {
                    background-color: transparent;
                }
            }
        }
    }
    .full_hr {
        margin-top: -20px;
        @media (max-width: 767px) {
            margin-top: -12px;
        }
    }
    button{
        &:disabled{
            pointer-events: none;
        }
    }
}