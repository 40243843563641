.login_block {
  height: 100vh; 
  .bg_login {
    width: 54.38%;
    // background-color: $bg_color;
    background-color: $white;
    display: flex;
    align-items: center;
    overflow: hidden;
    justify-content: center;
    @media (max-width:991px){
      width: 100%;
      padding-bottom: 190px;
    }
    @media (max-width:767px){
      padding-bottom: 140px;
    }
    .login_banner {
      height: 100%;
      object-fit: cover;
      width: auto;   
      @media (max-width:991px){
        max-height: 360px;
        margin-top: 19px;
      }
      @media (max-width:767px){
        max-height: 240px;
      }
      @media (max-width:767px){
        max-height: 196px;
      }
      &.desktop_login_bg{
        display: block;
        @media (max-width:767px){
          display: none;
        }
      }
      &.mobile_login_bg{
        display: none;
        @media (max-width:767px){
          display: block;
        }
      }     
    }
    .login_link{
      display: block;
      height: 100%;
      @media (min-width:1201px) and (max-width:1440px){
        height: 80%;
      }
      @media (min-width:991px) and (max-width:1200px){
        height: 60%;
      }
    }
    &.h100vh {
      height: 100vh;
      min-height: 741px;
      @media (max-width:991px){
        height: 100%;
        min-height: auto;
      }
    }
    &.h100vh_min{
      height: 100vh;
      min-height: 902px;
      @media (max-width:991px){
        height: 100%;
        min-height: auto;
      }
    }
    @media (min-width: 2134px) {
      // height: 100vh;
      min-height: 100vh;
    }
  }
  .login_form_col {
    width: 45.62%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    @media (max-width:991px){
      width: 100%;
      margin-bottom: 40px;
    }
    .form-inner-col {
      padding-top: 40px;
      padding-bottom: 40px;
      width: 100%;
      background-color: $white;
      @media (max-width:991px){
        padding-top: 40px;
        padding-bottom: 60px;
        margin-left: 16px;
        margin-right: 16px;
        margin-top: -200px;
        // box-shadow: 0px -2px 20px 2px #00000024;
      }
    }
    .login_logo {
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 29px;
      @media (max-width:767px){
        margin-bottom: 38px;
        img{
          max-width: 99px;
        }
      }
    }
    .form {
      max-width: 457px;
      width: 100%;
      margin: 0 auto;
      border-top: 0px;
      padding: 0px;
      box-shadow: none;
      @media (max-width:1200px){
        padding: 0px 20px;
      }
      @media (max-width:991px){
        max-width: 100%;
        padding: 0px 16px;
      }
      h1 {
        @include font_30_700;
        color: $black;
        margin-bottom: 8px;
        text-align: center;
        font-weight: 700;
        @media (max-width:767px){
          font-size: 16px;
          margin-bottom: 12px;
        }
      }
      .sub_name_para {
        @include font_18_400;
        color: $gray;
        line-height: 28px;
        letter-spacing: -0.18px;
        margin-bottom: 32px;
        margin-top: 0px;
        text-align: center;
        @media (max-width:767px){
          font-size: 12px;
          margin-bottom: 34px;
        }
      }
    }
  }
  .password_field_box{
    position: relative;
    .eye_close_box{
      cursor: pointer;
      position: absolute;
      right: 16px;
      top: 40px;
      .closed_eye_img{
      width: 17px;
      }
    }
  }
  .remember_check {
    margin-top: 0px;
    .checkbox_block label::after{
      background-image: none;
      border: 1px solid #B9B8C1;
      border-radius: 3px;
      height: 16px;
      width: 16px;
    }
    .checkbox_block input[type=checkbox]{
      width: 16px;
    }
    .checkbox_block input[type=checkbox]:checked + label:after{
      background-size: 16px 16px;
      border: 0px;
    }
    .checkbox_block label{
      height: 16px;
      width: 16px;
    }
    .remember_text{
      @include font_14_400;
      color: $dark_grey;
      line-height: normal;
      pointer-events: none;
      @media (max-width:767px){
        @include font_12_400;
      }
    }
    .forgot_link {
      a {
        @include font_14_700;
        color: $red;
        text-decoration: none;
        font-weight: 700;
        @media (max-width:767px){
          font-size: 12px;
        }
      }
    }
  }
  .login_btn {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 20px;
    &.btn-mt-32 {
      margin-top: 32px;
    }
    @media (max-width:767px){
      margin-top: 24px;
      margin-bottom: 16px;
    }
  }
  .switch_login {
    @include font_16_400;
    line-height: 16px;
    color: $dark_blue;
    margin-top: 0px;
    text-align: center;
    font-weight: 400;
    margin-bottom: 0px;
    @media (max-width:767px){
      font-size: 12px;
    }
    .member-btn {
      @include font_16_700;
      font-weight: 700;
      line-height: normal;
      color: $red;
      border: none;
      background: transparent;
      @media (max-width:767px){
        font-size: 12px;
      }
    }
  }
  .api-error {
    color: $red;
    @include font_14_400;
    line-height: 100%;
  }
  .fields-group {
    position: relative;
    .p-error {
      position: absolute;
      bottom: -22px;
      @media (max-width:767px){
        font-size: 12px;
      }
    }
  }
  #popover-password{
    .progress{
      height: 8px;
      background-color: #E2E3E4;
      border-radius: 20px;
      .progress-bar{
        border-radius: 20px;
        background-color: $orange;
      }
    }
    ul{
      margin-bottom: 0px;
      li{
        margin-bottom: 4px;
        .password_details{
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 12px;
          @media (max-width:767px){
            gap: 4px;
          }
          span{
            @include font_14_400;
            color: $gray;
            line-height: 24px;
             @media (max-width:767px){
              font-size: 12px;
              line-height: normal;
            }
          }
        }
        .status_svg{
          display: flex;
          align-items: center;
          justify-content: center;
          .green_right_svg{
            display: none;
            align-items: center;
            justify-content: center;
          }
          .grey_dot_svg{
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}
.verification-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  .simple-text {
    @include font_16_400;
    color: $shadeGrey;
    text-align: center;
    @media (max-width: 991px) {
      padding: 0 15px;
    }
  }
  .verify-head {
    @include font_30_700;
    color: $dark_blue;
  }
}
