.custom_treeTable{
    table{
        thead{
            tr{
                width: auto;
                border: 1px solid #E2E3E4;
                min-width: 100%;
                display: block;
                min-height: auto;
                height: auto;
                th{
                    display: inline-block;
                    width: 50%;
                    height: 100%;
                    padding: 18px 24px 18px 37px;
                    &:last-child{
                        text-align: end;
                        padding-right: 39px;
                    }
                    span{
                        @include font_12_700;
                        line-height: 12px;
                        font-weight: 700;
                        color: #393D53;
                        text-transform: uppercase;
                    }
                }
            }
        }
        tbody{
            tr{
                width: auto;
                border: 1px solid #E2E3E4;
                min-width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 0px;
                gap: 12px;
                td{
                    display: inline-block;
                    width: 50%;
                    padding-left: 35px;
                    padding-top: 12px;
                    padding-bottom: 12px;
                    &.mobile_td_two{
                        display: flex;
                        flex-direction: row-reverse;
                        align-items: center;
                        justify-content: flex-end;
                        padding-left: 0px;
                        [aria-label="Collapse"]{
                                transform: rotate(180deg);
                        }
                        [aria-label="Expand"]{
                                transform: rotate(90deg);
                        }
                    }
                    .p-treetable-toggler{
                        background-color: transparent;
                        width: 18px;
                        height: 18px;
                        &:hover{
                            background-color: transparent;
                        }
                        svg{
                            path{
                                fill: #393D53;
                            }
                        }
                    }
                }
                &:first-child{
                    border-top: 0px solid #E2E3E4;
                }
                &:last-child{
                    border-bottom: 1px solid #E2E3E4 !important;
                }
                .optionToggle{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 2px;
                    margin-left: -30px;
                    h4{
                        margin-bottom: 0px;
                        @include font_14_700;
                        line-height: normal;
                        color: $dark_blue;
                    }
                    .dots_col{
                        background-image: url('../../images/More.svg');
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: 20px 20px;
                        width: 30px;
                        button{
                            opacity: 0;
                        }
                    }
                }
                .form-row{
                    p{
                        margin-bottom: 0px;
                        @include font_18_400;
                        line-height: normal;
                        color: #000;
                        font-weight: 400;
                    }
                }
            }
            .p-row-odd:has(.form-row){
                border-top: 0px;
                border-bottom: 0px;
            }
            .p-row-odd td:has(.form-row){
                padding-top: 0px;
                padding-bottom: 0px;
            }
            .p-row-odd .mobile_td_two:has(.head_right_top){
                justify-content: flex-start;
                padding-right: 16px;
                gap: 6px;
            }
        }
    }
}
.head_right_top{
    .status_shows{
        height: auto;
        padding: 5px 10px;
        div{
        white-space: nowrap;
        font-size: 10px;
        line-height: normal;
        }
    }
}
