.table_box {
    padding-top: 32px;
}

.pumps_tabs {
    .show_enquiries_detail {
        @include font_12_700;
        color: $dark_blue;
        line-height: normal;
        font-weight: 700;
        padding: 4.5px 8px;
        background-color: #e2e3e4;
        border-radius: 40px;
        margin-left: 4px;
        cursor: pointer;
        position: relative;
        display: inline-block;
        &:hover {
            .tooltip_show {
                display: block;
            }
        }
        .tooltip_show {
            display: none;
            position: absolute;
            bottom: 33px;
            left: -32px;
            background-color: $dark_blue;
            padding: 8.5px 9.5px;
            border-radius: 6px;
            list-style: none;
            margin-bottom: 0px;
            margin-left: 0px;
            &::before {
                content: url("../../images/Polygon_one.svg");
                position: absolute;
                bottom: -10px;
                left: 40px;
                transform: rotate(180deg);
            }
            .tooltip_show_data {
                @include font_12_700;
                color: $white;
                line-height: normal;
                white-space: nowrap;
            }
        }
    }
    .location_td {
        padding-left: 30px;
    }
}
