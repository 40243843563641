.modal {
    display: flex;
    &.show {
        .modal-dialog {
            max-width: 632px;
            width: 100%;
            padding: 0px 16px;
            @media (max-width: 767px) {
                margin: 0 auto;
                margin-top: 15px;
                margin-bottom: 15px;
            }
            .modal-content {
                margin: 0;
                border-radius: 0px;
                border: none;
                .modal-header {
                    padding: 32px 32px 24px 32px;
                    border-bottom: 1px solid #b9b8c1;
                    @media (max-width: 767px) {
                        padding: 20px 20px 20px 16px;
                    }
                    h2 {
                        margin-bottom: 0px;
                        @include font_24_700;
                        font-weight: 700;
                        line-height: normal;
                        color: $dark_blue;
                    }
                    .btn-close {
                        background-image: url("../../images/Close.svg");
                        padding: 0px;
                        background-repeat: no-repeat;
                        background-size: 24px 24px;
                        opacity: 1;
                    }
                }
                .edit_customer_form {
                    .modal-body {
                        padding: 32px;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        @media (max-width: 767px) {
                            padding: 24px 16px 4px 16px;
                        }
                        .field_wrapper {
                            width: 48%;
                            @media (max-width: 767px) {
                                width: 100%;
                            }
                            .form-row {
                                #companyId {
                                    border: 1px solid #e2e3e4;
                                    padding: 16px;
                                    height: 48px;
                                    min-width: 325px;
                                    @media (max-width: 767px) {
                                        min-width: auto;
                                    }
                                }
                                .form_radio_wrapper {
                                    display: flex;
                                    flex-wrap: wrap;
                                    .form_radio_block {
                                        margin-top: 12px;
                                        display: flex;
                                        align-items: center;
                                        width: 48%;
                                        .form-radio {
                                            height: 20px;
                                            width: 20px;
                                            border-color: $greyShadeAF;
                                            margin-right: 8px;
                                            accent-color: $red;
                                            border-radius: 100px;
                                            padding: 0px;
                                            background-color: #00000000;
                                            @media (max-width: 767px) {
                                                height: 16px;
                                                width: 16px;
                                            }
                                        }
                                        .radio_label {
                                            color: $dark_blue;
                                            font-size: 16px;
                                            line-height: 1;
                                            margin-bottom: 0;
                                            @media (max-width: 767px) {
                                                font-size: 12px;
                                            }
                                        }
                                        @media (max-width: 767px) {
                                            width: 20%;
                                            margin-right: 12px;
                                        }
                                    }
                                }
                            }
                            .checkbox_main {
                                margin-bottom: 0px;
                                .checkbox_container {
                                    .checkbox_block {
                                        label {
                                            @media (max-width: 767px) {
                                                width: 16px;
                                                height: 16px;
                                            }
                                            &::after {
                                                @media (max-width: 767px) {
                                                    background-size: 16px 16px;
                                                    width: 16px;
                                                    height: 16px;
                                                }
                                            }
                                        }
                                    }
                                }
                                @media (max-width: 767px) {
                                    margin-bottom: 0px;
                                    margin-top: -12px;
                                }
                            }
                        }
                    }
                }
                .confirm_msg {
                    @include font_18_400;
                    color: $black;
                    margin-bottom: 24px;
                }
                .customer_del_wrapper {
                    display: flex;
                    justify-content: flex-start;
                    gap: 50px;
                    .customer_del_block {
                        .customer_field {
                            @include font_14_400;
                            margin-bottom: 8px;
                            color: $dark_grey;
                        }
                        .customer_data {
                            @include font_18_400;
                            color: $black;
                        }
                    }
                }
                .modal-footer {
                    border-top: none;
                    padding: 21px 32px 32px 32px;
                    @media (max-width: 767px) {
                        justify-content: center;
                        padding: 0px 16px 22px 16px;
                        // gap: 12px;
                    }
                    // @media (max-width: 373px) {
                    //     flex-direction: column-reverse;
                    // }
                    button {
                        margin: 0px;
                    }
                    .outline_btn {
                        border: 1px solid $red;
                        background-color: transparent;
                        border-radius: 0;
                        margin-right: 12px;
                        &:hover {
                            background-color: $red;
                        }
                        @media (max-width: 767px) {
                            width: 118px;
                            // margin-right: 0px;
                        }
                        &:disabled {
                            color: #ed1d24;
                        }
                    }
                    .darkred_btn {
                        border-radius: 0;
                        @media (max-width: 767px) {
                            // width: 172px;
                            width: 157px;
                            padding: 5px 5px;
                        }
                    }
                }
            }
            .checkbox_block {
                span {
                    @include font_14_400;
                    color: $shadeGrey;
                }
            }
            .checkbox_container {
                height: auto;
            }
        }
        &.customer_popup_modal {
            .form-row {
                .form-control {
                    padding: 0px 5px 0px 0px;
                    border: none;
                    height: 22px;
                }
            }
        }
        &.user_popup_modal {
            .modal-dialog {
                max-width: 766px;
            }
            &.enquiries_popup_modal {
                .modal-body {
                    margin-bottom: 3px;
                    @media (max-width: 767px) {
                        margin-bottom: 0px;
                    }
                }
                .modal-footer {
                    padding: 0px 32px 32px 32px;
                    @media (max-width: 767px) {
                        padding: 20px 16px 22px 16px;
                        .darkred_btn{
                            width: 118px;
                        }
                    }
                }
            }
            &.modify_criteria_modal{
                .modal-dialog {
                    max-width: 1068px
                }
                .modal-body{
                    padding: 32px 32px 10px 32px;
                    @media (max-width: 767px) {
                        padding: 24px 16px 4px 16px;
                    }
                }
                .modal-footer{
                    .outline_btn{
                        width: 183px;
                        @media (max-width: 767px) {
                            width: 118px;
                        }
                    }
                    .darkred_btn{
                        width: 177px;
                        @media (max-width: 767px) {
                            width: 118px;
                        }
                    }
                }
            }
        }
        &.delete_popup_modal {
            z-index: 99999;
            .modal-dialog {
                max-width: 595px;
                .modal-body {
                    padding: 40px 32px 29px 32px;
                    @media (max-width: 767px) {
                        padding: 24px 16px 4px 16px;
                    }
                }
                .modal-footer {
                    // @media (max-width: 373px) {
                    //     flex-direction: column;
                    // }
                    button {
                        width: 140px !important;
                    }
                }
            }
        }
    }
    .email-popup-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .email-popup-title {
            @include font_24_700;
            margin-top: 20px;
        }
        .email-content {
            @include font_18_400;
            text-align: center;
            a {
                @include font_18_700;
                text-decoration: none;
                color: $dark_blue;
            }
        }
    }
}
